import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Buffer } from 'buffer';
import { initSentry } from './services/API/Sentry';

import * as Sentry from "@sentry/react";
import GlobalErrorBoundary from './pages/GlobalErrorBoundary';

window.Buffer = window.Buffer ?? Buffer;

// Inicializa o Sentry
initSentry();

const root = ReactDOM.createRoot(document.getElementById('root'), {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn('Uncaught error', error, errorInfo.componentStack);
  }),
  // Callback called when React catches an error in an ErrorBoundary.
  onCaughtError: Sentry.reactErrorHandler(),
  // Callback called when React automatically recovers from errors.
  onRecoverableError: Sentry.reactErrorHandler(),
});

// window.onerror = function (message, source, lineno, colno, error) {
//   console.log(message, source, lineno, colno, error);
//   window.location.href = '/page-error';
// };

// window.addEventListener("unhandledrejection", function (event) {
//   console.error("Promise rejeitada:", event.reason.message);
//   console.error("Stack trace:", event.reason.stack);
//   window.location.href = '/page-error';
// });

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GlobalErrorBoundary>
        <App />
      </GlobalErrorBoundary>
    </BrowserRouter>
  </Provider>
);

import { useEffect, useState } from "react";
import PopoverPopup from "../PopoverPopup";
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { POPOVER_CLOSE } from "../../store/PopoverReducer/types";
import { POST_DATA } from "../../services/API";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../store/ControleMensagemReducer/types";

const ModalEmail = ({ idPopover, anchorEl, possuiAnexo = false }) => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [mensagem, setMensagem] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [cupomSelecionado, setCupomSelecionado] = useState(0);
    const popover = useSelector((store) => store.popoverPopup);

    const [cupons, setCupons] = useState([]);

    useEffect(() => {
        if (popover.idPopover === idPopover) {
            setMensagem(`Olá ${popover.data?.nomePessoa}, tudo bem?`);
        }

        (async () => {
            setTitulo('');
            if (popover.open && possuiAnexo) {
                const result = await POST_DATA(`Cupom/GetAllCupomByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}&flagAtivo=true`);
                result.forEach(item => { item.dataValidade = new Date(item.dataValidade) });
                setCupons(result);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popover.open]);

    const handleSendEmail = async () => {
        try {
            if (possuiAnexo && cupomSelecionado == '0') {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor selecionar um cupom',
                });
                return;
            }

            if (titulo.length === 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar o título do e-mail',
                });
                return;
            }

            if (mensagem.length === 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar a mensagem do e-mail',
                });
                return;
            }

            let objEnvio = {
                emailTo: popover.data?.emailCliente,
                assunto: titulo,
                mensagem: mensagem .replaceAll("\n", '\\n')
            };

            if (cupomSelecionado) {
                objEnvio = { ...objEnvio, cupom: cupomSelecionado }
            }

            await POST_DATA(`Usuario/SendMailPersonalizado?idUsuarioLoja=${userLoggedIn.IdUsuario}`, objEnvio);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'E-mail enviado com sucesso!',
            });

            dispatch({
                type: POPOVER_CLOSE,
                idPopover: idPopover,
                idItem: idPopover,
                open: true
            });
        }
        catch (ex) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Ocorreu um erro ao enviar o e-mail!',
            });
        }
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
    };

    return <PopoverPopup idPopover={idPopover} anchorEl={anchorEl}>
        <Box style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Enviar mensagem via E-mail</Typography>
            <TextField
                variant="outlined"
                fullWidth
                label='Título'
                name='login'
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                style={{ width: 480, marginTop: 10 }}
                InputLabelProps={{
                    style: {
                        color: 'black',
                        fontSize: '14px'
                    }
                }}
                InputProps={{
                    style: {
                        color: 'black',
                        fontSize: '14px'
                    }
                }}
            />
            <TextField
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                label='Mensagem'
                name='login'
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
                style={{ width: 480, marginTop: 15 }}
                InputLabelProps={{
                    style: {
                        color: 'black',
                        fontSize: '16px'
                    }
                }}
                InputProps={{
                    style: {
                        color: 'black',
                        fontSize: '16px'
                    }
                }}
            />
            {
                possuiAnexo &&
                <FormControl fullWidth style={{ marginTop: 10 }}>
                    <InputLabel id="cor-selector-label">Selecione o cupom</InputLabel>
                    <Select
                        variant="outlined"
                        id="label-select"
                        value={cupomSelecionado}
                        label="Selecione o cupom"
                        onChange={(e) => setCupomSelecionado(e.target.value)}                        
                        style={{ marginTop: 10, width: '100%', color: 'black' }}
                        MenuProps={{
                            disablePortal: true,
                            PaperProps: {
                                sx: { maxHeight: 300 },
                                style: {
                                    transform: 'scale(1)', // Ajusta o tamanho se necessário
                                },
                            },
                        }}
                    >
                        <MenuItem value={0}>Nenhum(a)</MenuItem>
                        {
                            cupons.map(cupom => <MenuItem key={cupom.Descricao} value={cupom.Descricao}>{cupom.Descricao}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            }
            <Divider style={{ marginTop: 10 }} />
            <Button style={{ marginTop: 10, background: 'black', color: 'white' }} variant="contained" onClick={handleSendEmail}>Enviar</Button>
            <Button style={{ marginTop: 10 }} variant="text" onClick={() => {
                dispatch({
                    type: POPOVER_CLOSE,
                    idPopover: idPopover,
                    idItem: idPopover,
                    open: true
                });
            }}>Cancelar</Button>
        </Box>
    </PopoverPopup>
};

export default ModalEmail;
import { Box, Button, Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { handleTrack } from "../../../services/API";
import { useEffect } from "react";

const ExplicacaoMeioPagamento = () => {
    const navigatge = useNavigate();

    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    useEffect(() => {
        if(userLoggedIn === null) return;

        (async () => {
            await handleTrack(userLoggedIn.IdUsuario, 'TelaPagamento', `Carregando tela de informativo de pagamento`);
        })();
    }, [userLoggedIn]);

    return <Box style={{ widht: '100%' }}>
        <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Pagamentos</Typography>
        <Typography style={{ fontSize: 18, color: '#7e7e7e' }}>Receba pagamentos usando recursos exclusivos para seu negócio.</Typography>

        <Card style={{ width: '100%', marginTop: 15, padding: 30 }}>
            <Typography style={{ fontSize: 19, fontWeight: 'bold' }}>Aceitar pagamentos</Typography>
            <Typography style={{ fontSize: 18, color: '#7e7e7e', marginTop: 30 }}>Receba pagamentos de qualquer valor para seus itens.</Typography>
            <Typography style={{ fontSize: 18, color: '#7e7e7e' }}>Para receber pagamentos usando esse recurso, primeiro conecte-se a um método de pagamento.</Typography>

            <Button variant="contained" style={{ marginTop: 70 }} onClick={() => navigatge(`/formas-pagamento`)}>Conectar meio de pagamento</Button>
        </Card>

    </Box>
}

export default ExplicacaoMeioPagamento;
import { Alert, Box, Button, Card, FormControlLabel, Grid, InputLabel, MenuItem, Select, styled, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GET_DATA, POST_DATA, PUT_DATA, SalvarLogEventoSentry, SalvarLogSentry, UploadFileAzure, UploadFileAzureText } from "../../../services/API";

import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { useDispatch } from "react-redux";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingAllPage from "../../../components/LoadingAllPage";
import HtmlIcon from '@mui/icons-material/Html';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { PhotoLibraryTwoTone } from "@mui/icons-material";
import * as moment from "moment";

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const UploadImagemContainer = styled(InputLabel)`
  min-width: 220px;
  width: 100%;  
  min-height: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 7px;
  overflow: hidden;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const initialValuesImagensHtml = [
    {
        id: 1,
        nome: 'Imagem 1',
        file: null,
        url: null
    },
    {
        id: 2,
        nome: 'Imagem 2',
        file: null,
        url: null
    },
    {
        id: 3,
        nome: 'Imagem 3',
        file: null,
        url: null
    },
    {
        id: 4,
        nome: 'Imagem 4',
        file: null,
        url: null
    },
    {
        id: 5,
        nome: 'Imagem 5',
        file: null,
        url: null
    }
];

const ControleTema = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [temas, setTemas] = useState([]);

    const [loadingSalvando, setLoadingSalvando] = useState(false);

    const [temaSelecionado, setTemaSelecionado] = useState(null);

    const [imagemSelecionada, setImagemSelecionada] = useState(null);

    const [imagemOrganogramaSelecionada, setImagemOrganogramaSelecionada] = useState(null);


    const [tabSelected, seTabSelected] = useState(0);

    const [imagensHtml, setImagensHtml] = useState([]);

    const [htmlTemaSelecionado, setHtmlTemaSelecionada] = useState(null);

    useEffect(() => {
        (async () => {
            setImagensHtml(initialValuesImagensHtml);
            await fetchTemas();
        })();
    }, []);

    const fetchTemas = async () => {
        const dados = await GET_DATA(`Tema/GetAllTemas?flgAtivo=true`);
        const arr = [{ IdTema: 0, Nome: 'Nenhum(a)' }];

        dados.forEach(item => arr.push(item));

        setTemas(dados);
    }

    const handleConverterToFile = (imagem, nomeImagem) => {
        // Divide a string Base64 em suas partes componentes
        const [header, base64] = imagem.split(',');
        const mimeString = header.split(':')[1].split(';')[0]; // Obtém o tipo MIME

        // Decodifica a string Base64
        const byteString = atob(base64);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        // Preenche o Uint8Array com os bytes da string Base64
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        // Cria um objeto File a partir do ArrayBuffer
        const file = new File([arrayBuffer], nomeImagem, { type: mimeString });

        return file;
    }

    const handleSalvar = async () => {
        try {
            setLoadingSalvando(true);

            const pasta = `tema_${temaSelecionado.IdTema}`;
            const extensao = imagemSelecionada.split(';')[0]?.replace('data:', '');
            const nomeImagem = `imagem_${temaSelecionado.IdTema}`;

            const file = handleConverterToFile(imagemSelecionada, `${nomeImagem}.${extensao.split('/')[1]}`);

            temaSelecionado.UrlImagem = await UploadFileAzure(file, pasta, `${nomeImagem}_${moment().format('DDMMYYYYHHmmss')}.${extensao.split('/')[1]}`, extensao, `crm-onboard`);

            setImagemSelecionada(null);

            await PUT_DATA(`Tema/UpdateTema`, temaSelecionado);

            await fetchTemas();

            setLoadingSalvando(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar a imagem do tema',
            });
        }
        catch (err) {
            setLoadingSalvando(false);

            SalvarLogSentry(err, 'ControleTema => handleSalvar');

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err.message,
            });
        }
    }

    const handleSalvarOrganograma = async () => {
        try {
            setLoadingSalvando(true);

            const pasta = `tema_${temaSelecionado.IdTema}`;
            const extensao = imagemOrganogramaSelecionada.split(';')[0]?.replace('data:', '');
            const nomeImagem = `imagem_${temaSelecionado.IdTema}`;

            const file = handleConverterToFile(imagemOrganogramaSelecionada, `${nomeImagem}.${extensao.split('/')[1]}`);

            temaSelecionado.UrlImagemOrganograma = await UploadFileAzure(file, pasta, `${nomeImagem}_${moment().format('DDMMYYYYHHmmss')}.${extensao.split('/')[1]}`, extensao, `temas-template`);

            setImagemOrganogramaSelecionada(null);

            await PUT_DATA(`Tema/UpdateTema`, temaSelecionado);

            await fetchTemas();

            setLoadingSalvando(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar a imagem do organograma do tema',
            });
        }
        catch (err) {
            setLoadingSalvando(false);

            SalvarLogSentry(err, 'ControleTema => handleSalvarOrganograma');

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err.message,
            });
        }
    }

    function toBase64(str) {
        // Codifica a string como UTF-8
        const utf8Bytes = new TextEncoder().encode(str);
        // Cria uma string binária a partir dos bytes
        const binaryString = String.fromCharCode(...utf8Bytes);
        // Converte a string binária para Base64
        return btoa(binaryString);
    }


    const handleSalvarImagensDescricao = async () => {
        try {
            setLoadingSalvando(true);

            const imagens = [...imagensHtml];

            let html = `<!DOCTYPE html>
<html>
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <title>Página demonstração</title>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <link rel="stylesheet" type="text/css" media="screen" href="./lollypop_files/main.css">
        <script src="./lollypop_files/main.js.transferir"></script>

        <style>
            .iframeModal {  
                display: flex; 
                flex-direction: column; 
                justify-content: center; 
                align-items: center;
                transform: scale(0.95);
                transform-origin: 0 0;
            }

    </style>
    <meta id="dcngeagmmhegagicpcmpinaoklddcgon"></head>
    <body>
        <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div class="iframeModal">`;

            for (const imagem of imagens.filter(a => a.file !== null)) {
                const pasta = `tema_${temaSelecionado.IdTema}`;
                const nomeImagem = `imagem_${temaSelecionado.IdTema}_${imagem.id}.${imagem.file.type.split('/')[1]}`;
                const extensao = imagem.file.type;

                imagem.url = await UploadFileAzure(imagem.file, pasta, nomeImagem, extensao, `crm-templates-bio`);
                imagem.file = imagem.url;

                html += `<img width="1420px" height="100%" src="${imagem.url}" alt="Imagem ${imagem.id}">`;
            }

            setImagensHtml(imagens);

            html += `</div>

        </div>
    
</body></html>`;

            const base64 = toBase64(html);

            const nomeImagem = `html_${temaSelecionado.IdTema}.html`;
            const pasta = `tema_${temaSelecionado.IdTema}`;

            temaSelecionado.UrlImagemDescricaoTema = await UploadFileAzureText(base64, pasta, nomeImagem, 'text/html', `crm-templates-bio`);

            await PUT_DATA(`Tema/UpdateTema`, temaSelecionado);

            await fetchTemas();

            setLoadingSalvando(false);

            window.open(temaSelecionado.UrlImagemDescricaoTema, '_blank');

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar as imagem e gerar o HTML',
            });

        } catch (err) {
            setLoadingSalvando(false);

            SalvarLogSentry(err, 'ControleTema => handleSalvarImagensDescricao');

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err.message,
            });
        }
    }

    const handleSalvarHtml = async () => {
        try {
            setLoadingSalvando(true);

            const pasta = `tema_${temaSelecionado.IdTema}`;
            const nomeImagem = `html_${temaSelecionado.IdTema}`;
            const extensao = htmlTemaSelecionado?.type;
            const reader = new FileReader();

            reader.onloadend = async function (e) {
                var base64String = reader.result.split(',')[1]; // Remove o prefixo data URI

                temaSelecionado.UrlImagemDescricaoTema = await UploadFileAzureText(base64String, pasta, `${nomeImagem}.${extensao.split('/')[1]}`, extensao, `crm-templates-bio`);

                setImagemOrganogramaSelecionada(null);

                await PUT_DATA(`Tema/UpdateTema`, temaSelecionado);

                await fetchTemas();

                seTabSelected(0);

                setLoadingSalvando(false);

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Sucesso ao salvar o HTML da descrição tema',
                });
            };

            reader.readAsDataURL(htmlTemaSelecionado);
        }
        catch (err) {
            setLoadingSalvando(false);

            SalvarLogSentry(err, 'ControleTema => handleSalvarHtml');

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err.message,
            });
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return <Container>
        {
            loadingSalvando && <LoadingAllPage text="Salvando dados do tema..." />
        }

        <Box style={{ width: '100%' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Controle dos temas</Typography>
            <Button variant="outlined" style={{ marginTop: 10 }} onClick={() => navigate(-1)}>Voltar</Button>
        </Box>

        <Card style={{ width: '100%', height: '100%', marginTop: 10, padding: 15 }}>
            <Box style={{ width: '100%' }}>
                <FormControlLabel
                    labelPlacement="top"
                    label="Selecione o tema"
                    control={
                        <Select
                            labelId="tema-selector"
                            id="tema-selector-id"
                            value={temaSelecionado?.IdTema || 0}
                            style={{ width: '100%', minWidth: 200 }}
                            onChange={(e) => {
                                const tema = temas.filter(a => a.IdTema === Number(e.target.value))[0];

                                setTemaSelecionado(tema);
                                setImagemSelecionada(null);
                                setImagemOrganogramaSelecionada(null);
                            }}
                            MenuProps={{
                                style: {
                                    maxHeight: 300,
                                },
                                disablePortal: true,
                                PaperProps: {
                                    style: {
                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                    },
                                },
                            }}
                        >
                            <MenuItem value={0}>Nenhum(a)</MenuItem>
                            {
                                temas.map(tema => {
                                    return <MenuItem key={tema.IdTema} value={tema.IdTema}>{tema.Nome}</MenuItem>
                                })
                            }
                        </Select>
                    }
                />
            </Box>
            <Box style={{ width: '100%', marginTop: 10 }}>
                <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                    <Grid item xs={5.9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 20, marginTop: 30, border: '1px solid #ececec' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Imagem tema</Typography>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: 15 }}>
                            {
                                !temaSelecionado?.UrlImagem && <Box>
                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Atual</Typography>
                                    <UploadImagemContainer>
                                        <PhotoLibraryTwoTone sx={{ fontSize: "7rem", color: "gray" }} />
                                    </UploadImagemContainer>
                                </Box>
                            }
                            {
                                temaSelecionado?.UrlImagem && <Box>
                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Atual</Typography>
                                    <img src={temaSelecionado?.UrlImagem} alt="Imagem" width={300} />
                                </Box>
                            }

                            <Box>
                                <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Nova</Typography>

                                {!imagemSelecionada &&
                                    <UploadImagemContainer htmlFor={`file-upload-imagem`} style={{ cursor: 'pointer' }}>
                                        <PhotoLibraryTwoTone sx={{ fontSize: "7rem", color: "gray" }} />

                                        <input
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg, image/webp"
                                            id={`file-upload-imagem`}
                                            name="myImage"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                if (!temaSelecionado?.UrlImagem) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: `Favor selecionar um tema`,
                                                    });

                                                    return;
                                                }

                                                const file = e?.target?.files[0];

                                                if (!file) return;

                                                const reader = new FileReader();

                                                reader.onload = function (e) {
                                                    const imgElement = document.getElementById('imagemSelecionada');
                                                    const imagem = e.target.result;

                                                    imgElement.src = imagem;

                                                    setImagemSelecionada(imagem);
                                                };

                                                reader.readAsDataURL(file);
                                            }}
                                            onClick={(e) => (e.target.value = null)}
                                        />
                                    </UploadImagemContainer>
                                }
                                <img src={imagemSelecionada} id="imagemSelecionada" alt="Imagem" width={300} style={{ marginLeft: 10, display: !imagemSelecionada ? 'none' : 'block' }} />
                            </Box>
                        </Box>
                        <Button disabled={!imagemSelecionada} variant="contained" style={{ marginTop: 50 }} onClick={() => handleSalvar()}>Salvar</Button>
                    </Grid>

                    <Grid item xs={5.9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 20, marginTop: 30, border: '1px solid #ececec' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Imagem tema organograma</Typography>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-around', marginTop: 15 }}>
                            {
                                !temaSelecionado?.UrlImagemOrganograma && <Box>
                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Atual</Typography>
                                    <UploadImagemContainer>
                                        <PhotoLibraryTwoTone sx={{ fontSize: "7rem", color: "gray" }} />
                                    </UploadImagemContainer>
                                </Box>
                            }
                            {
                                temaSelecionado?.UrlImagem && <Box>
                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Atual</Typography>
                                    <img src={temaSelecionado?.UrlImagemOrganograma} alt="Imagem" width={300} />
                                </Box>
                            }

                            <Box>
                                <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Nova</Typography>
                                {!imagemOrganogramaSelecionada &&
                                    <UploadImagemContainer htmlFor={`file-upload-imagem-organograma`} style={{ cursor: 'pointer' }}>
                                        <PhotoLibraryTwoTone sx={{ fontSize: "7rem", color: "gray" }} />

                                        <input
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg, image/webp"
                                            id={`file-upload-imagem-organograma`}
                                            name="myImage"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                if (!temaSelecionado?.UrlImagemOrganograma) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: `Favor selecionar um tema`,
                                                    });

                                                    return;
                                                }

                                                const file = e?.target?.files[0];

                                                if (!file) return;

                                                const reader = new FileReader();

                                                reader.onload = function (e) {
                                                    const imgElement = document.getElementById('imagemSelecionada');
                                                    const imagem = e.target.result;

                                                    imgElement.src = imagem;

                                                    setImagemOrganogramaSelecionada(imagem);
                                                };

                                                reader.readAsDataURL(file);
                                            }}
                                            onClick={(e) => (e.target.value = null)}
                                        />
                                    </UploadImagemContainer>
                                }
                                <img src={imagemOrganogramaSelecionada} id="imagemOrganogramaSelecionada" alt="Imagem" width={300} style={{ marginLeft: 10, display: !imagemOrganogramaSelecionada ? 'none' : 'block' }} />
                            </Box>
                        </Box>
                        <Button disabled={!imagemOrganogramaSelecionada} variant="contained" style={{ marginTop: 50 }} onClick={() => handleSalvarOrganograma()}>Salvar</Button>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, paddingBottom: 20, border: '1px solid #ececec' }}>
                        <Typography style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>Descrição do tema</Typography>

                        <Box style={{ width: '100%', display: 'flex', marginBottom: 30 }}>
                            <Tabs
                                value={tabSelected}
                                onChange={(event, newValue) => seTabSelected(newValue)}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Configuração" {...a11yProps(0)} />
                                <Tab label="Ver detalhes do tema atual" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        {
                            tabSelected === 0 &&
                            <Box style={{ width: '100%' }}>
                                <Grid container spacing={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                                    <Alert severity="warning" style={{ marginTop: 10, marginBottom: 15 }}>
                                        <span style={{ fontWeight: 'bold' }}>Url HTML Descrição:</span> <a href={temaSelecionado?.UrlImagemDescricaoTema} target="_blank">{temaSelecionado?.UrlImagemDescricaoTema ? 'Baixar' : ''}</a>
                                    </Alert>

                                    {
                                        imagensHtml.map((img, idx) => {
                                            return <Grid item key={`imagem-${img.id}`} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>{img.nome}</Typography>
                                                <UploadImagemContainer htmlFor={`file-upload-image-${img.id}`} style={{ cursor: 'pointer', width: 920 }}>

                                                    {
                                                        img.file === null &&
                                                        <AttachFileIcon sx={{ fontSize: "7rem", color: "gray", marginBottom: 10 }} />
                                                    }

                                                    {
                                                        img.file !== null &&
                                                        <img src={img.file} id={`img-html-${img.id}`} width={'100%'} style={{ maxWidth: 920 }} />
                                                    }

                                                    <input
                                                        type="file"
                                                        accept="image/png, image/gif, image/jpeg, image/webp"
                                                        id={`file-upload-image-${img.id}`}
                                                        name="myImage"
                                                        style={{ fontSize: 10, marginTop: 20 }}
                                                        onChange={(e) => {
                                                            if (!temaSelecionado?.UrlImagemDescricaoTema) {
                                                                dispatch({
                                                                    type: CONTROLEMENSAGEM_AVISO,
                                                                    tipoComponente: 'alert',
                                                                    titulo: 'Aviso',
                                                                    message: `Favor selecionar um tema`,
                                                                });

                                                                return;
                                                            }

                                                            const arrImagens = [...imagensHtml];
                                                            const file = e?.target?.files[0];

                                                            arrImagens[idx].file = file;

                                                            setImagensHtml(arrImagens);

                                                            const reader = new FileReader();

                                                            reader.onload = function (e) {
                                                                const imgElement = document.getElementById(`img-html-${img.id}`);
                                                                const imagem = e.target.result;

                                                                imgElement.src = imagem;
                                                            };

                                                            reader.readAsDataURL(file);
                                                        }}
                                                        onClick={(e) => (e.target.value = null)}
                                                    />
                                                </UploadImagemContainer>

                                                <Typography style={{ fontSize: 12, marginTop: 15 }}>Url: <span style={{ fontWeight: 'bold' }}>{img.url ? img.url : 'Arquivo ainda não enviado'}</span> {img.url && <ContentCopyIcon style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(img.url)} />} </Typography>
                                            </Grid>
                                        })
                                    }

                                    <Button disabled={imagensHtml.filter(a => a.file !== null).length === 0} variant="contained" style={{ marginTop: 15 }} onClick={() => handleSalvarImagensDescricao()}>Salvar imagens</Button>
                                </Grid>


                            </Box>
                        }

                        {
                            tabSelected === 1 &&
                            <Box style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: 40 }}>
                                {
                                    temaSelecionado?.UrlImagemDescricaoTema && <iframe
                                        className="iframeModal"
                                        title="Descrição temas"
                                        style={{ border: 'none', overflow: 'auto', minWidth: '700px', minHeight: '1000px', padding: 20 }}
                                        width="100%"
                                        height="100%"
                                        loading="lazy"
                                        allowFullScreen
                                        src={temaSelecionado?.UrlImagemDescricaoTema}
                                    ></iframe>
                                }

                                {
                                    !temaSelecionado?.UrlImagemDescricaoTema && <HtmlIcon style={{ fontSize: 300 }} />
                                }

                                {/* <Box style={{ width: 300, paddingBottom: 20, marginBottom: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Arquivo HTML novo</Typography>
                                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}><a href="https://leapstyle.blob.core.windows.net/crm-templates-bio/coffe.html" target="_blank">HTML exemplo</a></Typography>
                                    <UploadImagemContainer htmlFor={`file-upload-html`} style={{ cursor: 'pointer' }}>
                                        <AttachFileIcon sx={{ fontSize: "7rem", color: "gray", marginBottom: 10 }} />

                                        <input
                                            type="file"
                                            accept="file/*"
                                            id={`file-upload-html`}
                                            name="myImage"
                                            style={{ fontSize: 10 }}
                                            onChange={(e) => {
                                                if (!temaSelecionado) {
                                                    dispatch({
                                                        type: CONTROLEMENSAGEM_AVISO,
                                                        tipoComponente: 'alert',
                                                        titulo: 'Aviso',
                                                        message: `Favor selecionar um tema`,
                                                    });

                                                    return;
                                                }

                                                setHtmlTemaSelecionada(e?.target?.files[0]);
                                            }}
                                            onClick={(e) => (e.target.value = null)}
                                        />
                                    </UploadImagemContainer>
                                    <Button disabled={!htmlTemaSelecionado} variant="contained" style={{ marginTop: 15 }} onClick={() => handleSalvarHtml()}>Salvar HTML</Button>
                                </Box> */}

                            </Box>
                        }

                    </Grid>

                </Grid>

            </Box>
        </Card>
    </Container>
}

export default ControleTema;
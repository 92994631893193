import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, forwardRef } from 'react';
import { Snackbar, Alert as MuiAlert, Box, DialogTitle, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CONTROLEMENSAGEM_CLOSE } from '../../store/ControleMensagemReducer/types';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import { isMobile } from '../../services/DeviceServices';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getMensagens = (mensagens = []) => {
  let mensagem = null;

  if(mensagens.includes('\n')){
    mensagens = mensagens.split('\n');
  }

  if (mensagens && Array.isArray(mensagens)) {
    mensagem = mensagens.map((mensagem, index) => {
      if(mensagem === '') return null;
      return (
        <div key={index}>
          <span style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold' }}>{mensagem}</span>
          <br />
        </div>
      );
    });
  } else {
    return (
      <div style={{ marginTop: 10, fontSize: 16, fontWeight: 'bold' }}>
        {mensagens}
        <br />
      </div>
    );
  }
  return mensagem;
};

const container = {
  position: 'absolute',
  top: '0%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 35,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ControleMensagem = () => {
  const controle = useSelector((store) => store.controleMensagem);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(controle.open);
  }, [controle]);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') return false;

    dispatch({
      type: CONTROLEMENSAGEM_CLOSE,
    });
  };

  const getColor = () => {
    if (controle.tipo === 'success') {
      return 'rgb(33, 133, 63)';
    }
    else if (controle.tipo === 'error') {
      return 'rgb(219, 49, 15)';
    }
    else if (controle.tipo === 'warning') {
      return 'rgb(237, 108, 2)';
    }
    else {
      return 'rgb(25, 118, 210)'
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') return false;
    //if (reason === 'escapeKeyDown') return false;

    setOpen(false);
  };

  return (
    <>
      {controle.tipoComponente === 'alert' ? (
        <Snackbar
          key="alert"
          autoHideDuration={3000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
        >
          <Alert
            variant="filled"
            severity={controle.tipo}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: 'black',
              background: 'white'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: 20,
                fontWeight: 'bold',
                alignItems: 'center',
              }}
            >
              {controle.titulo}
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{
                  p: 0.5,
                  marginLeft: 10,
                  //'.MuiSvgIcon-root': { color: getColor() }
                }}
                onClick={handleCloseAlert}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {controle.message?.length > 0 &&
              <div style={{ marginTop: 10 }}>{getMensagens(controle.message)}</div>
            }
            <LinearProgress
              value={100}
              sx={(theme) => ({
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                '.MuiLinearProgress-bar': {
                  backgroundColor: getColor()
                },
                '--LinearProgress-radius': '0px',
              })}
            />
          </Alert>
        </Snackbar>
      ) : (
        <Modal
          key="modal"
          open={open}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '10%'
          }}
          keepMounted
          disableAutoFocus
          onClose={handleClose}
        >
          <Box
            sx={[
              container,
              {
                width: isMobile() ? '100%' : '50%',
                height: 'auto',
                borderRadius: 2,
                backgroundColor: 'rgb(253, 237, 237)',
              },
            ]}
          >
            <BootstrapDialogTitle id="componenteMensagem" onClose={handleClose}>
              <Box sx={{ fontSize: 20, marginLeft: -2 }}>Aviso/Erro</Box>
            </BootstrapDialogTitle>
            <br />
            {getMensagens(controle.message)}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ControleMensagem;

import * as Sentry from "@sentry/react";
import { useEffect } from "react";

import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

export function initSentry() {
    Sentry.init({
        dsn: process.env.REACT_APP_TOKEN_SENTRY,
        integrations: [
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        beforeSend(event) {
            if (event.exception && event.exception.values) {
                const errorDetails = event.exception.values[0];

                // Verifica se o erro é uma Promise rejection
                if (errorDetails.type === 'UnhandledRejection') {
                    // Acessa o objeto rejeitado e verifica se a chave específica existe
                    const rejectionReason = errorDetails.value; // Razão pela qual a Promise foi rejeitada

                    // Suponha que o objeto tenha uma chave específica chamada "minhaChave"
                    if (rejectionReason && typeof rejectionReason === 'object' && rejectionReason.includes('message, tipoComponente, titulo')) {
                        // Ignora o erro e não o envia para o Sentry
                        return null;
                    }
                }
            }

            if (event.request && event.request.url) {
                const url = event.request.url;

                if (url.includes('GetAllNotificacaoIdUsuarioLoja')) {
                    return null;
                }
            }
            return event;
        },
    });
}
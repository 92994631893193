import { Card } from "@mui/material";
import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
`;

export const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  /*
  background-color: ${({ bgColor, darkBgColor, isDarkMode }) =>
    isDarkMode ? darkBgColor : bgColor}; 
    */
  transition: 0.2s;
  cursor: pointer;

  @media (max-width:900px){
    width: 100%;
  }

  .card-header {
    margin: 0.3rem;
    padding: 0.3rem;
    border-radius: 4px;
    background-color: ${({ bgColor, darkBgColor, isDarkMode }) =>
      isDarkMode ? darkBgColor : bgColor};
    transition: 0.3s ease;
  }
  .card-body {
    padding: 1rem;
    height: 100%;
  }
  .value-diff {
    color: ${({ isDarkMode }) => (isDarkMode ? "lightgreen" : "green")};
  }
`;

import { useEffect, useState } from 'react';
import * as Styled from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, MenuItem, Select, TextField, Typography } from '@mui/material';
import { TEMA_LOJA_SET_OPTION_SITE } from '../../store/TemaLojaReducer/types';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import VpnLockOutlinedIcon from '@mui/icons-material/VpnLockOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';

const OpcoesSite = () => {
    const dispatch = useDispatch();
    const themeStorage = useSelector((store) => store.temaLoja);
    const [themeStoreSelected, setThemeStoreSelected] = useState(null);

    useEffect(() => {
        const themeStore = themeStorage.themes_store.filter(a => a.id === themeStorage.idThemeStoreSelected)[0];
        setThemeStoreSelected(themeStore);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickCheckOption = (idItem) => {
        const options = [...themeStoreSelected.opcoes_site];
        options.forEach(m => {
            if (m.id === idItem) {
                m.selected = !m.selected;
            }
            return m;
        });
        dispatch({
            type: TEMA_LOJA_SET_OPTION_SITE,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: options
        });
    }

    const handleChangeVariableOption = (idItem, value) => {
        const options = [...themeStoreSelected.opcoes_site];

        options.forEach(m => {
            if (m.id === idItem) {
                m.description = m.descriptionTmp.replace('_variable', value);
                m._variable = value;
            }
            return m;
        });

        dispatch({
            type: TEMA_LOJA_SET_OPTION_SITE,
            themeStorageId: themeStorage.idThemeStoreSelected,
            item: options
        });
    }

    const getIconByName = (name) => {
        const estilo = { fontSize: 50, marginLeft: 5 };

        if (name === 'local_shipping') {
            return <LocalShippingOutlinedIcon style={estilo} />;
        }
        else if (name === 'vpn_lock') {
            return <VpnLockOutlinedIcon style={estilo} />;
        }
        else if (name === 'credit_score') {
            return <CreditScoreOutlinedIcon style={estilo} />;
        }
        else if (name === 'change_circle') {
            return <CurrencyExchangeOutlinedIcon style={estilo} />;
        }

        return null;
    }

    return (
        themeStoreSelected &&
        <Styled.Container>
            <Box style={{ width: '100%' }}>
                <Typography style={{ fontSize: '20px', marginLeft: '10px', fontWeight: 'bold' }} variant="body2">Opções do Site</Typography>
            </Box>
            <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', height: 'auto', justifyContent: 'space-evenly' }}>
                {
                    themeStoreSelected.opcoes_site.map((item) => {
                        return (
                            <Styled.Item key={item.id}>
                                <Checkbox
                                    sx={{
                                        width: '20px', height: '20px'
                                    }}
                                    name={`option_site_${item.id}`}
                                    id={`${item.id}`}
                                    checked={item.selected}
                                    onChange={() => handleClickCheckOption(item.id)}
                                />

                                {getIconByName(item.icon)}

                                <Box style={{ marginLeft: '10px' }}>
                                    <Typography style={{ fontSize: '14px', fontWeight: 'bold' }} variant="body2">{item.name}</Typography>
                                    {
                                        item.id !== 3 && item._variable !== 'null' &&
                                        <TextField
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            value={item._variable}
                                            onChange={(e) => handleChangeVariableOption(item.id, e.target.value)}
                                        />
                                    }
                                    {
                                        item.id === 3 &&
                                        <Select
                                            labelId="genero-selector"
                                            id="genero-selector-id"
                                            value={item._variable}
                                            label="Gênero"
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                handleChangeVariableOption(item.id, e.target.value)
                                            }}
                                            MenuProps={{
                                                disablePortal: true,
                                                PaperProps: {
                                                    sx: { maxHeight: 300 },
                                                    style: {
                                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value={'1X'}>1X</MenuItem>
                                            <MenuItem value={'2X'}>2X</MenuItem>
                                            <MenuItem value={'3X'}>3X</MenuItem>
                                            <MenuItem value={'4X'}>4X</MenuItem>
                                            <MenuItem value={'5X'}>5X</MenuItem>
                                            <MenuItem value={'6X'}>6X</MenuItem>
                                            <MenuItem value={'7X'}>7X</MenuItem>
                                            <MenuItem value={'8X'}>8X</MenuItem>
                                            <MenuItem value={'9X'}>9X</MenuItem>
                                            <MenuItem value={'10X'}>10X</MenuItem>
                                            <MenuItem value={'11X'}>11X</MenuItem>
                                            <MenuItem value={'12X'}>12X</MenuItem>
                                            <MenuItem value={'13X'}>13X</MenuItem>
                                            <MenuItem value={'14X'}>14X</MenuItem>
                                            <MenuItem value={'15X'}>15X</MenuItem>
                                            <MenuItem value={'16X'}>16X</MenuItem>
                                            <MenuItem value={'17X'}>17X</MenuItem>
                                            <MenuItem value={'18X'}>18X</MenuItem>
                                            <MenuItem value={'19X'}>19X</MenuItem>
                                            <MenuItem value={'20X'}>20X</MenuItem>
                                            <MenuItem value={'21X'}>21X</MenuItem>
                                            <MenuItem value={'22X'}>22X</MenuItem>
                                            <MenuItem value={'23X'}>23X</MenuItem>
                                            <MenuItem value={'24X'}>24X</MenuItem>
                                        </Select>
                                    }
                                    <Typography style={{ fontSize: '12px' }} variant="body2">{item.description}</Typography>
                                </Box>
                            </Styled.Item>
                        )
                    })
                }
            </Box>
        </Styled.Container>
    )
}

export default OpcoesSite;

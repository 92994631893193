import { Box, Typography } from '@mui/material';
import { Container } from './styles';
import { useNavigate } from 'react-router';
import OpcoesFormasEntrega from './opcoesFormasEntrega';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { handleTrack } from '../../../services/API';

const FormasEntrega = () => {
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    useEffect(() => {
        if(userLoggedIn === null) return;

        (async() => {
            await handleTrack(userLoggedIn.IdUsuario, 'FormasEntrega', `Carregando tela de formas de entrega`);
        })();
    }, [userLoggedIn]);

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Configuração / Formas de entrega</Typography>
                </Box>
            </Box>
            <OpcoesFormasEntrega />
        </Container>
    )
}

export default FormasEntrega;

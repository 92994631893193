import { 
    Autocomplete, 
    Box, 
    Button, 
    FormControl, 
    Modal, 
    TextField, 
    Typography, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Stack, 
    InputAdornment, 
    MenuItem, 
    Select, 
    InputLabel, 
    Checkbox 
} from "@mui/material";
import Card from "../../../components/Card";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import * as moment from "moment";
import BootstrapDialogTitle from "../../../components/Modal/BootstrapDialogTitle";
import { 
    POST_DATA, 
    PUT_DATA, 
    SalvarLogSentry, 
    GetAllModuloCRUD, 
    GetEADCategoriaModulo, 
    InsertEADModulo, 
    GetModuloById, 
    UpdateModuloById, 
    GetAllEadModuloCurso,
    GetModuloCursoById,
    UpdateCursoById,
    UploadFileAzure,
    InsertEADModuloCurso
} from "../../../services/API";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from 'react-color';
import { ImagemSemResultado } from "../../../services/utils/functions";
import LoadingAllPage from "../../../components/LoadingAllPage";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router";

const columns = [
    { id: "IdCategoria", label: "Id", minWidth: 100 },
    { id: "descricao", label: "Descrição", maxWidth: 120, align: "left", },
    { id: "ativo", label: "Ativo", minWidth: 90, maxWidth: 100 }
];

const CadastrosGerais = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [openModal, setOpenModal] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [pecas, setPecas] = useState([]);
    const [tipoCadastro, setTipoCadastro] = useState(1);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [columnsGrid, setColumnsGrid] = useState(columns);
    const [rows, setRows] = useState([]);
    const [random, setRandom] = useState(null);

    const [categoryModal, setCategoryModal] = useState(false);
    const [changeModulo, setChangeModulo] = useState(false);
    const [eadCategory, setEadCategory] = useState([]);
    const [eadModulo, setEadModulo] = useState([]);
    const [eadCategoryValue, setEadCategoryValue] = useState(null);
    const [eadCategoryValueCurso, setEadCategoryValueCurso] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [newModulo, setNewModulo] = useState({
        titulo: '',
        descricao: '',
        tempo: '',
        destino: '',
        maturidade: ''
    })
    const [newCurso, setNewCurso] = useState({
        idModulo: null,
        titulo: '',
        descricao: '',
        caminhaCapa: '',
        caminhaVideo: '',
        tempo: '',
        destino: '',
        maturidade: '',
        flgAtivo: true
    })

    const [sexoId, setSexoId] = useState(-1);

    const [cor, setCor] = useState(null);
    const [idAutocomplete, setIdAutoComplete] = useState(0);
    const [inputAutocomplete, setInputAutocomplete] = useState(null);
    const [inputValueAutocomplete, setInputValueAutocomplete] = useState(null);

    const [valorSalvar, setValorSalvar] = useState('');

    const container = {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        (async () => {
            await fetchDados();
        })();
    }, [tipoCadastro, random]);

    useEffect(() => {
        const getModulo = async () => {
            setEadModulo(null)
            setSelectedImage(null)
            setEadCategoryValueCurso(null)
            var modulos = await GetAllModuloCRUD()
            const _modulos = modulos.filter(res => res.FlgAtivo)
            setEadModulo(_modulos)
            
        }

        if(tipoCadastro === 10) {
            getModulo()
        }
    }, [tipoCadastro])

    const fetchDados = async () => {
        try {
            setLoading(true);

            setRows([]);
            setColumnsGrid(columns);

            let dados = [];
            let result = [];

            if (tipoCadastro === 1) {
                result = await POST_DATA(`Categoria/GetAllCategoria?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCategoria&Log=`);
                dados = result.map(r => { return { id: r.IdCategoria, descricao: r.Descricao, flgAtivo: r.FlagAtivo } });
                setCategorias(dados);
            }
            else if (tipoCadastro === 2) {
                result = await POST_DATA(`Material/GetAllMaterial`);
                dados = result.map(r => { return { id: r.IdMaterial, descricao: r.Descricao, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 3) {
                result = await POST_DATA(`Marca/GetAllMarca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMarca&Log=`);
                dados = result.map(r => { return { id: r.IdMarca, descricao: r.Nome, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 4) {
                setColumnsGrid([
                    { id: "IdCategoria", label: "Id", minWidth: 100 },
                    { id: "descricao", label: "Descrição", maxWidth: 120, align: "left", },
                    { id: "categoria", label: "Categoria", maxWidth: 120, align: "left", },
                    { id: "ativo", label: "Ativo", minWidth: 90, maxWidth: 100 }
                ]);

                result = await POST_DATA(`Peca/GetAllPeca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdPeca&Log=`);
                dados = result.map(r => { return { id: r.IdPeca, descricao: r.Nome, categoria: r.IdCategoria, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 5) {
                result = await POST_DATA(`Cor/GetAllCor?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCor&Log=`);
                dados = result.map(r => { return { id: r.IdCor, descricao: r.Descricao, flgAtivo: r.FlagAtivo } });
            }
            else if (tipoCadastro === 6) {
                result = await POST_DATA(`Estampa/GetAllEstampa?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdEstampa&Log=`);
                dados = result.map(r => { return { id: r.IdEstampa, descricao: r.Descricao, flgAtivo: r.FlagAtivo } });
            }
            else if (tipoCadastro === 7) {
                result = await POST_DATA(`NotificacaoCRM/GetAllNotificacaoCRMByValorExato?strValorExato=-1&ColunaParaValorExato=idUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=id&Log=`);
                dados = result.map(r => { return { id: r.id, descricao: r.sDescricao, flgAtivo: r.FlagAtivo } });
            }
            else if (tipoCadastro === 8) {
                setColumnsGrid([
                    { id: "IdCategoria", label: "Id", minWidth: 100 },
                    { id: "peca", label: "Peça", maxWidth: 120, align: "left", },
                    { id: "descricao", label: "Sub Peça", maxWidth: 120, align: "left", },
                    { id: "ativo", label: "Ativo", minWidth: 90, maxWidth: 100 }
                ]);

                result = await POST_DATA(`SubPeca/GetAllSubPeca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdSubPeca&Log=`);
                dados = result.map(r => { return { id: r.IdSubPeca, idPeca: r.IdPeca, descricao: r.Nome, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 9) {
                setColumnsGrid([
                    { id: "IdCategoria", label: "#"},
                    { id: "descricao", label: "Titulo"},
                    { id: "cateM", label: "Categoria Modulo"},
                    { id: "ativo", label: "Ativo"},
                    { id: "alterar", label: "Alterar"}
                ]);
                var category = await GetEADCategoriaModulo() // pega a categoria para editar
                setEadCategory(category)

                result = await GetAllModuloCRUD();

                dados = result.map(r => { return { 
                    id: r.IdModulo, 
                    descricao: r.Titulo, 
                    categoriaM: r._CategoriaModulo.Descricao, 
                    flgAtivo: r.FlgAtivo, 
                    alterar: 'Alterar' 
                }});
            }
            else if (tipoCadastro === 10) {
                setColumnsGrid([
                    { id: "IdCategoriaCurso", label: "#"},
                    { id: "moduloCurso", label: "Modulo"},
                    { id: "tituloCurso", label: "Titulo"},
                    { id: "descricaoCurso", label: "Descrição"},
                    { id: "tempoCurso", label: "Tempo"},
                    { id: "ativoCurso", label: "Ativo"},
                    { id: "alterarCurso", label: "Alterar"}
                ]);

                result = await GetAllEadModuloCurso();

                if(result !== null) {
                    dados = result.map(r => { return {
                        idModuloCurso: r.IdModuloCurso,
                        id: r.IdModuloCurso,
                        modulo: r._Modulo.Titulo,
                        titulo: r.Titulo,
                        descricao: r.Descricao,
                        tempo: r.Tempo,
                        flgAtivo: r.FlgAtivo,
                        alterar: 'Alterar'
                    } });
                }
            }

            if (search.length > 0)
                setRows(dados.filter(a => a.descricao.toUpperCase().includes(search.toUpperCase())));
            else
                setRows(dados);

            setLoading(false);
        } catch (error) {
            SalvarLogSentry(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (openModal) {
            setValorSalvar('');
        }
    }, [openModal]);

    const getLabel = () => {
        let label = '';

        switch (tipoCadastro) {
            case 1:
                label = 'Categoria'
                break;
            case 2:
                label = 'Material'
                break;
            case 3:
                label = 'Marca'
                break;
            case 4:
                label = 'Peça'
                break;
            case 5:
                label = 'Cor'
                break;
            case 6:
                label = 'Estampa'
                break;
            case 7:
                label = 'Notificação'
                break;
            default:
                label = '';
                break;
        }

        return label;
    }

    const handleSalvar = async () => { //novo
        if (valorSalvar.length === 0 && tipoCadastro !== 9 && tipoCadastro !== 10) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: `Favor informar a ${getLabel()}`,
            });
            return;
        }

        let result = null;
        if (tipoCadastro === 1) { //Categoria
            result = await POST_DATA(`Categoria/GetAllCategoriaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCategoria&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Categoria/CadastroCategoria`, { Descricao: valorSalvar, FlagAtivo: true });
        }
        else if (tipoCadastro === 2) { //Material
            result = await POST_DATA(`Material/GetAllMaterialByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMaterial&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Material/InsertMaterial`, { Descricao: valorSalvar, FlgAtivo: true });
        }
        else if (tipoCadastro === 3) { //Marca
            result = await POST_DATA(`Marca/GetAllMarcaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMarca&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Marca/CadastroMarca`, { Nome: valorSalvar, FlgAtivo: true, IdIncluidoPor: userLoggedIn.IdUsuario });
        }
        else if (tipoCadastro === 4) { //Peça            
            result = await POST_DATA(`Peca/GetAllPecaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdPeca&Log=`, {});

            const pecaaXCategoria = result.filter(a => a.IdCategoria === idAutocomplete);
            if (pecaaXCategoria != null && pecaaXCategoria.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Peca/CadastroPeca`, { Nome: valorSalvar, IdCategoria: idAutocomplete, FlgAtivo: true, IdIncluidoPor: userLoggedIn.IdUsuario });
        }
        else if (tipoCadastro === 5) { //Cor
            result = await POST_DATA(`Cor/GetAllCorByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCor&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Cor/CadastroCor`, { Descricao: valorSalvar, hexadecimal: cor, FlagAtivo: true });
        }
        else if (tipoCadastro === 6) { //Estampa
            result = await POST_DATA(`Estampa/GetAllEstampaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdEstampa&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Estampa/CadastroEstampa`, { Descricao: valorSalvar, FlagAtivo: true });
        }
        else if (tipoCadastro === 7) { //Notificação
            result = await POST_DATA(`NotificacaoCRM/GetAllNotificacaoCRMByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=sDescricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=id&Log=`);

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`NotificacaoCRM/CadastroNotificacaoCRM`, { idTipoNotificacaoCRM: 1, idUsuarioEmpresa: -1, sDescricao: valorSalvar, FlagAtivo: true });
        }
        else if (tipoCadastro === 8) { //Sub Peças
            result = await POST_DATA(`SubPeca/GetAllSubPecaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdSubPeca&Log=`);

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`SubPeca/CadastroSubPeca`, { IdPeca: idAutocomplete, IdIncluidoPor: -1, Nome: valorSalvar, FlgAtivo: true });
        }
        else if (tipoCadastro === 9) { //Modulo
            let image = null

            if (selectedImage) {
                const extensao = selectedImage.type.split("/")[1]
                const nomeArquivo = `fotoCapa${moment().format('DDMMYYYYHHmmss')}.${extensao[1]}`
                const pasta = `ead/modulosCurso`
                image = await UploadFileAzure(selectedImage, pasta, nomeArquivo, extensao)
            }

            const data = {
                IdCategoriaModulo: eadCategoryValue.IdCategoriaModulo,
                Titulo: newModulo.titulo,
                Descricao: newModulo.descricao,
                Tempo: newModulo.tempo,
                Destino: newModulo.destino,
                Maturidade: newModulo.maturidade,
                CaminhaCapa: image ? image : null,
                FlgAtivo: true
            }

            result = await InsertEADModulo(data)


            setNewModulo({
                titulo: '',
                descricao: '',
                tempo: '',
                destino: '',
                maturidade: ''
            })

            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: `Sucesso ao salvar o item`,
                });
                setSelectedImage(null)
                setOpenModal(false);
                await fetchDados();
            }
        }
        else if (tipoCadastro === 10) {
            let image = null

            if (selectedImage) {
                const extensao = selectedImage.type.split("/")[1]
                const nomeArquivo = `fotoCapa${moment().format('DDMMYYYYHHmmss')}.${extensao[1]}`
                const pasta = `ead/modulosCurso`
                image = await UploadFileAzure(selectedImage, pasta, nomeArquivo, extensao)
            }

            const data = {
                IdModulo: eadCategoryValueCurso.IdModulo,
                Titulo: newCurso.titulo,
                Descricao: newCurso.descricao,
                Tempo: newCurso.tempo,
                Destino: newCurso.destino,
                Maturidade: newCurso.maturidade,
                CaminhaVideo: newCurso.caminhaVideo,
                CaminhaCapa: image ? image : null,
                FlgAtivo: true
            }

            result = await InsertEADModuloCurso(data)

            setNewCurso({
                idModulo: null,
                titulo: '',
                descricao: '',
                caminhaCapa: '',
                caminhaVideo: '',
                tempo: '',
                destino: '',
                maturidade: '',
                flgAtivo: true
            })

            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: `Sucesso ao salvar o item`,
                });
                setSelectedImage(null)
                setOpenModal(false);
                await fetchDados();
            }
        }
    }

    const handleGetInfo = async (item) => {
        setChangeModulo(null)
        setSelectedImage(null)
        setEadCategoryValue(null)
        setCategoryModal(true)

        if(tipoCadastro === 9) {
            const response = await GetModuloById(item.id)
            var category = await GetEADCategoriaModulo()
            setEadCategory(category)
    
            if(response !== null) {
                setChangeModulo(response)
                setSelectedImage(response.CaminhaCapa)
                const categorySelected = category.filter((res) => res.IdCategoriaModulo === response.IdCategoriaModulo)
                setEadCategoryValue(categorySelected[0])
            }
        }

        if(tipoCadastro === 10) {
            const response = await GetModuloCursoById(item.idModuloCurso)
            var modulos = await GetAllModuloCRUD()
            const _modulos = modulos.filter(res => res.FlgAtivo)
            
            if(response !== null) {
                setEadModulo(_modulos)
                setChangeModulo(response)
                setSelectedImage(response.CaminhaCapa)
                const moduloSelected = modulos.filter((res) => res.IdModulo === response.IdModulo)
                setEadCategoryValue(moduloSelected[0])
            }
        }
    }

    const handleAlterarModulo = async () => { //ALTERAR
        let image = null

        if (selectedImage && typeof selectedImage !== 'string') {
            const extensao = selectedImage.type.split("/")[1]
            const nomeArquivo = `fotoCapa${moment().format('DDMMYYYYHHmmss')}.${extensao[1]}`
            const pasta = `ead/modulosCurso`
            image = await UploadFileAzure(selectedImage, pasta, nomeArquivo, extensao)
        }

        if (tipoCadastro === 9) {
            let data = {
                IdModulo: changeModulo.IdModulo,
                IdCategoriaModulo: eadCategoryValue.IdCategoriaModulo,
                Titulo: changeModulo.Titulo,
                Descricao: changeModulo.Descricao,
                Tempo: changeModulo.Tempo,
                Destino: changeModulo.Destino,
                Maturidade: changeModulo.Maturidade,
                CaminhaCapa: image ? image : changeModulo.CaminhaCapa,
                FlgAtivo: changeModulo.FlgAtivo
            }
    
            const response = await UpdateModuloById(data)

            if (response) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: `Sucesso ao salvar o item`,
                });
                setSelectedImage(null)
                setCategoryModal(false);
                await fetchDados();
            }
        }

        if (tipoCadastro === 10) {
            let data = {
                IdModuloCurso: changeModulo.IdModuloCurso,
                IdModulo: eadCategoryValue.IdModulo,
                Titulo: changeModulo.Titulo,
                Descricao: changeModulo.Descricao,
                CaminhaCapa: image ? image : changeModulo.CaminhaCapa,
                CaminhaVideo: changeModulo.CaminhaVideo,
                Tempo: changeModulo.Tempo,
                Destino: changeModulo.Destino,
                Maturidade: changeModulo.Maturidade,
                FlgAtivo: changeModulo.FlgAtivo
            }
    
            const response = await UpdateCursoById(data)

            if (response) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: `Sucesso ao salvar o item`,
                });
                setSelectedImage(null)
                setCategoryModal(false);
                await fetchDados();
            }
        }
    }

    return (
        <Container>
            {
                loading &&
                <LoadingAllPage text={`Buscando por ${getLabel()}...`} />
            }

            {
                openModal && //novo
                <Modal
                    key="modal"
                    open={openModal}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '10%',
                    }}
                    keepMounted
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '50%',
                                height: tipoCadastro === 9 || tipoCadastro === 10 ? 600 : 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                                overflowY: "scroll"
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteMensagem" onClose={() => setOpenModal(false)}>
                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>Cadastrar {getLabel()}</Box>
                        </BootstrapDialogTitle>

                        {tipoCadastro !== 9 && tipoCadastro !== 10 && <Box style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                id="cadastrp"
                                label={getLabel()}
                                placeholder={getLabel()}
                                variant="outlined"
                                type='text'
                                value={valorSalvar}
                                onChange={(e) => {
                                    setValorSalvar(e.target.value);
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 16 }
                                }}
                            />
                        </Box>}

                        {
                            tipoCadastro === 4 &&
                            <Box style={{ width: '100%', marginTop: 10 }}>
                                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 15 }}>
                                    <InputLabel id="cor-selector-label">Sexo</InputLabel>
                                    <Select
                                        variant="outlined"
                                        id="label-select"
                                        value={sexoId}
                                        label="Selecione o sexo"
                                        onChange={(e) => setSexoId(Number(e.target.value))}
                                        style={{ marginTop: 10, width: '100%', color: 'black' }}
                                        MenuProps={{
                                            sx: { maxHeight: 300 },
                                            disablePortal: true,
                                            PaperProps: {
                                                style: {
                                                    transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={-1}>Nenhum(a)</MenuItem>
                                        <MenuItem value={0}>Masculino</MenuItem>
                                        <MenuItem value={1}>Feminino</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        id={`categoria`}
                                        disabled={sexoId === -1}
                                        options={categorias}
                                        value={inputAutocomplete || categorias[0]}
                                        defaultValue={0}
                                        getOptionLabel={(option) => option?.Descricao || 'Acessórios'}
                                        onChange={(event, newValue) => {
                                            setIdAutoComplete(newValue?.IdCategoria);
                                            setInputAutocomplete(newValue);
                                        }}
                                        inputValue={inputValueAutocomplete}
                                        onInputChange={(event, newInputValue) => {
                                            if (newInputValue === '') setIdAutoComplete(0);
                                            setIdAutoComplete(newInputValue?.IdCategoria);
                                            setInputValueAutocomplete(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Categoria" placeholder="Categoria" />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        }

                        {
                            tipoCadastro === 8 &&
                            <Box style={{ width: '100%', marginTop: 10 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        id={`peca`}
                                        options={pecas}
                                        value={inputAutocomplete || pecas[0]}
                                        defaultValue={0}
                                        getOptionLabel={(option) => option?.Nome}
                                        onChange={(event, newValue) => {
                                            setIdAutoComplete(newValue?.IdPeca);
                                            setInputAutocomplete(newValue);
                                        }}
                                        inputValue={inputValueAutocomplete}
                                        onInputChange={(event, newInputValue) => {
                                            if (newInputValue === '') setIdAutoComplete(0);
                                            setIdAutoComplete(newInputValue?.IdPeca);
                                            setInputValueAutocomplete(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Peça" placeholder="Peça" />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        }

                        {
                            tipoCadastro === 5 &&
                            <Box style={{ marginTop: 10 }}>
                                <SketchPicker
                                    color={cor || '#fffff'}
                                    onChangeComplete={(color) => {
                                        setCor(color.hex);
                                    }}
                                />
                            </Box>
                        }

                        {
                            tipoCadastro === 9 &&
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                <div>
                                    <h5>Titulo</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            id="titulo"
                                            variant="outlined"
                                            type='text'
                                            value={newModulo.titulo}
                                            onChange={(e) => {
                                                setNewModulo(prevState => ({
                                                  ...prevState,
                                                  titulo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Categoria</h5>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={(event) => setEadCategoryValue(event.target.value)}
                                            value={eadCategoryValue || ''}
                                            MenuProps={{
                                                disablePortal: true,
                                                PaperProps: {
                                                    style: {
                                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                    },
                                                },
                                            }}
                                        >
                                        {eadCategory.map((item) => (
                                            <MenuItem
                                                key={item.IdCategoriaModulo}
                                                value={item}
                                            >
                                                {item.Descricao}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div>
                                    <h5>Descrição</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={7}
                                            id="descricao"
                                            variant="outlined"
                                            type='text'
                                            value={newModulo.descricao}
                                            onChange={(e) => {
                                                setNewModulo(prevState => ({
                                                  ...prevState,
                                                  descricao: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Tempo em minutos</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="tempo"
                                            variant="outlined"
                                            type='text'
                                            value={newModulo.tempo}
                                            onChange={(e) => {
                                                setNewModulo(prevState => ({
                                                  ...prevState,
                                                  tempo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Destino</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="destino"
                                            variant="outlined"
                                            type='text'
                                            value={newModulo.destino}
                                            onChange={(e) => {
                                                setNewModulo(prevState => ({
                                                  ...prevState,
                                                  destino: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Maturidade</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="maturidade"
                                            variant="outlined"
                                            type='text'
                                            value={newModulo.maturidade}
                                            onChange={(e) => {
                                                setNewModulo(prevState => ({
                                                  ...prevState,
                                                  maturidade: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(event) => {
                                            // handleImageUpload
                                            const img = event.target.files[0];
                                            const imgsize = img.size / 1024;

                                            if (imgsize > 10000) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    titulo: 'Aviso' ,
                                                    message: 'Tamanho da imagem ultrapassa 10MB, favor tratá-la',
                                                    tipocomponente: 'alert'
                                                })
                                                return;
                                            }
                                            setSelectedImage(img);
                                        }}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" component="span">
                                        Selecionar Imagem
                                        </Button>
                                    </label>
                                    {selectedImage && <img style={{marginTop: 10}} src={selectedImage} alt="Selected" width="200" />}
                                </div>
                            </div>
                        }

                        {
                            tipoCadastro === 10 &&
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                <div>
                                    <h5>Modulo</h5>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            onChange={(event) => {setEadCategoryValueCurso(event.target.value)}}
                                            value={eadCategoryValueCurso || ''}
                                            MenuProps={{
                                                disablePortal: true,
                                                PaperProps: {
                                                    style: {
                                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                    },
                                                },
                                            }}
                                        >
                                            {eadModulo?.map((item) => (
                                                <MenuItem
                                                    key={item.IdModulo}
                                                    value={item}
                                                >
                                                    {item.Titulo}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div>
                                    <h5>Titulo</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            id="titulo"
                                            variant="outlined"
                                            type='text'
                                            value={newCurso.titulo}
                                            onChange={(e) => {
                                                setNewCurso(prevState => ({
                                                  ...prevState,
                                                  titulo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Descrição</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            id="descricao"
                                            multiline
                                            rows={7}
                                            variant="outlined"
                                            type='text'
                                            value={newCurso.descricao}
                                            onChange={(e) => {
                                                setNewCurso(prevState => ({
                                                  ...prevState,
                                                  descricao: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Tempo em minutos</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            id="tempo"
                                            variant="outlined"
                                            type='text'
                                            value={newCurso.tempo}
                                            onChange={(e) => {
                                                setNewCurso(prevState => ({
                                                  ...prevState,
                                                  tempo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Destino</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="destino"
                                            variant="outlined"
                                            type='text'
                                            value={newCurso.destino}
                                            onChange={(e) => {
                                                setNewCurso(prevState => ({
                                                  ...prevState,
                                                  destino: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Maturidade</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="maturidade"
                                            variant="outlined"
                                            type='text'
                                            value={newCurso.maturidade}
                                            onChange={(e) => {
                                                setNewCurso(prevState => ({
                                                  ...prevState,
                                                  maturidade: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Video</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="video"
                                            variant="outlined"
                                            type='text'
                                            value={newCurso.caminhaVideo}
                                            onChange={(e) => {
                                                setNewCurso(prevState => ({
                                                  ...prevState,
                                                  caminhaVideo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>


                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(event) => {
                                            // handleImageUpload
                                            const img = event.target.files[0];
                                            const imgsize = img.size / 1024;

                                            if (imgsize > 10000) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    titulo: 'Aviso' ,
                                                    message: 'Tamanho da imagem ultrapassa 10MB, favor tratá-la',
                                                    tipocomponente: 'alert'
                                                })
                                                return;
                                            }
                                            setSelectedImage(img);
                                        }}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" component="span">
                                        Selecionar Imagem
                                        </Button>
                                    </label>
                                    {selectedImage && <img style={{marginTop: 10}} src={selectedImage} alt="Selected" width="200" />}
                                </div>
                            </div>
                        }

                        <Box style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={() => handleSalvar()}>Salvar</Button>
                        </Box>
                    </Box>
                </Modal>
            }

            {
                categoryModal && //edita
                <Modal
                    key="modal"
                    open={categoryModal}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        top: '50%',
                        left: '10%',
                    }}
                    keepMounted
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '50%',
                                height: tipoCadastro === 9 || tipoCadastro === 10 ? 600 : 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                                overflowY: "scroll"
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteMensagem" onClose={() => setCategoryModal(false)}>
                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>Modificar{getLabel()}</Box>
                        </BootstrapDialogTitle>

                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                {/* {tipoCadastro === 10 && 
                                    <div>
                                        <h5>Id Modulo</h5>
                                        <Box style={{ marginTop: 10 }}>
                                            <TextField
                                                fullWidth
                                                id="id-modulo"
                                                variant="outlined"
                                                type='text'
                                                value={changeModulo?.IdModulo}
                                                onChange={(e) => {
                                                    setChangeModulo(prevState => ({
                                                    ...prevState,
                                                    IdModulo: e.target.value,
                                                    }));
                                                }}
                                                InputLabelProps={{
                                                    style: { fontSize: 16 }
                                                }}
                                            />
                                        </Box>
                                    </div>
                                } */}

                                <div>
                                    <h5>Titulo</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            id="titulo"
                                            variant="outlined"
                                            type='text'
                                            value={changeModulo?.Titulo}
                                            onChange={(e) => {
                                                setChangeModulo(prevState => ({
                                                  ...prevState,
                                                  Titulo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                {tipoCadastro === 9 && 
                                    <div>
                                        <h5>Categoria</h5>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(event) => {setEadCategoryValue(event.target.value)}}
                                                value={eadCategoryValue || ''}
                                                MenuProps={{
                                                    disablePortal: true,
                                                    PaperProps: {
                                                        style: {
                                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                        },
                                                    },
                                                }}
                                            >
                                            {eadCategory.map((item) => (
                                                <MenuItem
                                                    key={item.IdCategoriaModulo}
                                                    value={item}
                                                >
                                                    {item.Descricao}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }

                                {tipoCadastro === 10 && 
                                    <div>
                                        <h5>Modulo</h5>
                                        <FormControl sx={{ width: '100%' }}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                onChange={(event) => {setEadCategoryValue(event.target.value)}}
                                                value={eadCategoryValue || ''}
                                                MenuProps={{
                                                    disablePortal: true,
                                                    PaperProps: {
                                                        style: {
                                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                        },
                                                    },
                                                }}
                                            >
                                                {eadModulo.map((item) => (
                                                    <MenuItem
                                                        key={item.IdModulo}
                                                        value={item}
                                                    >
                                                        {item.Titulo}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                }

                                {tipoCadastro === 10 && <div>
                                    <h5>Link Video</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            id="caminha-video"
                                            variant="outlined"
                                            type='text'
                                            value={changeModulo?.CaminhaVideo}
                                            onChange={(e) => {
                                                setChangeModulo(prevState => ({
                                                  ...prevState,
                                                  CaminhaVideo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>}

                                <div>
                                    <h5>Descrição</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={7}
                                            id="descricao"
                                            variant="outlined"
                                            type='text'
                                            value={changeModulo?.Descricao}
                                            onChange={(e) => {
                                                setChangeModulo(prevState => ({
                                                  ...prevState,
                                                  Descricao: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Tempo em minutos</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="tempo"
                                            variant="outlined"
                                            type='text'
                                            value={changeModulo?.Tempo}
                                            onChange={(e) => {
                                                setChangeModulo(prevState => ({
                                                  ...prevState,
                                                  Tempo: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Destino</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="destino"
                                            variant="outlined"
                                            type='text'
                                            value={changeModulo?.Destino}
                                            onChange={(e) => {
                                                setChangeModulo(prevState => ({
                                                  ...prevState,
                                                  Destino: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    <h5>Maturidade</h5>
                                    <Box style={{ marginTop: 10 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            id="maturidade"
                                            variant="outlined"
                                            type='text'
                                            value={changeModulo?.Maturidade}
                                            onChange={(e) => {
                                                setChangeModulo(prevState => ({
                                                  ...prevState,
                                                  Maturidade: e.target.value,
                                                }));
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: 16 }
                                            }}
                                        />
                                    </Box>
                                </div>

                                <div>
                                    {changeModulo &&
                                        <h5>
                                            Ativo 
                                            <Checkbox 
                                                checked={changeModulo.FlgAtivo} 
                                                onClick={() => setChangeModulo(prevState => ({ ...prevState, FlgAtivo: !prevState.FlgAtivo }))} defaultChecked 
                                            />
                                        </h5>
                                    }
                                </div>

                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(event) => {
                                            // handleImageUpload
                                            const img = event.target.files[0];
                                            const imgsize = img.size / 1024;

                                            if (imgsize > 10000) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    titulo: 'Aviso' ,
                                                    message: 'Tamanho da imagem ultrapassa 10MB, favor tratá-la',
                                                    tipocomponente: 'alert'
                                                })
                                                return;
                                            }
                                            setSelectedImage(img);
                                        }}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" component="span">
                                        Selecionar Imagem
                                        </Button>
                                    </label>
                                    {selectedImage && <img style={{marginTop: 10}} src={selectedImage} alt="Selected" width="200" />}
                                </div>
                            </div>

                        <div style={{display: "flex", marginTop: 20, width: '100%', justifyContent: "space-between"}}>
                        <Box>
                            <Button variant="contained" onClick={() => handleAlterarModulo()}>Salvar</Button>
                        </Box>
                        </div>
                    </Box>
                </Modal>
            }

            <Box style={{ width: '100%' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Cadastros gerais</Typography>
            </Box>

            <Box style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                <Button variant="contained" style={{ background: tipoCadastro === 1 ? 'black' : null }} onClick={() => {
                    setTipoCadastro(1);
                }}>Categoria</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 2 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(2);
                }}>Material</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 3 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(3);
                }}>Marca</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 4 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(4);
                }}>Peça</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 8 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(8);
                }}>Sub Peça</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 5 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(5);
                }}>Cor</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === 6 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(6);
                }}>Estampa</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === 7 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(7);
                }}>Notificação</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 8 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(8);
                    navigate(`/controle-temas`);
                }}>Controle Temas</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === -1 ? 'black' : null }} variant="contained" onClick={() => {
                    navigate('/onboard-lojista');
                }}>Onboard Lojista</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === -1 ? 'black' : null }} variant="contained" onClick={() => {
                    navigate('/on-board-design-site');
                }}>Onboard Site</Button>
                
                <Button style={{ marginLeft: 5, background: tipoCadastro === -1 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(9);
                }}>Modulo</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === -1 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(10);
                }}>Video</Button>
            </Box>

            <Box style={{ width: '100%' }}>
                <Card style={{ marginTop: 10 }}>
                    <Box style={{ width: '100%', marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            label="Pesquisar"
                            placeholder='Descrição'
                            size="small"
                            sx={{ width: "300px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="start"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        setRandom(Math.random());
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>,
                                endAdornment: <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        await fetchDados();
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            }}
                        />

                        <Button style={{ marginLeft: 5 }} variant="contained" onClick={() => {
                            setSelectedImage(null)
                            setOpenModal(true);
                        }}>Novo</Button>
                    </Box>
                    <TableContainer sty>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnsGrid.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell key={`img-${row.id}`} align='left'>
                                                {row.id}
                                            </TableCell>
                                            {
                                                tipoCadastro === 8 &&
                                                <TableCell key={`categoria-${row.id}`} align='left'>
                                                    {pecas.filter(a => a.IdPeca === row.IdPeca)[0]?.Nome || "-"}
                                                </TableCell>
                                            }
                                            {
                                                tipoCadastro === 10 &&
                                                <>
                                                    <TableCell key={`video-${row.id}`} align='left'>
                                                        {row.modulo}
                                                    </TableCell>
                                                    <TableCell key={`video-${row.id}`} align='left'>
                                                        {row.titulo}
                                                    </TableCell>
                                                </>
                                            }
                                            <TableCell key={`nome-${row.id}`} align='left'>
                                                {row.descricao}
                                            </TableCell>
                                            {
                                                tipoCadastro === 4 &&
                                                <TableCell key={`categoria-${row.id}`} align='left'>
                                                    {row.categoria}
                                                </TableCell>
                                            }
                                            {
                                                tipoCadastro === 9 &&
                                                <TableCell key={`categoriaM-${row.id}`} align='left'>
                                                    {row.categoriaM}
                                                </TableCell>
                                            }
                                            {
                                                tipoCadastro === 10 &&
                                                <TableCell key={`video${row.id}`} align='left'>
                                                    {row.tempo}
                                                </TableCell>
                                            }
                                            <TableCell key={`ativo-${row.id}`} align='left'>
                                                {row.flgAtivo ? 'Sim' : 'Não'}
                                            </TableCell>
                                            {
                                                tipoCadastro === 9 || tipoCadastro === 10 ?
                                                <TableCell key={`categoria-${row.id}`} align='left'>
                                                    <Button variant="contained" component="span" onClick={() => {handleGetInfo(row)}}>
                                                        {row.alterar}
                                                    </Button>
                                                </TableCell>
                                                :
                                                null
                                            }
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                </Card>
            </Box>

        </Container>
    )
}

export default CadastrosGerais;
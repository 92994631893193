import { Fragment, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { Container } from "./styles";
import { Box, Select, MenuItem, Paper, Stack, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Switch, TextField, Alert } from "@mui/material";
import { useNavigate } from "react-router";
import { NumericFormat } from "react-number-format";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import * as moment from "moment";
import { ImagemSemResultado } from "../../../services/utils/functions";

const columns = [
    {
        id: "metaFaturamento",
        label: "Meta faturamento",
        minWidth: 150,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "metaVenda",
        label: "Meta venda (Qtd)",
        minWidth: 150,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR")
    },
    {
        id: "metaMes",
        label: "Mês",
        minWidth: 70,
        maxWidth: 70,
        align: "left",
        format: (value) => value.toFixed(2)
    },
    {
        id: "metaAno",
        label: "Ano",
        minWidth: 70,
        maxWidth: 70,
        align: "left",
        format: (value) => value.toFixed(2)
    },
    {
        id: "ativo",
        label: "Ativo",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 100,
        align: "left",
    },
];


const Metas = () => {
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const [fieldFocus, setFieldFocus] = useState('');

    const [metas, setMetas] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            fetchMetas();
        })();
    }, []);

    useEffect(() => {
        const tmpMetas = metas?.map(cupom => {
            return <Row meta={cupom} />
        });

        setRows(tmpMetas || []);
    }, [metas]);

    const fetchMetas = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoading(true);

        const result = await POST_DATA(`UsuarioEmpresa/GetAllLogMetaByIdUsuarioEmpresa?IdUsuarioEmpresa=${userLoggedIn.IdUsuarioEmpresa}&fVerTodos=true&fSomenteAtivos=false&join=true&maxInstances=0&order_by=DtAlteracao&Log=`);        

        const metas = [];
        let data = null;

        result.map(item => {
            data = moment(item.DtAlteracao);

            metas.push({
                Id: item.Id,
                IdLoja: item.IdUsuarioEmpresa,
                MetaMes: data.month() + 1,
                MetaAno: data.year(),
                MetaFaturamento: item.metaFaturamento,
                MetaVenda: item.metaVenda,
                DtAlteracao: item.DtAlteracao,
                FlgAtivo: item.FlgAtivo,
                Habilitado: !item.FlgAtivo
            })
        });

        setMetas(metas);
        setLoading(false);
    }

    const handleAdicionar = () => {
        const meta = {
            Id: 0,
            IdLoja: userLoggedIn.IdUsuarioEmpresa,
            MetaMes: 1,
            MetaAno: 2023,
            MetaVenda: 0,
            MetaFaturamento: 0,
            DtAlteracao: null,
            FlgAtivo: true
        }

        setMetas([...metas, meta]);
        setFieldFocus('MetaFaturamento0');
    }

    const handleChangeMeta = (id, property, value) => {
        const tmpMetas = [...metas];
        tmpMetas.forEach(item => {
            if (item.Id === id) {
                item[property] = value;
            }
        });

        setMetas(tmpMetas);
        setFieldFocus(() => `${property}${id}`);
    }

    const Row = ({ meta }) => {
        const dispatch = useDispatch();

        const handlSalvar = async () => {
            try {
                setLoading(true);

                const metaToSave = {
                    Id: meta.Id,
                    IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                    metaFaturamento: Number(meta.MetaFaturamento.toString().replace(',', '.')),
                    metaVenda: Number(meta.MetaVenda.toString().replace(',', '.')),
                    DtAlteracao: `${meta.MetaAno}-${meta.MetaMes}-01`,
                    FlgAtivo: meta.FlgAtivo
                };

                await PUT_DATA(metaToSave.Id > 0 ? `UsuarioEmpresa/UpdateMetasByUsuarioEmpresa` : `UsuarioEmpresa/InsertMetasByUsuarioEmpresa`, metaToSave);

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Meta salva!',
                });

                setLoading(false);
                await fetchMetas();
            }
            catch (err) {
                setLoading(false);
                SalvarLogSentry(err);
            }
        }

        const handleDesativar = async () => {
            if (meta.Id > 0 && moment(meta.dataValidade) < moment()) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Cupom expirado!',
                });
                return false;
            }

            setLoading(true);
            const cupomOld = await POST_DATA(`Cupom/ValidaCupomXUsuario?idUsuario=${userLoggedIn.IdUsuario}&cupom=${meta.Descricao}`);
            cupomOld.FlgAtivo = meta.FlgAtivo;
            await POST_DATA(`Cupom/UpdateCupom`, cupomOld);
            setLoading(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: !meta.FlgAtivo ? 'Cupom ativado!' : 'Cupom desativado!',
            });

            return true;
        }

        return <Fragment key={meta.Id}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 100, maxWidth: 100 }}>
                    <NumericFormat
                        id={`MetaFaturamento${meta.Id}`}
                        autoFocus={`MetaFaturamento${meta.Id}` === fieldFocus}
                        prefix={"R$ "}
                        defaultValue={0}
                        value={meta.MetaFaturamento}
                        variant="outlined"
                        customInput={TextField}
                        decimalSeparator=","
                        onClick={() => {
                            setFieldFocus(() => `MetaFaturamento${meta.Id}`);
                        }}
                        onChange={(e) => {
                            const valor = e.target.value.replace(/\D[,]/g, "").replace('R$ ', '').replace('% ', '').trim();
                            handleChangeMeta(meta.Id, 'MetaFaturamento', valor)
                        }}
                    />
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <NumericFormat
                        id={`MetaVenda${meta.Id}`}
                        autoFocus={`MetaVenda${meta.Id}` === fieldFocus}
                        defaultValue={0}
                        value={meta.MetaVenda}
                        variant="outlined"
                        customInput={TextField}
                        decimalSeparator=","
                        onClick={() => {
                            setFieldFocus(() => `MetaVenda${meta.Id}`);
                        }}
                        onChange={(e) => {
                            const valor = e.target.value.replace(/\D[,]/g, "").trim();
                            handleChangeMeta(meta.Id, 'MetaVenda', valor)
                        }}
                    />
                </TableCell>
                <TableCell align="left" style={{ minWidth: 150, maxWidth: 150 }}>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={meta.MetaMes}
                        onChange={(e) => handleChangeMeta(meta.Id, 'MetaMes', e.target.value)}
                        MenuProps={{
                            disablePortal: true,
                            PaperProps: {
                                sx: { maxHeight: 300 },
                                style: {
                                    transform: 'scale(1)', // Ajusta o tamanho se necessário
                                },
                            },
                        }}
                    >
                        <MenuItem key={1} value={1}>Janeiro</MenuItem>
                        <MenuItem key={2} value={2}>Fevereiro</MenuItem>
                        <MenuItem key={3} value={3}>Março</MenuItem>
                        <MenuItem key={4} value={4}>Abril</MenuItem>
                        <MenuItem key={5} value={5}>Maio</MenuItem>
                        <MenuItem key={6} value={6}>Junho</MenuItem>
                        <MenuItem key={7} value={7}>Julho</MenuItem>
                        <MenuItem key={8} value={8}>Agosto</MenuItem>
                        <MenuItem key={9} value={9}>Setembro</MenuItem>
                        <MenuItem key={10} value={10}>Outubro</MenuItem>
                        <MenuItem key={11} value={11}>Novembro</MenuItem>
                        <MenuItem key={12} value={12}>Dezembro</MenuItem>
                    </Select>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 90, maxWidth: 90 }}>
                    <NumericFormat
                        id={`MetaAno${meta.Id}`}
                        autoFocus={`MetaAno${meta.Id}` === fieldFocus}
                        defaultValue={0}
                        value={meta.MetaAno}
                        variant="outlined"
                        customInput={TextField}
                        decimalSeparator=","
                        onClick={() => {
                            setFieldFocus(() => `MetaAno${meta.Id}`);
                        }}
                        onChange={(e) => {
                            const valor = e.target.value.replace(/\D[,]/g, "").trim();
                            handleChangeMeta(meta.Id, 'MetaAno', valor)
                        }}
                    />
                </TableCell>
                <TableCell align="left">
                    <Switch
                        id={`FlgAtivo${meta.Id}`}
                        disabled={meta.Habilitado}
                        readOnly={meta.Id === 0}
                        checked={meta.FlgAtivo}
                    ></Switch>
                </TableCell>
                <TableCell align="left">
                    <Button disabled={meta.Habilitado} onClick={() => handlSalvar()} variant="contained">Salvar</Button>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Metas</Typography>
                </Box>
                <Box style={{ width: '100%' }}>
                    {rows.length !== 0 && (
                        <Alert severity="info" style={{ marginTop: 10 }}>
                            Existe {rows.length} metas criadas.
                        </Alert>
                    )}
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'space-between', marginRight: 20, marginBottom: 10 }}>
                    <Typography></Typography>
                    <Button onClick={() => handleAdicionar()} variant="contained">Adicionar</Button>
                </Box>
            </Box>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando cupons</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                    </>
                )}
            </Paper>
        </Container>
    )
}

export default Metas;
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { POST_DATA, PUT_DATA } from '../../../services/API';
import { Box, Button, Card, CardHeader, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, CircularProgress } from '@mui/material';
import { Divider } from "@mui/material";
import { CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';

const Categorias = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locaion = useLocation();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [isLoadingData, setIsLoadingData] = useState(false);    

    const [categoriasSelecionadas, setCategoriasSelecioandas] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [categoriasLojas, setCategoriasLojas] = useState([]);

    const leftChecked = intersection(categoriasSelecionadas, categorias);
    const rightChecked = intersection(categoriasSelecionadas, categoriasLojas);
  
    useEffect(() => {
        if(userLoggedIn === null) return;
        
        (async() => {    
            await fetchCategorias();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn]);

    const fetchCategorias = async() => {
        setIsLoadingData(true);

        const responseCategorias = await POST_DATA(`Categoria/GetAllCategoria?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCategoria&Log=`);
        setCategorias(responseCategorias);

        const responseCategoriasLoja = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
        const arr = [];
        let categoriasFiltradas = responseCategorias;
        responseCategoriasLoja.forEach(item => {
            categoriasFiltradas = categoriasFiltradas.filter(marca => marca.IdCategoria !== item.IdCategoria);
            arr.push({
                IdCategoria: item.IdCategoria,
                IdUsuarioEmpresa: item.IdUsuarioEmpresa,
                Descricao: responseCategorias.filter(categoria => categoria.IdCategoria === item.IdCategoria)[0]?.Descricao,
                FlagAtivo: item.FlagAtivo
            });
        });

        setCategorias(categoriasFiltradas);
        setCategoriasLojas(arr);
        setIsLoadingData(false);
    }
  
    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const handleToggle = (value) => () => {
        const currentIndex = categoriasSelecionadas.indexOf(value);
        const newChecked = [...categoriasSelecionadas];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCategoriasSelecioandas(newChecked);
    };

    const numberOfChecked = (items) =>
        intersection(categoriasSelecionadas, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setCategoriasSelecioandas(not(categoriasSelecionadas, items));
        } else {
            setCategoriasSelecioandas(union(categoriasSelecionadas, items));
        }
    };

    const handleAllRight = () => {
        setCategoriasLojas(categoriasLojas.concat(categorias));
        setCategorias([]);
    };

    const handleCheckedRight = () => {
        setCategoriasLojas(categoriasLojas.concat(leftChecked));
        setCategorias(not(categorias, leftChecked));
        setCategoriasSelecioandas(not(categoriasSelecionadas, leftChecked));
    };

    const handleCheckedLeft = () => {
        setCategorias(categorias.concat(rightChecked));
        setCategoriasLojas(not(categoriasLojas, rightChecked));
        setCategoriasSelecioandas(not(categoriasSelecionadas, rightChecked));
    };

    const handleAllLeft = () => {
        setCategorias(categorias.concat(categoriasLojas));
        setCategoriasLojas([]);
    };

    const handleSave = async() => {
        setIsLoadingData(true);

        const arrEnvio = [];
        if(categoriasLojas.length > 0){
            categoriasLojas.forEach(marca => {
                arrEnvio.push({
                    IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                    IdCategoria: marca.IdCategoria,
                    FlagAtivo: true
                });
            });
            await PUT_DATA(`UsuarioEmpresaXCategoria/InsertListUsuarioEmpresaXCategoria`, arrEnvio);
        }
        else {
            await POST_DATA(`UsuarioEmpresaXCategoria/DeletarUsuarioEmpresaXCategoriaByIdUsuarioEmpresa?Id=${userLoggedIn.IdUsuarioEmpresa}&Log=`);
        }

        dispatch({
            type: CONTROLEMENSAGEM_SUCESSO,
            tipoComponente: 'alert',
            titulo: 'Sucesso',
            message: 'Categorias salvas',
        });

        setIsLoadingData(false);

        if(locaion.state?.veioTelaProduto){
            navigate('/produtos');
        }
    }

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ py: 1 }}
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
            />
            <Divider />
            <List
                sx={{
                    width: '100%',
                    minHeight: 350,
                    maxHeight: 350,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((item) => {
                    const labelId = `transfer-list-all-item-${item.IdCategoria}-label`;
                    return (
                        <ListItem
                            key={item.IdCategoria}
                            role="listitem"
                            button
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={categoriasSelecionadas.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item.Descricao} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );

    if(isLoadingData)
        return <Stack
                sx={{ height: "550px" }}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress />
                <Typography sx={{ mt: 1 }}>Carregando marcas</Typography>
            </Stack>

    return (
        <div>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Categorias</Typography>
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography></Typography>
                    <Button variant='contained' onClick={() => handleSave()}>Salvar</Button>
                </Box>
                <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={5}>{customList('Opções de categoria', categorias)}</Grid>
                    <Grid item xs={2}>
                        <Grid container direction="column" alignItems="center">
                        {
                                /** 
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                */
                            }
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="Mover para a direita"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="Mover para a equerda"
                            >
                                &lt;
                            </Button>
                            {
                                /**
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                                */
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>{customList('Categorias selecionadas', categoriasLojas)}</Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Categorias;

import { Box, Grid, Typography, CircularProgress, Button, styled, Stack } from '@mui/material';
import * as moment from "moment";
import { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import ReactSpeedometer from "react-d3-speedometer";
import { POST_DATA, SalvarLogSentry } from "../../services/API";
import { useSelector } from "react-redux";
import Card from "../../components/Card";
import { Circle, Descricao, Title } from "./styles";
import CarrinhosAbandonados from '../../assets/carrinhosAbandonados.jpeg';

import { useNavigate } from 'react-router';

import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import PeriodPicker from '../../components/PeriodPicker';

const Dashboard = () => {
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);


    const [loading, setLoading] = useState(false);

    const [carregouDadosGrafico, setCarregouDadosGrafico] = useState(false);

    const [graficoTransacoes, setGraficoTransacoes] = useState(null);

    const [valoresFaturamento, setValoresFaturamento] = useState([0, 500, 1000, 1500]);
    const [quantidadeVenda, setQuantidadeVenda] = useState([0, 500, 1000, 1500]);


    //----------------

    const [qtdTodosPedidos, setQtdTodosPedidos] = useState(0);

    const [qtdPedidosAguardandoPagamento, setQtdPedidosAguardandoPagamento] = useState(0);

    const [qtdPedidosAprovados, setQtdPedidosAprovados] = useState(0);    

    const [qtdPedidosRecebidos, setQtdPedidosRecebidos] = useState(0);
    const [valorPedidosRecebidos, setValorPedidosRecebidos] = useState(0);

    const [qtdProdutos, setQtdProdutos] = useState(0);
    const [qtdCarrinhoAbandonados, setQtdCarrinhoAbandonados] = useState(0);
    const [qtdClientes, setQtdClientes] = useState(0);
    const [qtdAviseMe, setQtdAviseMe] = useState(0);
    const [qtdNewsLetter, setQtdNewsLetter] = useState(0);


    let carregou = false;
    useEffect(() => {
        if (carregou) return;
        carregou = true;

        (async () => {
            await fetchDadosDashboard(ordenacaoDashboard?.period?.startDate, ordenacaoDashboard?.period?.endDate);
        })();

    }, [userLoggedIn, ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    useEffect(() => {
        if (!carregouDadosGrafico) return;

        setGraficoTransacoes(<Chart
            chartType="Bar"
            data={[['Transações por status', 'Paga', 'Recusada', 'Aguardando pgto'], ['Qtd', qtdPedidosRecebidos, qtdPedidosAprovados, qtdPedidosAguardandoPagamento]]}
            options={{
                title: "Transações por status",
                is3D: true,
                colors: ['#FB7A21', '#f1416d', '#bbaee5'],
                bar: { groupWidth: "90%" },
                vAxis: {
                    minValue: 0
                }
            }}
            width={"100%"}
            height={"200px"}
            loader={loading && ComponenteLoading}

        />)
    }, [carregouDadosGrafico]);

    const fetchDadosDashboard = async (dtInicio, dtFim) => {
        if (userLoggedIn === null) return;

        try {
            setCarregouDadosGrafico(false);
            setLoading(true);

            await fecthUsuarioEmpresa();

            const endpoint = `UsuarioEmpresa/DashboardContadores?IdUsuario=${userLoggedIn.IdUsuario}&IdUsuarioEmpresa=${userLoggedIn.IdUsuarioEmpresa}&DataInicio=${moment(dtInicio).format('YYYY-MM-DD')}&DataFim=${moment(dtFim).format('YYYY-MM-DD')}`;
            const dados = await POST_DATA(endpoint);

            const _qtdTodosPedidos = dados.filter(a => a.QueryType === 'fecthPedidosTodos')[0].ResultCount;

            const _qtdPedidosAprovados = dados.filter(a => a.QueryType === 'fecthPedidosAprovados')[0].ResultCount;
            const _valorPedidosAprovados = dados.filter(a => a.QueryType === 'fecthPedidosAprovados_valor')[0].ResultCount;

            const _qtdPedidosEntregues = dados.filter(a => a.QueryType === 'fecthPedidosEntregues')[0].ResultCount;
            const _valorPedidosEntregues = dados.filter(a => a.QueryType === 'fecthPedidosEntregues_valor')[0].ResultCount;

            // const _qtdPedidosRecusado = dados.filter(a => a.QueryType === 'fecthPedidosRecusado')[0].ResultCount;
            // const _valorPedidosRecusado = dados.filter(a => a.QueryType === 'fecthPedidosRecusado_valor')[0].ResultCount;

            const _qtdPedidosAguardandoPagamento = dados.filter(a => a.QueryType === 'fecthPedidosAguardandoPagamento')[0].ResultCount;

            const _qtdProdutos = dados.filter(a => a.QueryType === 'fecthProdutos')[0].ResultCount;
            const _qtdCarrinhoAbandonados = dados.filter(a => a.QueryType === 'buscarCarrinhosAbandonados')[0].ResultCount;
            const _qtdClientes = dados.filter(a => a.QueryType === 'fecthUsuarioEmpresa_periodo')[0].ResultCount;
            const _qtdAviseMe = dados.filter(a => a.QueryType === 'fecthAviseMe')[0].ResultCount;
            const _qtdNewsLetter = dados.filter(a => a.QueryType === 'fecthNewsletter')[0].ResultCount;


            setQtdPedidosAguardandoPagamento(_qtdPedidosAguardandoPagamento);
            setQtdPedidosRecebidos(_qtdPedidosAprovados + _qtdPedidosEntregues);
            setValorPedidosRecebidos(_valorPedidosAprovados + _valorPedidosEntregues);

            setQtdTodosPedidos(_qtdTodosPedidos);
            setQtdPedidosAprovados(_qtdPedidosAprovados);            

            setQtdProdutos(_qtdProdutos);
            setQtdCarrinhoAbandonados(_qtdCarrinhoAbandonados);
            setQtdClientes(_qtdClientes);
            setQtdAviseMe(_qtdAviseMe);
            setQtdNewsLetter(_qtdNewsLetter);

            setCarregouDadosGrafico(true);
            setLoading(false);
        }
        catch (err) {
            SalvarLogSentry(err);
            setLoading(false);
        }
    }


    const fecthUsuarioEmpresa = async () => {
        const usuarioEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
        const valorFaturamento = usuarioEmpresa?.metaFaturamento || 0;

        if(valorFaturamento > 0){
            setValoresFaturamento([0, Math.round(valorFaturamento * 0.3333, 2), Math.round(valorFaturamento * 0.6666, 2), valorFaturamento]);
        }

        const valorVenda = usuarioEmpresa?.metaVenda || 0;
        if(valorVenda > 0){
            setQuantidadeVenda([0, Math.round(valorVenda * 0.3333, 2), Math.round(valorVenda * 0.6666, 2), valorVenda]);
        }
    }

    const ComponenteLoading = <Stack
        sx={{ height: "150px" }}
        direction="column"
        justifyContent="center"
        alignItems="center"
    >
        <CircularProgress />
        <Typography sx={{ mt: 1 }}>Carregando dados</Typography>
    </Stack>

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Dashboard</Typography>                    
                </Box>
                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginBottom: 15 }}>
                    <PeriodPicker />
                </Box>

                <Grid container spacing={1} style={{ marginBottom: 100 }}>
                    <Grid item xs={12} md={2.4}>
                        <Card>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Title>Faturamento</Title>
                                    <Descricao>
                                        {
                                            valorPedidosRecebidos?.toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })
                                        }
                                    </Descricao>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Card>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Title>Ticket médio</Title>
                                    <Descricao>
                                        {
                                            (valorPedidosRecebidos / (qtdPedidosRecebidos > 0 ? qtdPedidosRecebidos : 1))?.toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })
                                        }
                                    </Descricao>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Card>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Title>Total Produtos</Title>
                                    <Descricao>
                                        {
                                            qtdProdutos
                                        }
                                    </Descricao>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/produtos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Card>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Title>Total Pedidos</Title>
                                    <Descricao>
                                        {qtdTodosPedidos}
                                    </Descricao>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                        <Card>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Title>Pedidos Pagos</Title>
                                    <Descricao>{qtdPedidosRecebidos}
                                    </Descricao>
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </>
                            }
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card style={{ height: 180 }}>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Title>Lista de espera</Title>
                                            <Descricao>{qtdAviseMe}
                                            </Descricao>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                                <AddCircleOutlinedIcon onClick={() => navigate('/avise-me-produtos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Title>Leads</Title>
                                            <Descricao>{qtdNewsLetter}
                                            </Descricao>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                                <AddCircleOutlinedIcon onClick={() => navigate('/newsLetter')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Card>
                        <Card style={{ height: 180, marginTop: 10 }}>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <img onClick={() => navigate('/pedidos/carrinos-abandonados')} style={{ marginTop: -60, cursor: 'pointer' }} width={150} src={CarrinhosAbandonados} />
                                    <span style={{ marginTop: -118, fontWeight: 'bold', fontSize: 28, color: 'white' }}>{qtdCarrinhoAbandonados || 0}</span>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card>
                            {
                                !carregouDadosGrafico && ComponenteLoading
                            }
                            {graficoTransacoes}
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                            </Box>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card style={{ height: 'auto' }}>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading && <>
                                    <Title>Clientes novos</Title>
                                    <Circle style={{ marginTop: 10, marginBottom: 10 }}> {qtdClientes} </Circle>
                                    <Typography style={{ color: '#757575', fontSize: '15px', fontWeight: 500 }} onClick={() => navigate('/pedidos')}><span style={{ fontSize: '12px !important', color: '#f1416d', cursor: 'pointer' }}>Ver relatório completo</span></Typography>
                                </>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            {
                                loading && ComponenteLoading
                            }
                            {
                                !loading &&
                                <Box style={{ maxHeight: 220, paddingLeft: 20, paddingRight: 20 }}>
                                    <Title>Faturamento</Title>
                                    <ReactSpeedometer
                                        value={Number((valorPedidosRecebidos / (qtdPedidosRecebidos > 0 ? qtdPedidosRecebidos : 1)).toFixed(2))}
                                        //value={500}
                                        minValue={0}
                                        customSegmentStops={[valoresFaturamento[0], valoresFaturamento[1], valoresFaturamento[2], valoresFaturamento[3]]}
                                        maxValue={valoresFaturamento[3]}
                                        segments={3}
                                        width={250}
                                        segmentColors={[
                                            "#fe481a",
                                            "#ebdb21",
                                            "#6ad72c",
                                        ]}
                                    />
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: -150, paddingRight: 20 }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </Box>
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            {
                                !loading &&
                                <Box style={{ maxHeight: 200 }}>
                                    <Title>Vendas</Title>
                                    <ReactSpeedometer
                                        value={qtdPedidosRecebidos}
                                        //value={50}
                                        minValue={0}
                                        customSegmentStops={[quantidadeVenda[0], quantidadeVenda[1], quantidadeVenda[2], quantidadeVenda[3]]}
                                        maxValue={quantidadeVenda[3]}
                                        segments={3}
                                        width={250}
                                        segmentColors={[
                                            "#fe481a",
                                            "#ebdb21",
                                            "#6ad72c",
                                        ]}
                                    />
                                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: -150, paddingRight: 20 }}>
                                        <AddCircleOutlinedIcon onClick={() => navigate('/pedidos')} style={{ fontSize: 22, cursor: 'pointer', color: '#919090' }} />
                                    </Box>
                                </Box>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
};

export default Dashboard;
import { useDispatch, useSelector } from 'react-redux';
import NavigationScroll from './layout/NavigationScroll';
import Routes from './routes';
import { useEffect } from 'react';
import { USUARIO_LOGADO_SET_DADOS } from './store/UsuarioLogadoReducer/types';
import ScrollToTop from './components/ScrollToTop';
import { getStorage } from './hooks/useStorage';
import ControleMensagem from './components/ControleMensagem';
import { Theme } from './themes';
import { SET_DADOS_ON_BOARD } from './store/OnBoard/types';
import { isObject } from 'formik';
import { SUPER_USER_SET_DADOS } from './store/SuperUser/types';
import { LEAPLOVERS_SET_AFILIADO } from './store/LeapLovers/types';
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA, SalvarLogSentry } from './services/API';
import { TEMA_LOJA_SET } from './store/TemaLojaReducer/types';
import { LEAPVESTI_SET_DADOS } from './store/LeapVesti/types';

import TagManager from 'react-gtm-module';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const onboard = useSelector((state) => state.onBoard);
    const superUser = useSelector((state) => state.superUser);
    const dispatch = useDispatch();

    //const location = useLocation();
    //useEffect(() => { console.log(`location`, location); }, [location]);

    useEffect(() => {
        try {
            let usuario = getStorage('usrin');
            let afiliado = getStorage('usrAfiliado');
            let leapVesti = getStorage('usrLeapVesti');
            let superUsrin = getStorage('superUsrin');
            if (usuario === undefined) return;

            let usuarioLogado = JSON.parse(usuario);

            if (usuarioLogado !== null) {
                dispatch({
                    type: USUARIO_LOGADO_SET_DADOS,
                    data: usuarioLogado
                });
            }

            var usrAfiliado = JSON.parse(afiliado);
            if (usrAfiliado !== null) {
                dispatch({
                    type: LEAPLOVERS_SET_AFILIADO,
                    data: usrAfiliado
                });
            }

            var usrLeapVesti = JSON.parse(leapVesti);
            if (usrLeapVesti !== null) {
                dispatch({
                    type: LEAPVESTI_SET_DADOS,
                    data: usrLeapVesti
                });
            }

            if (superUsrin !== null) {
                dispatch({
                    type: SUPER_USER_SET_DADOS,
                    data: JSON.parse(superUsrin)
                });
            }

            const storeOnBoard = getStorage('onBoard');
            if (onboard === null && storeOnBoard !== null && storeOnBoard !== undefined && storeOnBoard !== '' && !isObject(storeOnBoard)) {
                const obj = JSON.parse(storeOnBoard);
                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: obj
                });
            }

            document.title = process.env.REACT_APP_LEAP_LOVERS === 'true' ? 'Leap Lovers' : process.env.REACT_APP_LEAP_VESTI === 'true' ? 'Leap Vesti' : 'CRM CMS';

            if (process.env.REACT_APP_START_LOGIN_OPCAO == 2) { //2
                try {
                    TagManager.initialize({
                        gtmId: process.env.REACT_APP_KEY_GOOGLE_MANAGER, // Substitua pelo ID do GTM que será passado dinamicamente
                    });

                    import('react-facebook-pixel')
                        .then((x) => x.default)
                        .then((ReactPixel) => {
                            ReactPixel.init(process.env.REACT_APP_ID_PIXEL) // facebookPixelId
                            ReactPixel.pageView();
                        });
                }
                catch { }
            }

            (async () => {
                if (usuarioLogado === null) return;

                const obj = {
                    userName: process.env.REACT_APP_LOGIN_DESIGN,
                    password: process.env.REACT_APP_SENHA_DESIGN
                };

                const login = await POST_AUTH(obj);
                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${usuarioLogado.IdUsuario}`, login.token);

                if (construtorSite?.data?.id > 0) {
                    dispatch({
                        type: TEMA_LOJA_SET,
                        data: construtorSite.data.arquivo
                    });
                }

                if (superUser === null) return;

                const onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${usuarioLogado.IdUsuario}`);
                //const onboardDesignSiteResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=2&idUsuario=${usuarioLogado.IdUsuario}`);

                if (!window.location.pathname.includes('/onboard-lojista')) { // && !window.location.pathname.includes('/on-board-design-site')
                    if (onboardLojistaResult.totalPerguntaRespondida < onboardLojistaResult.totalPergunta) {
                        window.location.href = '/onboard-lojista';
                        return;
                    }
                    // else if (onboardDesignSiteResult.totalPerguntaRespondida < onboardDesignSiteResult.totalPergunta) {
                    //     window.location.href = '/on-board-design-site';
                    //     return;
                    // }

                    // if (construtorSite.data.id === 0) {
                    //     window.location.href = '/on-board-design-site';
                    //     return;
                    // }
                }

                if (onboardLojistaResult.totalPerguntaRespondida >= onboardLojistaResult.totalPergunta) { //&& onboardDesignSiteResult.totalPerguntaRespondida >= onboardDesignSiteResult.totalPergunta
                    const objStoreOnBoard = JSON.parse(storeOnBoard);
                    if (objStoreOnBoard && !window.location.pathname.includes('/on-board') && !objStoreOnBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus) {
                        window.location.href = '/on-board';
                        return;
                    }
                }
            })();
        }
        catch (err) {
            SalvarLogSentry(err, 'Iniciando dados do CRM');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Theme customization={customization}>
            <NavigationScroll>
                <ScrollToTop />
                <Routes />
                <ControleMensagem />
            </NavigationScroll>
        </Theme>
    );
};

export default App;

import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { POST_DATA, PUT_DATA, SalvarLogSentry, UploadFileAzure } from '../../../services/API';
import { Autocomplete, Avatar, Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, Modal, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ImagemSemResultado } from '../../../services/utils/functions';
import ClearIcon from '@mui/icons-material/Clear';
import * as moment from 'moment';
import { ManageSearch } from '@mui/icons-material';
import './styles.css';
import PeriodPicker from '../../../components/PeriodPicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pt } from 'date-fns/locale'
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';

const columns = [
    <TableCell>Nº do Pedido</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Cupom</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Influencer</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Data Evento</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Data de Solicitação</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Valor Total</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Data de Pagamento</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Valor Pago</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Comprovante</TableCell>,
    <TableCell style={{ fontWeight: 'bold' }} align="left">Status</TableCell>,
    <TableCell />
];

const container = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const INITIAL_STATE_FINANCEIRO = {
    IdPedidoPagamentoInfluencer: 0,
    IdPedido: 0,
    IdUsuarioLoja: 0,
    IdUsuarioInfluencer: 0,
    Observacao: '',
    DataSolicitacao: '',
    DataCadastro: '',
    DataPagamento: '',
    ValorTotal: 0,
    ValorPago: 0,
    UrlComprovante: '',

    Comprovante: null,

    InputAutocomplete: null,
    InputValueAutocomplete: null
}

const ListagemPedidosAfiliado = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usuarioLogado = useSelector((store) => store.usuarioLogado);
    const ordenacaoDashboard = useSelector((store) => store.ordenacaoDashboard);

    const [influencers, setInfluencers] = useState([]);
    const [flgPagos, setflgPagos] = useState(false);
    const [movimentoFinanceiro, setMovimentoFinanceiro] = useState(INITIAL_STATE_FINANCEIRO);

    const [loadingPedidos, setLoadingPedidos] = useState(false);
    const [statusPedido, setStatusPedido] = useState(0);
    const querySelector = useMediaQuery('(max-width:600px)');
    const [search, setSearch] = useState('');
    const [pedidos, setPedidos] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [idInfluencer, setIdInfluencer] = useState(0);
    const [inputAutocomplete, setInputAutocomplete] = useState(null);
    const [inputValueAutocomplete, setInputValueAutocomplete] = useState(null);

    useEffect(() => {
        (async () => {
            if (usuarioLogado !== null) {
                await fetchPedidos();
                await fetchInfluencers();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, usuarioLogado, flgPagos]);

    useEffect(() => {
        navigate(`/pedido-afiliado`, { replace: true, state: { statusId: statusPedido } });
    }, [ordenacaoDashboard?.period, ordenacaoDashboard?.periodToCompare]);

    useEffect(() => {
        (async() => {
            await fetchPedidos();
        })();
    }, [idInfluencer]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchInfluencers = async () => {
        const resultAfiliados = await POST_DATA(`UsuarioLoginInfluencer/GetUsuarioLoginInfluencerByIdUsuarioLoja?IdUsuarioLoja=${usuarioLogado.IdUsuario}&join=true&Log=`);
        setInfluencers([{ IdUsuarioLoginInfluencer: 0, Nome: 'Selecionar' }, ...resultAfiliados]);
    }

    const fetchPedidos = async () => {
        setLoadingPedidos(true);

        let result = [];
        if(idInfluencer > 0){
            result = await POST_DATA(`UsuarioLoginInfluencer/GetListMinhasVendasFilter?idUsuarioLoja=${usuarioLogado.IdUsuario}&dataInicio=${ordenacaoDashboard.period.startDate}&dataFim=${ordenacaoDashboard.period.endDate}&idUsuarioLoginiInfluencer=${idInfluencer}`);
        }
        else {
            result = await POST_DATA(`UsuarioLoginInfluencer/GetListMinhasVendasFilter?idUsuarioLoja=${usuarioLogado.IdUsuario}&dataInicio=${ordenacaoDashboard.period.startDate}&dataFim=${ordenacaoDashboard.period.endDate}`);
        }

        result = result.filter(a => moment(a.DataCadastro) >= moment(ordenacaoDashboard.period.startDate) && moment(a.DataCadastro) <= moment(ordenacaoDashboard.period.endDate))

        if (flgPagos) {
            result = result.filter(a => a.DataPagamento !== null);
        }

        setPedidos(result);
        setRows(result);

        setLoadingPedidos(false);
    }

    const handleSaveMovimentoFinanceiro = async () => {
        try {
            let msg = '';

            if (movimentoFinanceiro.IdUsuarioInfluencer === 0 || movimentoFinanceiro.IdUsuarioInfluencer === undefined) {
                msg += '\nFavor selecionar o influencer';
            }
            if (movimentoFinanceiro.ValorTotal === 0) {
                msg += '\nFavor informar o valor total do registro';
            }

            if (movimentoFinanceiro.DataSolicitacao === '') {
                msg += '\nFavor informar a data de solicitação';
            }

            if (movimentoFinanceiro.DataPagamento !== '' || movimentoFinanceiro.ValorPago !== 0 || movimentoFinanceiro.Comprovante !== null) {
                if (movimentoFinanceiro.DataPagamento === '') {
                    msg += '\nFavor informar a data de pagamento'
                }
                if (movimentoFinanceiro.ValorPago === 0) {
                    msg += '\nFavor informar o valor pago'
                }
            }

            if (msg.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: msg,
                });
                return;
            }

            if (movimentoFinanceiro.Comprovante !== null) {
                const pasta = `${usuarioLogado.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}`
                movimentoFinanceiro.UrlComprovante = await UploadFileAzure(movimentoFinanceiro.Comprovante, `${pasta}/influencers`, `${movimentoFinanceiro.IdPedidoPagamentoInfluencer}.${movimentoFinanceiro.Comprovante.type.split('/').pop()}`, movimentoFinanceiro.Comprovante.type);
            }

            const objeto = { ...movimentoFinanceiro, DataCadastro: moment().format('YYYY-MM-DD HH:mm:ss'), ValorTotal: Number(movimentoFinanceiro.ValorTotal.toString().replace(',', '.')), ValorPago: Number(movimentoFinanceiro.ValorPago.toString().replace(',', '.')), IdUsuarioLoja: usuarioLogado.IdUsuario };

            let result = null;
            if (movimentoFinanceiro.IdPedidoPagamentoInfluencer > 0) {
                result = await POST_DATA(`UsuarioLoginInfluencer/UpdateMinhasVendasInfluencer`, objeto);
            }
            else {
                result = await PUT_DATA(`UsuarioLoginInfluencer/InsertMinhasVendasInfluencer`, objeto);
            }

            if (result) {
                await fetchPedidos();
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Sucesso ao salvar registro',
                });
            }

            setOpenModal(false);
        }
        catch (err) {
            setOpenModal(false);
            SalvarLogSentry(err);
        }
    }


    function Row(props) {
        const { row: item } = props;

        return (
            <Fragment>
                <TableRow>
                <TableCell component="th" scope="row"> {(item.IdPedido === null || item.IdPedido === 0) ? '-' : item.IdPedido} </TableCell>
                    <TableCell component="th" scope="row"> {item.Cupom === null ? '-' : item.Cupom} </TableCell>
                    <TableCell align="right">{item._UsuarioInfluencer.Nome}</TableCell>
                    <TableCell align="left">{moment(item.DataCadastro).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="left">{moment(item.DataSolicitacao).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="left">{item.ValorTotal.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}</TableCell>
                    <TableCell align="left">{item.DataPagamento === null ? '-' : moment(item.DataPagamento).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="left">{item.ValorPago === null ? '-' : item.ValorPago.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}</TableCell>
                    <TableCell align="left">{item.DataPagamento === null ? 'Não pago' : 'Pago'}</TableCell>
                    <TableCell align="right">
                        {item.UrlComprovante === null || item.UrlComprovante.length === 0 && <span>-</span>}
                        {item.UrlComprovante !== null && item.UrlComprovante.length > 0 && <a href='#' onClick={() => window.open(`${item.UrlComprovante}`, '_blank')}>Baixar</a>}
                    </TableCell>
                    <TableCell align="left">
                        <Button variant='contained' style={{ background: 'black' }} onClick={() => {
                            const objeto = { ...item, ValorPago: item.ValorPago?.toString()?.replace('.', ','), ValorTotal: item.ValorTotal.toString().replace('.', ','), DataPagamento: item.DataPagamento === null ? '' : new Date(item.DataPagamento), DataSolicitacao: new Date(item.DataSolicitacao), Comprovante: null };
                            setMovimentoFinanceiro(objeto);
                            setOpenModal(true);
                        }}>Editar</Button>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }

    return (
        <>
            {
                openModal &&
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    keepMounted
                    disableEscapeKeyDown
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: 900,
                                height: 'auto',
                                maxHeight: 850,
                                overflowY: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <Box style={{ width: '100%' }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>Novo registro</Typography>
                        </Box>
                        <Box style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            disabled={movimentoFinanceiro.IdPedido !== 0}
                                            id={`influencer`}
                                            options={influencers}
                                            value={movimentoFinanceiro.InputAutocomplete || (movimentoFinanceiro.IdUsuarioInfluencer > 0 ? influencers.filter(a => a.IdUsuarioLoginInfluencer === movimentoFinanceiro.IdUsuarioInfluencer)[0] : influencers[0])}
                                            defaultValue={0}
                                            getOptionLabel={(option) => option?.Nome || 'Selecionar'}
                                            onChange={(event, newValue) => {
                                                if(movimentoFinanceiro.IdPedido !== 0) return;
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, IdUsuarioInfluencer: newValue?.IdUsuarioLoginInfluencer, InputAutocomplete: newValue })
                                            }}
                                            inputValue={movimentoFinanceiro.InputValueAutocomplete}
                                            onInputChange={(event, newInputValue) => {
                                                if(movimentoFinanceiro.IdPedido !== 0) return;
                                                if (newInputValue === '') setMovimentoFinanceiro({ ...movimentoFinanceiro, IdUsuarioInfluencer: 0 });
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, InputValueAutocomplete: newInputValue });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Influencer" placeholder="Influencer" />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        fullWidth
                                        disabled={movimentoFinanceiro.IdPedido !== 0}
                                        label="ValorTotal"
                                        placeholder={'Valor total'}
                                        size="small"
                                        sx={{ marginLeft: 5 }}
                                        value={movimentoFinanceiro.ValorTotal}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        onChange={(e) => {
                                            if(movimentoFinanceiro.IdPedido !== 0) return;
                                            const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim().replace(/^0+/, '');
                                            if (Number(valor) < 0) {
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, ValorTotal: 0 });
                                            }
                                            setMovimentoFinanceiro({ ...movimentoFinanceiro, ValorTotal: valor });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        fullWidth
                                        label="ValorPago"
                                        placeholder={'Valor pago'}
                                        size="small"
                                        sx={{ marginLeft: 5 }}
                                        value={movimentoFinanceiro.ValorPago}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        onChange={(e) => {
                                            const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim().replace(/^0+/, '');
                                            if (Number(valor) < 0) {
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, ValorPago: 0 });
                                            }
                                            setMovimentoFinanceiro({ ...movimentoFinanceiro, ValorPago: valor });
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                                    <FormControlLabel labelPlacement="top" control={
                                        <input
                                            type='file'
                                            id='comprovante'
                                            style={{ maxWidth: 200 }} accept='image/png, image/jpeg, application/pdf'
                                            onChange={(e) => {
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, Comprovante: e.target.files[0] });
                                            }}
                                        />
                                    }
                                        label="Comprovante"
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography>Data de solicitação</Typography>
                                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disabled={movimentoFinanceiro.IdPedido !== 0}
                                            id={`dataSolicitacao`}
                                            format="dd/MM/yyyy"
                                            value={movimentoFinanceiro.DataSolicitacao || ''}
                                            onChange={(newValue) => {
                                                if(movimentoFinanceiro.IdPedido !== 0) return;
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, DataSolicitacao: newValue });
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>Data de pagamento</Typography>
                                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            id={`dataPagamento`}
                                            format="dd/MM/yyyy"
                                            value={movimentoFinanceiro.DataPagamento || ''}
                                            onChange={(newValue) => {
                                                setMovimentoFinanceiro({ ...movimentoFinanceiro, DataPagamento: newValue });
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={3}
                                        label="Observação"
                                        placeholder={'Observação'}
                                        size="small"
                                        value={movimentoFinanceiro.Observacao}
                                        onChange={(e) => {
                                            setMovimentoFinanceiro({ ...movimentoFinanceiro, Observacao: e.target.value });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" style={{ background: 'black', marginRight: 5 }} onClick={() => setOpenModal(false)}>Fechar</Button>
                            <Button variant="contained" onClick={async () => {
                                await handleSaveMovimentoFinanceiro();
                            }}>Salvar</Button>
                        </Box>
                    </Box>
                </Modal>
            }

            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                <Typography style={{ fontWeight: 'bold' }}>Afiliados / Financeiro</Typography>
                <Button variant='contained' onClick={() => {
                    setMovimentoFinanceiro(INITIAL_STATE_FINANCEIRO);
                    setOpenModal(true);
                }}>Novo</Button>
            </Box>
            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2}>
                    <TextField
                        label="Pesquisar por cupom"
                        placeholder='Pesquisar por cupom'
                        size="small"
                        sx={{ width: "350px" }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value.trim());
                            setRows(pedidos.filter(a => a._UsuarioInfluencer?.Cupom?.toUpperCase()?.includes(e.target.value.toUpperCase())));
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <ManageSearch />
                            </InputAdornment>,
                            endAdornment: search.length > 0 && (
                                <InputAdornment
                                    style={{ cursor: 'pointer', color: 'black' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        setRows(pedidos);
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id={`influencer`}
                            options={influencers}
                            value={inputAutocomplete || influencers[0]}
                            defaultValue={0}                            
                            getOptionLabel={(option) => option?.Nome || 'Selecionar'}
                            onChange={(event, newValue) => {
                                setIdInfluencer(newValue?.IdUsuarioLoginInfluencer);
                                setInputAutocomplete(newValue);
                            }}
                            inputValue={inputValueAutocomplete}
                            onInputChange={(event, newInputValue) => {
                                if (newInputValue === '') setIdInfluencer(0);
                                setInputValueAutocomplete(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} style={{ height: 33 }} variant="outlined" label="Influencer" placeholder="Influencer" />
                            )}
                        />
                    </FormControl>
                </Stack>
                <Box style={{ display: 'flex' }}>
                    <FormControlLabel labelPlacement="top" control={
                        <Switch
                            checked={flgPagos}
                            onChange={() => {
                                setflgPagos(!flgPagos)
                            }} />}
                        label="Somente Pagos"
                    />
                    <PeriodPicker />
                </Box>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loadingPedidos ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => column)}
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ width: "100%" }}>
                                    {rows && rows.length > 0 &&
                                        rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item) => {
                                            return <Row key={item.IdPedidoPagamentoInfluencer} row={item} idPedido={item.IdPedido} />
                                        }
                                        )}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={pedidos.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={
                                search
                                    ? `${rows?.length} resultados encontrados para pesquisa "${search}" - Produtos por página: `
                                    : "Produtos por página"
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
        </>
    );
}

export default ListagemPedidosAfiliado;

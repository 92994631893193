import { Card, styled } from '@mui/material';
import { isMobile } from '../../services/DeviceServices';

export const FooterContainer = styled(Card, { shouldForwardProp: (prop) => prop !== 'opened' && prop !== 'lojaConfigurada' })`
    margin-left: ${props => !props.lojaConfigurada ? '0px' : props.opened ? '260px' : '90px' };
    width: 100%;
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    padding: 1rem;
    position: ${ isMobile() ? 'fixed' : 'relative' };
    bottom: ${ isMobile() ? 0 : '-44px' };
`;

import { Box, Button, CircularProgress, Collapse, InputAdornment, Paper, Stack, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, FormControl, Radio, FormLabel, RadioGroup, Pagination, Modal } from "@mui/material";
import { useNavigate } from "react-router";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import ClearIcon from '@mui/icons-material/Clear';
import { FormControlLabel } from "@mui/material";
import { truncate } from "../../../services/utils/functions";
import * as moment from "moment";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const columns = [
    { id: "imagem", label: "Foto", minWidth: 120 },
    { id: "nome", label: "Produto", minWidth: 120, maxWidth: 200 },
    {
        id: "quantidadeEstoque",
        label: "Qtd. Geral",
        minWidth: 170,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "valores",
        label: "Valores (R$)",
        minWidth: 170,
        align: "left"
    },
    {
        id: "dtAtualizacao",
        label: "Data Atualização",
        minWidth: 170,
        align: "left",
        format: (value) => value.toFixed(2),
    },
    {
        id: "ativo",
        label: "Ativo",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "acoes",
        label: "",
        maxWidth: 100,
        align: "left",
    },
];

const container = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const INITIAL_STATE = {
    "IdMovimentacaoEstoque": 0,
    "ITipoMovimento": 1, //1 = Entrada 2 = Ajuste
    "IdImagem": 0,
    "IdEtiqueta": 0,
    "IdEtiquetaComplemento": 0,
    "IqtdEntrada": 0,
    "IqtdAnterior": 0,
    "Observacao": "",
    "DataMovimento": null,
    "DataCadastro": null,
    "IdUsuarioCadastro": 0
};

const Estoque = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const querySelector = useMediaQuery('(max-width:600px)');
    const [produtos, setProdutos] = useState([]);

    const [rows, setRows] = useState([]);
    const [loadingProduto, setLoadingProduto] = useState(false);
    const [totalPages, setTotalPage] = useState(0);

    const [openModalEstoque, setOpenModalEstoque] = useState(false);
    const [movimentoEstoque, setMovimentoEstoque] = useState(INITIAL_STATE);

    //Filtros
    const [tipoFiltro, setTipoFiltro] = useState(1);
    const [search, setSearch] = useState('');
    const [ativos, setAtivos] = useState(true);

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        (async () => {
            await fetchProdutos();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            await fetchProdutos();
        })();
    }, [page, ativos]);

    const Row = ({ produto }) => {
        const [open, setOpen] = useState(false);
        const [etiquetasProduto, setEtiquetasProduto] = useState([]);
        const [loadingProdutosDetalhes, setLoadingProdutosDetalhes] = useState(false);
        const [textLoading, setTextLoading] = useState('Carregando detalhes');

        const fetchDetalheProduto = async (idImagem) => {
            if (open) return;

            setLoadingProdutosDetalhes(true);

            const result = await POST_DATA(`Imagem/GetImagemById?Id=${idImagem}&join=true`);
            result._Etiquetas.forEach(etiqueta => {
                etiqueta._EtiquetaComplemento.forEach(etc => {
                    etc.QuantidadeAnterior = etc.iQuantidade;
                })
            });

            setEtiquetasProduto(result._Etiquetas);
            setLoadingProdutosDetalhes(false);
        }

        const handleChangeProduto = (idEtiqueta, idEtc, quantidade) => {
            let tmp = [...etiquetasProduto];
            tmp.filter(item => item.IdEtiqueta === idEtiqueta).forEach(etiqueta => {
                etiqueta._EtiquetaComplemento.filter(item => item.IdEtiquetaComplemento === idEtc).forEach(etc => {
                    etc.iQuantidade = quantidade;
                })
            })

            setEtiquetasProduto(tmp);
        }

        const handleSaveEtiquetaComplemento = async (idEtc, quantidade) => {
            try {
                setTextLoading('Salvando produto');
                setLoadingProdutosDetalhes(true);

                const result = await PUT_DATA(`Etiqueta/UpdateQuantidadeEtiquetaComplemento?idEtiquetaComplemento=${idEtc}&iQuantidade=${quantidade}&Log=`);
                if (result) {
                    dispatch({
                        type: CONTROLEMENSAGEM_SUCESSO,
                        tipoComponente: 'alert',
                        titulo: 'Sucesso',
                        message: 'Quantidade alterada com sucesso',
                    });
                }

                setLoadingProdutosDetalhes(false);
                setTextLoading('Carregando detalhes');
            }
            catch (err) {
                SalvarLogSentry(err);
                setLoadingProdutosDetalhes(false);
                dispatch({
                    type: CONTROLEMENSAGEM_ERRO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: err.message,
                });
            }
        }

        let valores = '';
        produto?._Etiquetas.map(et => {
            valores += `${valores.length > 0 ? ';' : ''}${et.mValor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}`
        });

        return <Fragment key={produto.IdProduto}>
            <TableRow>
                <TableCell align="left">
                    <img
                        style={{ borderRadius: "8px", width: "80px", height: "120px" }}
                        src={produto.LocalIIS}
                        alt="Produto imagem"
                    />
                </TableCell>
                <TableCell align="left">
                    <Typography dangerouslySetInnerHTML={{ __html: truncate(produto?._Etiquetas[0]?.sDescricao || '-', 50) }} />
                </TableCell>
                <TableCell align="left">
                    {
                        produto._Etiquetas.map(et => {
                            return et._EtiquetaComplemento.reduce((soma, item) => { return soma += item?.iQuantidade }, 0)
                        })
                    }
                </TableCell>
                <TableCell align="left">
                    {valores}
                </TableCell>
                <TableCell align="left">
                    {produto.DtAlteracao === produto.DtInclusao
                        ? moment(produto.DtInclusao).format('DD/MM/YYYY')
                        : moment(produto.DtAlteracao).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="left">
                    {produto.FlgAtivo ? 'Sim' : 'Não'}
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                            await fetchDetalheProduto(produto.idImagem);
                            setOpen(!open);
                        }}
                    >
                        <RemoveRedEyeIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            {
                loadingProdutosDetalhes &&
                <TableRow>
                    <TableCell colSpan={6}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%' }}
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>{textLoading}</Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            }
            {
                !loadingProdutosDetalhes && open && etiquetasProduto.length === 0 &&
                <TableRow>
                    <TableCell colSpan={6}>
                        <Stack
                            sx={{ width: "100%", minHeight: "80px", p: 2 }}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography fontWeight={800} variant="h6">
                                Nenhum variação encontrada.
                            </Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            }
            {
                !loadingProdutosDetalhes && open && etiquetasProduto.length > 0 &&
                <TableRow>
                    <TableCell colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ marginTop: 1 }}>
                                {
                                    !loadingProdutosDetalhes && etiquetasProduto.map((etiqueta, i) => {
                                        return <Box key={i}>
                                            <Box style={{ display: 'flex' }}>
                                                <Typography style={{ fontWeight: 'bold' }}>{etiqueta?.sDescricao}</Typography>
                                                <Typography style={{ marginLeft: 10 }}>
                                                    <Box style={{ border: '1px solid black', background: etiqueta?.Cor, borderRadius: 10, border: '1px solid black', borderRadius: 10, width: 20, height: 20 }} ></Box>
                                                </Typography>
                                            </Box>
                                            <Table
                                                size="medium"
                                                stickyHeader
                                                aria-label="purchases"
                                                sx={{ border: "1px solid lightgray", mb: 4 }}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Tamanho</TableCell>
                                                        <TableCell>Cor</TableCell>
                                                        <TableCell>Estampa</TableCell>
                                                        <TableCell>SKU</TableCell>
                                                        <TableCell align="left">Preço</TableCell>
                                                        <TableCell align="center">Quantidade</TableCell>
                                                        <TableCell align="left">Ações</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        etiqueta._EtiquetaComplemento.map(etc => {
                                                            return <TableRow key={etiqueta.IdEtiqueta}>
                                                                <TableCell>{etc.Tamanho?.Descricao}</TableCell>
                                                                <TableCell>{etc._Cor !== null ? etc._Cor?.Descricao : 'Sem Cor'}</TableCell>
                                                                <TableCell>{etc._Estampa !== null ? etc._Estampa?.Descricao : 'Sem Estampa'}</TableCell>
                                                                <TableCell>{etc.Sku !== null ? etc.Sku : 'Sem SKU'}</TableCell>
                                                                <TableCell>{etiqueta.mValor.toLocaleString("pt-br", {
                                                                    style: "currency",
                                                                    currency: "BRL",
                                                                })}</TableCell>
                                                                <TableCell className="text-center">
                                                                    {/* <TextField
                                                                        type="number"
                                                                        style={{ textAlign: 'center' }}
                                                                        sx={{ width: "60px", alignText: "center" }}
                                                                        onChange={(e) => handleChangeProduto(etiqueta.IdEtiqueta, etc.IdEtiquetaComplemento, Number(e.target.value))}
                                                                        value={etc.iQuantidade}
                                                                    /> */}
                                                                    {etc.iQuantidade}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button onClick={() => {
                                                                        setMovimentoEstoque({ ...movimentoEstoque, IdImagem: etiqueta.idImagem, IdEtiqueta: etiqueta.IdEtiqueta, IdEtiquetaComplemento: etc.IdEtiquetaComplemento, IqtdAnterior: etc.iQuantidade })
                                                                        setOpenModalEstoque(true);
                                                                    }}
                                                                        variant="contained" style={{ marginRight: 5 }}>Alterar</Button>
                                                                    {/* <Button onClick={() => handleSaveEtiquetaComplemento(etc.IdEtiquetaComplemento, etc.iQuantidade)} disabled={etc.QuantidadeAnterior === etc.iQuantidade} variant="contained">Salvar</Button> */}
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    })
                                }
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    }

    useEffect(() => {
        if (produtos) {
            const nProdutos = produtos.map((produto) => {
                return <Row key={produto.IdProduto} produto={produto} />
            });
            setRows(nProdutos);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produtos]);

    const fetchProdutos = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoadingProduto(true);

        let filtroApi = {
            idUsuario: userLoggedIn?.IdUsuario,
            idUsuarioVendedor: userLoggedIn?.IdUsuario,
            idUsuarioComprador: 0,
            iPagina: page,
            Sexo: '0,1,2',
            FlagKids: false,
            Categoria: null,
            Peca: null,
            Tamanho: "",
            Marca: "",
            HashTags: null,
            ValorMinimo: 0,
            ValorMaximo: 0,
            FlgUsado: false,
            FlgFavoritos: false,
            order_by: '_Etiquetas.sDescricao asc',
            FlgAtivo: ativos,
            Sku: null,
            sDescricaoEtiqueta: null
        }

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        let endPoint = `Imagem/GetListImagemFilterPaginationCRM`;

        if (filtroStr.trim().length > 0 && tipoFiltro == 2)
            filtroApi = { ...filtroApi, Sku: filtroStr.trim() }
        else if (filtroStr.trim().length > 0 && tipoFiltro == 1)
            filtroApi = { ...filtroApi, sDescricaoEtiqueta: filtroStr.trim() }

        const result = await PUT_DATA(endPoint, filtroApi);
        result._Imagens.forEach((item) => {
            item.SkusEtiquetas = item._Etiquetas.map((et) => {
                return et._EtiquetaComplemento.map((etc) => {
                    return etc.Sku;
                });
            });

            item.SkusEtiquetas = item.SkusEtiquetas.join('-').replace(',', ' / ');
        });

        setTotalPage(result.iTotalPagina);
        setProdutos(result._Imagens);
        setLoadingProduto(false);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleAtualizarEstoque = async () => {
        try {
            if(movimentoEstoque.IqtdEntrada === 0){
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Qtd deve ser supior a zero.',
                });    
                return;
            }

            if(movimentoEstoque.ITipoMovimento === 2 && movimentoEstoque.IqtdEntrada > movimentoEstoque.IqtdAnterior) //Ajuste
            {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Quantidade de ajuste deve ser inferior ou igual a quantidade atual',
                }); 
                return;
            }

            const objeto = { ...movimentoEstoque, IqtdEntrada: (movimentoEstoque.ITipoMovimento === 1 ? movimentoEstoque.IqtdEntrada : -movimentoEstoque.IqtdEntrada), IdUsuarioCadastro: userLoggedIn.IdUsuario, DataCadastro: new Date(), DataMovimento: new Date() }
            const result = await PUT_DATA(`MovimentacaoEstoque/InsertMovimentacaoEstoque`, objeto);

            if(result){
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Estoque alterado com sucesso',
                });   
                setOpenModalEstoque(false);
                await fetchProdutos();
                setMovimentoEstoque(INITIAL_STATE);
            }
        }
        catch (err) {
            setOpenModalEstoque(false);
            SalvarLogSentry(err);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: err.message,
            });
        }
    }

    return (
        <>
            {
                openModalEstoque &&
                <Modal
                    open={openModalEstoque}
                    onClose={() => setOpenModalEstoque(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    keepMounted
                    disableEscapeKeyDown
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: 900,
                                height: 'auto',
                                maxHeight: 850,
                                overflowY: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <Box style={{ width: '100%' }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>Movimento de estoque</Typography>
                        </Box>
                        <Box style={{ width: '100%', display: 'flex' }}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Tipo movimento</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={movimentoEstoque.ITipoMovimento}
                                    name="radio-buttons-group"
                                    onChange={(e) => { setMovimentoEstoque({ ...movimentoEstoque, ITipoMovimento: Number(e.target.value) }) }}
                                >
                                    <FormControlLabel value={1} control={<Radio />} label="Entrada" />
                                    <FormControlLabel value={2} control={<Radio />} label="Ajuste" />
                                </RadioGroup>
                            </FormControl>
                            <Box style={{ width: "180px", display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <TextField
                                    disabled
                                    label="Qtd anterior"
                                    placeholder={'Qtd anterior'}
                                    size="small"
                                    sx={{ width: "180px" }}
                                    value={movimentoEstoque.IqtdAnterior}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                />
                                <TextField
                                    label="Qtd de itens"
                                    placeholder={'Qtd de itens'}
                                    size="small"
                                    sx={{ width: "180px", marginTop: 1 }}
                                    value={movimentoEstoque.IqtdEntrada}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onChange={(e) => {
                                        const valor = e.target.value.replace(/[^0-9:,.]/g, "").trim().replace(/^0+/, '');
                                        if (valor === '' || Number(valor) < 0) {
                                            setMovimentoEstoque({ ...movimentoEstoque, IqtdEntrada: 0 });
                                        }
                                        else {
                                            setMovimentoEstoque({ ...movimentoEstoque, IqtdEntrada: valor });
                                        }
                                    }}
                                />
                            </Box>

                            <TextField
                                multiline
                                minRows={3}
                                label="Observação"
                                placeholder={'Observação'}
                                size="small"
                                sx={{ width: 470, marginLeft: 3 }}
                                value={movimentoEstoque.Observacao}
                                onChange={(e) => {
                                    setMovimentoEstoque({ ...movimentoEstoque, Observacao: e.target.value });
                                }}
                            />
                        </Box>
                        <Box style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" style={{ background: 'black', marginRight: 5 }} onClick={() => setOpenModalEstoque(false)}>Fechar</Button>
                            <Button variant="contained" onClick={async () => {
                                await handleAtualizarEstoque();
                            }}>Salvar</Button>
                        </Box>
                    </Box>
                </Modal>
            }
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Controle de Estoque</Typography>
                </Box>
            </Box>
            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Stack direction="row" gap={2}>
                        <FormControl sx={{ marginTop: -2 }}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={1}
                                value={tipoFiltro}
                                name="radio-buttons-group"
                                onChange={(e) => { setTipoFiltro(e.target.value) }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Nome" />
                                <FormControlLabel value={2} control={<Radio />} label="Sku" />
                            </RadioGroup>
                        </FormControl>

                        <TextField
                            label="Pesquisar"
                            placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchProdutos('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ maxHeight: 43, marginLeft: 10 }}
                            onClick={() => {
                                if (page === 1)
                                    fetchProdutos();
                                else
                                    setPage(1);
                            }}
                        >
                            Pesquisar
                        </Button>
                    </Stack>

                    <FormControlLabel labelPlacement="top" control={<Switch checked={ativos} onChange={() => setAtivos(!ativos)} />} label="Ativos" />
                </Stack>
            </Stack>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loadingProduto ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando produtos</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map((row, i) => {
                                            return row;
                                        })
                                    }
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography fontWeight={800} variant="h6">
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>

                        <Box style={{ width: '100%', marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                            <Pagination
                                size="large"
                                color="primary"
                                showFirstButton
                                showLastButton
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Box>
                    </>
                )}
            </Paper>

        </>
    )
}

export default Estoque;
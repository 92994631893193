import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainLayout from '../layout/MainLayout';
import Dashboard from '../pages/Dashboard/index';
import { getStorage, removeStorage } from '../hooks/useStorage';
import { PaginaEmDev } from '../pages/PaginaEmDev';
import { ProdutoCadastro } from '../pages/Gestao/Produtos/cadastro';
import ListagemProdutos from '../pages/Gestao/Produtos';
import * as moment from 'moment';
import ListagemPedidos from '../pages/Pedidos/index';
import DetalhesPedido from '../pages/Pedidos/detalhesPedido';
import CarrinhoAbandonados from '../pages/Pedidos/carrinhosAbandonados';
import Emails from '../pages/Configuracao/Emails';
import FormasPagamento from '../pages/Configuracao/FormasPagamento';
import NotFound from '../pages/NotFound';
import FormasEntrega from '../pages/Configuracao/FormaseEntrega';
import Estoque from '../pages/Gestao/Estoque';
import Cupom from '../pages/Gestao/Cupom';
import RelatoriosClientes from '../pages/Relatorios/Clientes';
import RelatoriosVendasCliente from '../pages/Relatorios/VendasCliente';
import RelatoriosVendasProduto from '../pages/Relatorios/VendasProduto';
import RelatoriosEstoque from '../pages/Relatorios/Estoque';
import DetalhesCompra from '../pages/Gestao/ClientesVendas/detalhesCompra';
import Categorias from '../pages/Gestao/Categorias';
import Marcas from '../pages/Gestao/Marcas';
import Avaliacao from '../pages/Relatorios/Avaliacao';
import Metas from '../pages/Gestao/Metas';
import ConfigLoja from '../pages/Configuracao/Loja';
import ResumoLoja from '../pages/Indicadores/ResumoLoja';
import EstatisticaLoja from '../pages/Indicadores/EstatisticaLoja';
import ListagemClientes from '../pages/Gestao/Clientes';
import ListagemClientesVendas from '../pages/Gestao/ClientesVendas';
import EdicaoCliente from '../pages/Gestao/Clientes/editarCliente';
import AssinaturaLoja from '../pages/Configuracao/AssinaturaLoja';
import Campanha from '../pages/Marketing/Campanha';
import ConfigurarEcommerce from '../pages/eCommerce/Configuracao';
import { useSelector } from 'react-redux';
import OnBoard from '../pages/Configuracao/OnBoard';
import DominioLeap from '../pages/Marketing/DominioLeap';
import Atacado from '../pages/Atacado';
import DetalhesProduto from '../components/ProductList/DetalheProduto';
import Gestao from '../pages/Academy/Gestão';
import Financas from '../pages/Academy/Financas';
import Marketing from '../pages/Academy/Marketing';
import Empreendedorismo from '../pages/Academy/Empreendedorismo';
import AulaScreen from '../pages/Academy/Aula';
import MeusProdutosMarketplace from '../pages/Marketplace/MeusProdutos';
import DadosBancariosMarketplace from '../pages/Marketplace/DadosBancarios';
import Lojas from '../pages/Lojas';
import Promocoes from '../pages/eCommerce/Promocoes';
import Lancamentos from '../pages/eCommerce/Lancamentos';
import Destaques from '../pages/eCommerce/Destaques';
import CalendarioVenda from '../pages/CalendarioVenda';
import CaixaPDV from '../pages/PDV/Caixa';
import VendasPDV from '../pages/PDV/Vendas';
import { Notificacoes } from '../pages/Notificacoes';
import RelatorioCuponsUso from '../pages/Relatorios/Cupons';
import CadastrosGerais from '../pages/XGR/Cadastros';
import Liquidacao from '../pages/eCommerce/Liquidacao';
import Afiliados from '../pages/Afiliados';
import CadastroAfiliado from '../pages/Afiliados/cadastro';
import PedidosAtacado from '../pages/Atacado/PedidosAtacado';
import ListagemPedidosAfiliado from '../pages/Afiliados/Pedidos';
import RelatorioMovimentoEstoque from '../pages/Relatorios/MovimentoEstoque';
import SolicitacaoProdutosEsgotados from '../pages/Relatorios/SolicitacaoProdutosEsgotados';
import NewsLetter from '../pages/Relatorios/NewsLetter';
import ConstrutorEcommerce from '../pages/eCommerce/Configuracao/construtor';
import Genero from '../pages/Gestao/Genero';
import MarketingSEO from '../pages/Marketing/SEO';
import GoogleAnalytics from '../pages/Marketing/GoogleAnalytics';
import OnBoardLojista from '../pages/Configuracao/OnBoardLojista';
import Novidades from '../pages/eCommerce/Novidades';
import MaisVendidos from '../pages/eCommerce/MaisVendidos';
import Colecoes from '../pages/Gestao/Produtos/Colecoes';
import OnboardDesignSite from '../pages/Configuracao/OnboardDesignSite';
import Configuracao from '../pages/Gestao/Configuracao';
import ControleTema from '../pages/XGR/Cadastros/ControleTema';
import { isMobile } from '../services/DeviceServices';
import OnBoardMobile from '../pages/Configuracao/OnBoardMobile';
import ExplicacaoMeioPagamento from '../pages/Configuracao/FormasPagamento/explicacaoMeioPagamento';
import PageError from '../pages/PageError';
import { POST_DATA } from '../services/API';

const Private = ({ children }) => {
  const userLoggedIn = useSelector((state) => state.usuarioLogado);
  const onBoard = useSelector((state) => state.onBoard);

  const location = useLocation();

  const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';
  const ehLeapVesti = process.env.REACT_APP_LEAP_VESTI === 'true';

  const usuarioLogado = getStorage('usrin');
  if (!usuarioLogado) return <Navigate to={'/login'} />;

  const objUsuarioLogado = JSON.parse(usuarioLogado);
  const dataLogin = moment(objUsuarioLogado.Time);
  const agora = moment().add(0, 'minutos');

  if (dataLogin < agora) {
    removeStorage('usrin');
    alert('Sessão expirada!\n Favor realizar o login novamente');
    window.location.href = "/login";
    return null;
  }

  if (userLoggedIn !== null && !userLoggedIn?.FlgCodigoAcesso) {
    return <Navigate to={'/validar-codigo-ativacao'} />
  }

  if (ehLeapLovers) return <Navigate to={'/dashboard-afiliado'} />
  if (ehLeapVesti) return <Navigate to={'/pedidos-vesti'} />

  const lojaConfigurada = onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3 && a.FlagStatus === false).length > 0;
  if (lojaConfigurada && (location.pathname !== '/on-board' && location.pathname !== '/page-error' && location.pathname !== '/onboard-lojista' && location.pathname !== '/config-loja' && location.pathname !== '/login' && location.pathname !== '/configurar-ecommerce' && location.pathname !== '/on-board-design-site')) return <Navigate to={'/on-board'} />

  return children;
};

const PrivateSuperUser = ({ children }) => {
  const usuarioLogado = getStorage('superUsrin');

  if (!usuarioLogado) return <Navigate to={'/login'} />;

  return children;
};

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <Private>
          <Dashboard />
        </Private>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <Private>
          <Dashboard />
        </Private>
      ),
    },
    {
      path: '/produtos',
      element: (
        <Private>
          <ListagemProdutos />
        </Private>
      ),
    },
    {
      path: '/produtos/cadastro',
      element: (
        <Private>
          <ProdutoCadastro />
        </Private>
      ),
    },
    {
      path: '/pedidos',
      element: (
        <Private>
          <ListagemPedidos />
        </Private>
      ),
    },
    {
      path: '/pedidos/detalhes-pedidos',
      element: (
        <Private>
          <DetalhesPedido />
        </Private>
      ),
    },
    {
      path: '/pedidos/carrinos-abandonados',
      element: (
        <Private>
          <CarrinhoAbandonados />
        </Private>
      ),
    },
    {
      path: '/clientes',
      element: (
        <Private>
          <ListagemClientes />
        </Private>
      ),
    },
    {
      path: '/edicao-cliente',
      element: (
        <Private>
          <EdicaoCliente />
        </Private>
      ),
    },
    {
      path: '/clientes-vendas',
      element: (
        <Private>
          <ListagemClientesVendas />
        </Private>
      ),
    },
    {
      path: '/clientes-vendas-detalhes',
      element: (
        <Private>
          <DetalhesCompra />
        </Private>
      ),
    },
    {
      path: '/config-emails',
      element: (
        <Private>
          <Emails />
        </Private>
      ),
    },
    {
      path: '/explicacao-formas-pagamento',
      element: (
        <Private>
          <ExplicacaoMeioPagamento />
        </Private>
      ),
    },
    {
      path: '/formas-pagamento',
      element: (
        <Private>
          <FormasPagamento />
        </Private>
      ),
    },
    {
      path: '/estoque',
      element: (
        <Private>
          <Estoque />
        </Private>
      ),
    },
    {
      path: '/movimentos-estoque',
      element: (
        <Private>
          <RelatorioMovimentoEstoque />
        </Private>
      ),
    },
    {
      path: '/formas-entrega',
      element: (
        <Private>
          <FormasEntrega />
        </Private>
      ),
    },
    {
      path: '/cupons',
      element: (
        <Private>
          <Cupom />
        </Private>
      ),
    },
    {
      path: '/relatorio-clientes',
      element: (
        <Private>
          <RelatoriosClientes />
        </Private>
      ),
    },
    {
      path: '/relatorio-vendas-cliente',
      element: (
        <Private>
          <RelatoriosVendasCliente />
        </Private>
      ),
    },
    {
      path: '/relatorio-vendas-produto',
      element: (
        <Private>
          <RelatoriosVendasProduto />
        </Private>
      ),
    },
    {
      path: '/relatorio-estoque',
      element: (
        <Private>
          <RelatoriosEstoque />
        </Private>
      ),
    },
    {
      path: '/categorias',
      element: (
        <Private>
          <Categorias />
        </Private>
      ),
    },
    {
      path: '/avaliacoes',
      element: (
        <Private>
          <Avaliacao />
        </Private>
      ),
    },
    {
      path: '/metas',
      element: (
        <Private>
          <Metas />
        </Private>
      ),
    },
    {
      path: '/marcas',
      element: (
        <Private>
          <Marcas />
        </Private>
      ),
    },
    {
      path: '/config-loja',
      element: (
        <Private>
          <ConfigLoja />
        </Private>
      ),
    },
    {
      path: '/assinatura',
      element: (
        <Private>
          <AssinaturaLoja />
        </Private>
      ),
    },
    {
      path: '/indicador-resumo-loja',
      element: (
        <Private>
          <ResumoLoja />
        </Private>
      ),
    },
    {
      path: '/indicador-estatistica-loja',
      element: (
        <Private>
          <EstatisticaLoja />
        </Private>
      ),
    },
    {
      path: '/not-found',
      element: (
        <Private>
          <NotFound />
        </Private>
      ),
    },
    {
      path: '/page-error',
      element: (
        <Private>
          <PageError />
        </Private>
      ),
    },
    {
      path: '/pagina-em-dev',
      element: (
        <Private>
          <PaginaEmDev />
        </Private>
      ),
    },
    {
      path: '/campanhas',
      element: (
        <Private>
          <Campanha />
        </Private>
      ),
    },
    {
      path: '/configurar-ecommerce',
      element: (
        <Private>
          <ConfigurarEcommerce />
        </Private>
      ),
    },
    {
      path: '/configurar-ecommerce-onboard',
      element: (
        <Private>
          <ConstrutorEcommerce />
        </Private>
      ),
    },
    {
      path: '/on-board',
      element: (
        <Private>
          {isMobile() ? <OnBoardMobile /> : <OnBoard />}
        </Private>
      ),
    },
    {
      path: '/config-dominio-leap',
      element: (
        <Private>
          <DominioLeap />
        </Private>
      ),
    },
    {
      path: '/atacado',
      element: (
        <Private>
          <Atacado />
        </Private>
      ),
    },
    {
      path: '/pedidos-atacado',
      element: (
        <Private>
          <PedidosAtacado />
        </Private>
      ),
    },
    {
      path: '/detalhe-produto',
      element: (
        <Private>
          <DetalhesProduto />
        </Private>
      ),
    },
    {
      path: '/gestao',
      element: (
        <Private>
          <Gestao />
        </Private>
      ),
    },
    {
      path: '/financas',
      element: (
        <Private>
          <Financas />
        </Private>
      ),
    },
    {
      path: '/marketing',
      element: (
        <Private>
          <Marketing />
        </Private>
      ),
    },
    {
      path: '/empreendedorismo',
      element: (
        <Private>
          <Empreendedorismo />
        </Private>
      ),
    },
    {
      path: '/aula',
      element: (
        <Private>
          <AulaScreen />
        </Private>
      ),
    },

    {
      path: '/marketplace-meus-produtos',
      element: (
        <Private>
          <MeusProdutosMarketplace />
        </Private>
      ),
    },
    {
      path: '/marketplace-dados-bancarios',
      element: (
        <Private>
          <DadosBancariosMarketplace mostrarDadosAsaas={false} />
        </Private>
      ),
    },
    {
      path: '/dados-bancarios-pagamento',
      element: (
        <Private>
          <DadosBancariosMarketplace />
        </Private>
      ),
    },
    {
      path: '/lojas',
      element: (
        <PrivateSuperUser>
          <Lojas />
        </PrivateSuperUser>
      ),
    },
    {
      path: '/ecommerce-promocoes',
      element: (
        <Private>
          <Promocoes />
        </Private>
      ),
    },
    {
      path: '/ecommerce-lancamento',
      element: (
        <Private>
          <Lancamentos />
        </Private>
      ),
    },
    {
      path: '/ecommerce-liquidacao',
      element: (
        <Private>
          <Liquidacao />
        </Private>
      ),
    },
    {
      path: '/ecommerce-destaque',
      element: (
        <Private>
          <Destaques />
        </Private>
      ),
    },
    {
      path: '/calendario-vendas',
      element: (
        <Private>
          <CalendarioVenda />
        </Private>
      ),
    },

    {
      path: '/caixa-pdv',
      element: (
        <Private>
          <CaixaPDV />
        </Private>
      ),
    },
    {
      path: '/vendas-pdv',
      element: (
        <Private>
          <VendasPDV />
        </Private>
      ),
    },
    {
      path: '/notificacoes',
      element: (
        <Private>
          <Notificacoes />
        </Private>
      ),
    },
    {
      path: '/relatorio-cupons-uso',
      element: (
        <Private>
          <RelatorioCuponsUso />
        </Private>
      ),
    },
    {
      path: '/cadastros-gerais',
      element: (
        <Private>
          <CadastrosGerais />
        </Private>
      ),
    },
    {
      path: '/afiliados',
      element: (
        <Private>
          <Afiliados />
        </Private>
      ),
    },
    {
      path: '/cadastro-afiliado',
      element: (
        <Private>
          <CadastroAfiliado />
        </Private>
      ),
    },
    {
      path: '/pedido-afiliado',
      element: (
        <Private>
          <ListagemPedidosAfiliado />
        </Private>
      ),
    },
    {
      path: '/avise-me-produtos',
      element: (
        <Private>
          <SolicitacaoProdutosEsgotados />
        </Private>
      ),
    },
    {
      path: '/newsLetter',
      element: (
        <Private>
          <NewsLetter />
        </Private>
      ),
    },
    {
      path: '/generos',
      element: (
        <Private>
          <Genero />
        </Private>
      ),
    },
    {
      path: '/marketing-seo',
      element: (
        <Private>
          <MarketingSEO />
        </Private>
      ),
    },
    {
      path: '/marketing-google-analytics',
      element: (
        <Private>
          <GoogleAnalytics />
        </Private>
      ),
    },
    {
      path: '/onboard-lojista',
      element: (
        <Private>
          <OnBoardLojista />
        </Private>
      ),
    },
    {
      path: '/ecommerce-novidades',
      element: (
        <Private>
          <Novidades />
        </Private>
      ),
    },
    {
      path: '/ecommerce-mais-vendidos',
      element: (
        <Private>
          <MaisVendidos />
        </Private>
      ),
    },
    {
      path: '/produtos-colecoes',
      element: (
        <Private>
          <Colecoes />
        </Private>
      ),
    },
    {
      path: '/on-board-design-site',
      element: (
        <Private>
          <OnboardDesignSite />
        </Private>
      ),
    },
    {
      path: '/configurar-categorias',
      element: (
        <Private>
          <Configuracao />
        </Private>
      ),
    },
    {
      path: '/controle-temas',
      element: (
        <Private>
          <ControleTema />
        </Private>
      ),
    },
  ],
};

Private.propTypes = {
  children: PropTypes.node,
};

export default MainRoutes;

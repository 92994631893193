import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { POST_DATA, PUT_DATA } from '../../../services/API';
import { Box, Button, CircularProgress, Dialog, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { ImagemSemResultado, formatCurrency, formatDateSimples, salvarCSV } from '../../../services/utils/functions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StarRateIcon from '@mui/icons-material/StarRate';
import { CONTROLEMENSAGEM_AVISO } from '../../../store/ControleMensagemReducer/types';
import { Container } from './styles';
import ModalWhats from '../../../components/ModalWhats';
import ModalEmail from '../../../components/ModalEmail';
import { POPOVER_OPEN } from '../../../store/PopoverReducer/types';
import PeriodPicker from '../../../components/PeriodPicker';
import * as moment from 'moment';

const columns = [
    { id: "dataAvaliacao", label: "Data da avaliação", minWidth: 150, maxWidth: 200 },
    { id: "notaAvaliacao", label: "Nota da avaliação", minWidth: 150, maxWidth: 170 },
    { id: "descricaoAvaliacao", label: "Descrição da avaliação", minWidth: 150, maxWidth: 170 },
    { id: "nomeCliente", label: "Nome do cliente", minWidth: 150, maxWidth: 170 },
    { id: "telefone", label: "Telefone", minWidth: 120, maxWidth: 170 },
    { id: "email", label: "E-mail", minWidth: 120, maxWidth: 170 },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 200,
        align: "right",
    },
];

const Avaliacao = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    
    const [loading, setLoading] = useState(false);
    const [avaliacoes, setAvaliacoes] = useState([]);
    const [filtroEstrelas, setFiltroEstrelas] = useState(0);
    const [anchorEl, setaAchorEl] = useState(null);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            await fetchAvaliacoes();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroEstrelas, userLoggedIn, orderList?.period, orderList?.periodToCompare]);

    useEffect(() => {
        const tmpAvaliacoes = avaliacoes?.map(avaliacao => {
            return <Row avaliacao={avaliacao} />
        });

        setRows(tmpAvaliacoes || []);
    }, [avaliacoes]);

    const fetchAvaliacoes = async () => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoading(true);

        const nota = filtroEstrelas > 0 ? filtroEstrelas : null;
        const result = await PUT_DATA(`Pedido/GetAvaliacaoUsuarioVendedorFiltro?idUsuario=${userLoggedIn.IdUsuario}&dataInicio=${orderList.period.startDate}&dataFim=${orderList.period.endDate}&nota=${nota}`);
        setAvaliacoes(result.objetoRetorno);
        
        setLoading(false);
    }

    const fetchExcel = async () => {
        if (avaliacoes === null || avaliacoes.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Data da avaliação; Nota da avaliação; Descrição avaliação; Nome do cliente; Telefone;  Email`;
        avaliacoes?.forEach(avaliacao => {
            dados += `\n${avaliacao.dataAvaliacao}; ${avaliacao.Nota}; ${avaliacao.Descricao}; ${avaliacao?._Pedido?._UsuarioComprador?.Nome}; ${avaliacao?._Pedido?._UsuarioComprador?.Celular}; ${avaliacao?._Pedido?._UsuarioComprador?.Email}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'Avaliações.csv', dados);
        window.open(url, '_blank');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleFiltrar = (nota) => {
        const result = avaliacoes.filter(item => item.notaAvaliacao === nota);
        const tmpAvaliacoes = nota > 0 ? result?.map(avaliacao => <Row avaliacao={avaliacao} />) : avaliacoes?.map(avaliacao => <Row avaliacao={avaliacao} />);

        setRows(tmpAvaliacoes);
        setFiltroEstrelas(nota);
    }

    const Row = ({ avaliacao }) => {
        const [notas, setNotas] = useState([]);

        useEffect(() => {
            let estrlas = [];
            for (let index = 0; index < avaliacao.Nota; index++) {
                estrlas.push(<StarRateIcon key={index} style={{ fill: '#facc00' }} />)
            }

            setNotas(estrlas);
        }, []);

        return <Fragment key={avaliacao.IdAvaliacaoPedido}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {moment(avaliacao?.DataCadastro).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {
                        notas.map(nota => nota)
                    }
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {avaliacao.Descricao}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {avaliacao?._Pedido?._UsuarioComprador?.Nome ? avaliacao?._Pedido?._UsuarioComprador?.Nome : 'Não identificado' }
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {avaliacao?._Pedido?._UsuarioComprador?.Email ? avaliacao?._Pedido?._UsuarioComprador?.Email : '-' }
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {avaliacao?._Pedido?._UsuarioComprador?.Celular ? avaliacao?._Pedido?._UsuarioComprador?.Celular : '-'}
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                    <Button
                        size="small"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmailComAnexo',
                                    idItem: 'poperModalEmailComAnexo',
                                    open: true,
                                    data: {
                                        nomePessoa: avaliacao?._Pedido?._UsuarioComprador?.Nome,
                                        emailCliente: avaliacao?._Pedido?._UsuarioComprador?.Email
                                    }
                                }); 
                            }, 200);
                        }} 
                    >
                        <LocalOfferIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalWhats',
                                    idItem: 'poperModalWhats',
                                    open: true,
                                    data: {
                                        nomePessoa: avaliacao?._Pedido?._UsuarioComprador?.Nome,
                                        numeroWhatsapp: avaliacao?._Pedido?._UsuarioComprador?.Celular
                                    }
                                }); 
                            }, 200);
                        }}
                    >
                        <WhatsAppIcon style={{ fontSize: 16 }} />
                    </Button>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    return (
        <Container>
            <ModalWhats anchorEl={anchorEl} />
            <ModalEmail idPopover='poperModalEmailComAnexo' anchorEl={anchorEl} possuiAnexo={true} />

            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Relatórios /Avaliações</Typography>
                </Box>
                <Box style={{ width: "100%", marginTop: 10, display: 'flex' }}>
                    <FormControl fullWidth>
                        <InputLabel id="tamanho-selector">Nota</InputLabel>
                        <Select
                            style={{ width: 150 }}
                            labelId="demo-simple-select-label"
                            label="Notas"
                            id="demo-simple-select"
                            value={filtroEstrelas}
                            onChange={(e) => handleFiltrar(Number(e.target.value))}
                            MenuProps={{
                                disablePortal: true,
                                PaperProps: {
                                    style: {
                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                    },
                                },
                            }}
                        >
                            <MenuItem key={0} value={0}>Todos</MenuItem>
                            <MenuItem key={1} value={1}>1</MenuItem>
                            <MenuItem key={2} value={2}>2</MenuItem>
                            <MenuItem key={3} value={3}>3</MenuItem>
                            <MenuItem key={4} value={4}>4</MenuItem>
                            <MenuItem key={5} value={5}>5</MenuItem>
                        </Select>
                    </FormControl>
                    <Box style={{ minWidth: 250, display: 'flex', justifyContent: 'flex-end' }}>
                        <PeriodPicker />
                    </Box>
                </Box>
            </Box>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando avaliações</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={avaliacoes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"Avaliações por página"}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    );
}

export default Avaliacao;

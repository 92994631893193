import P from 'prop-types';
import { useEffect, useState } from 'react';
import { Container, StyleValueCart, StyledMenu } from './styles';
import { Box, CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth } from '../../../store/Customization/types';
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { getStorage, removeStorage } from '../../../hooks/useStorage';

import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import { GET_DATA, GET_DATA_DESIGN, POST_AUTH, POST_DATA, PUT_DATA, SalvarLogSentry } from '../../../services/API';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NotificacoesModal from '../../../pages/Notificacoes/NotificacoesModal';
import { NOTIFICACOES_OPEN } from '../../../store/NotificacoesModalReducer/types';
import * as moment from 'moment';
import { isMobile } from '../../../services/DeviceServices';

import GoWhatsApp from '../../../components/GoWhatsApp';
import { TEMA_LOJA_SET } from '../../../store/TemaLojaReducer/types';
import { SET_DADOS_ON_BOARD } from '../../../store/OnBoard/types';
import axios from 'axios';
import LoadingAllPage from '../../../components/LoadingAllPage';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const customization = useSelector((state) => state.customization);
    const notificacoesModal = useSelector((state) => state.notificacoesModal);
    const superUser = useSelector((state) => state.superUser);
    const usuarioLogado = useSelector((state) => state.usuarioLogado);
    const leapLovers = useSelector((state) => state.leapLovers);
    const leapVesti = useSelector((state) => state.leapVesti);
    const onBoard = useSelector((state) => state.onBoard);
    const [dominio, setDominio] = useState({
        IdDominioLEAP: 0,
        IdUsuarioEmpresa: 0,
        IdProvedor: 0,
        IpServidor: '',
        NomeSiteLeap: '',
        NomeSiteDominioGeral: '',
        DataCriacao: '',
        DataAtualizacao: null,
        FlagAtivo: true
    });

    const [urlLoja, setUrlLoja] = useState('');

    const [loadingDominio, setLoadingDominio] = useState(false);

    const [notificacoes, setNotificacoes] = useState([]);
    const [quantidadeNotificacoes, setQuantidadeNotificacoes] = useState(0);
    const [passouMousePorCima, setPassouMousePorCima] = useState(false);
    const [descricaoUserLogado, setDescricaoUserLogado] = useState('');

    const [mostrarItensHeader, setMostrarItensHeader] = useState(true);

    const [mostrarBotaoLiberarVendas, setMostrarBotaoLiberarVendas] = useState(false);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        removeStorage('usrin');
        removeStorage('onBoard');
        removeStorage('superUsrin');
        removeStorage('usrLeapVesti');
        removeStorage('usrLeapLovers');
        navigate('/login');

        dispatch({
            type: TEMA_LOJA_SET,
            data: null
        });

        dispatch({
            type: SET_DADOS_ON_BOARD,
            data: null
        });
    }

    const getDominios = async () => {
        try {
            const result = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${usuarioLogado?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
            if (result !== null && result.length > 0) {
                setDominio(result[0]);
            }
        }
        catch (err) {
            SalvarLogSentry(err, 'Header getDominios');
        }
    }

    let intervalId = null;
    useEffect(() => {
        (async () => {
            if (usuarioLogado === null) return;

            await getDominios();
            await fetchNotificacoes();

            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };

            const login = await POST_AUTH(obj);
            const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${usuarioLogado.IdUsuario}`, login.token);

            if (construtorSite.data.id > 0) {
                const temasResult = await GET_DATA(`Tema/GetTemaById?id=${construtorSite.data.arquivo.tipoThema}`);

                const codigo = construtorSite.data.urlSite.split('=');
                const url = `${temasResult?.UrlTema}?xyz=${codigo[1]}`;

                setUrlLoja(url);
            }

            const itensFinalizados = onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => (a.OnboardingStoreItem.IdOnboardingStoreItem === 1 || a.OnboardingStoreItem.IdOnboardingStoreItem === 2 || a.OnboardingStoreItem.IdOnboardingStoreItem === 3 || a.OnboardingStoreItem.IdOnboardingStoreItem === 5) && a.FlagStatus).length === 4;
            setMostrarBotaoLiberarVendas(itensFinalizados);

            intervalId = setInterval(async () => {
                await fetchNotificacoes();
            }, 1000 * 60)
        })();

        setMostrarItensHeader(!(location.pathname.replace('/', '') === 'onboard-lojista') && !(location.pathname.replace('/', '') === 'lojas') && !(location.pathname.replace('/', '') === 'on-board-design-site') && onBoard && onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0]?.FlagStatus);

        if (leapLovers !== null)
            setDescricaoUserLogado(`${leapLovers.afiliado.Nome}`);
        else
            setDescricaoUserLogado(`${superUser !== null ? superUser?.Email : usuarioLogado?.NomeFantasia} ${superUser !== null && usuarioLogado?.NomeFantasia ? `- ${usuarioLogado?.NomeFantasia}` : ''}`);

        return () => {
            clearInterval(intervalId);
        }
    }, [usuarioLogado, leapLovers, location]);

    const fetchNotificacoes = async () => {
        try {
            if (usuarioLogado === null) return;

            const dataInicio = moment().add(-30, 'days').format('YYYY-MM-DD 00:00:00');
            const dataFim = moment().format('YYYY-MM-DD 23:59:59');

            const url = `Notificacao/GetAllNotificacaoIdUsuarioLoja?idUsuarioLoja=${usuarioLogado.IdUsuario}&dataInicio=${dataInicio}&dataFim=${dataFim}&palavraChave=pedido&maxInstances=20`;

            const notificacoes = await POST_DATA(url);
            setQuantidadeNotificacoes(notificacoes.filter(a => !a.FlagVisualizacao).length || 0);
            setNotificacoes(notificacoes);
        }
        catch {}
    }

    return (
        <Container>
            {
                notificacoesModal.open && <NotificacoesModal />
            }
            {
                loadingDominio && <LoadingAllPage text='Visualizando prévia do seu site...' />
            }
            <CssBaseline />
            <Box style={{ marginLeft: isMobile() ? 20 : customization.opened ? drawerWidth - 240 : (mostrarItensHeader ? 120 : 20), width: '100%', minWidth: 200, maxWidth: isMobile() ? 200 : null, display: 'flex', justifyContent: 'start' }}>
                {
                    !leapVesti && !(location.pathname.replace('/', '') === 'on-board') &&
                    <Typography style={{ fontWeight: 'bold', fontSize: isMobile() ? 10 : null }}>({descricaoUserLogado}) - {leapLovers !== null ? leapLovers.afiliado.Loja.Nome : 'Gestão Loja'} {superUser !== null ? <Button onClick={() => navigate('/lojas')}>Mudar loja</Button> : null}</Typography>
                }
                {
                    leapVesti &&
                    <Typography style={{ fontWeight: 'bold', fontSize: isMobile() ? 12 : null }}>Vesti Gestão de Pedidos</Typography>
                }
            </Box>
            <Box style={{ width: 900, marginRight: 5, cursor: 'pointer', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <span
                    onMouseMove={() => setPassouMousePorCima(true)}
                    onMouseLeave={() => setPassouMousePorCima(false)}
                >
                    <GoWhatsApp whatsNumber='11976548907' fixoLocal={true} passouMousePorCima={passouMousePorCima} />
                </span>

                {
                    leapLovers === null && leapVesti === null && !isMobile() &&
                    <>
                        {
                            mostrarItensHeader &&
                            <>
                                {
                                    mostrarBotaoLiberarVendas &&
                                    <Button variant='contained' style={{ background: 'black', fontSize: 13, marginRight: 10, width: 220 }} onClick={async () => {
                                        const itensPreenchidos = onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(item => (item.OnboardingStoreItem.iOrdem === 4 || item.OnboardingStoreItem.iOrdem === 6) && item.FlagStatus).length === 2;;

                                        if (!itensPreenchidos) {
                                            dispatch({
                                                type: CONTROLEMENSAGEM_AVISO,
                                                tipoComponente: 'alert',
                                                titulo: 'Aviso',
                                                message: 'Favor finalizar as pendências (Meio de Pagamento e Comunicação) da loja',
                                            });

                                            navigate(`/on-board`);

                                            return;
                                        }

                                        await PUT_DATA(`UsuarioEmpresa/UpdateFlgLiberadaLojaOnline?IdUsuarioEmpresa=${usuarioLogado.IdUsuarioEmpresa}&FlgLiberadaLojaOnline=${!usuarioLogado.FlgLiberadaLojaOnline}`);

                                        dispatch({
                                            type: CONTROLEMENSAGEM_SUCESSO,
                                            tipoComponente: 'alert',
                                            titulo: 'Sucesso',
                                            message: usuarioLogado.FlgLiberadaLojaOnline ? 'Loja desativada com sucesso' : 'Loja liberada com sucesso',
                                        });
                                    }}>
                                        {!usuarioLogado.FlgLiberadaLojaOnline ? "Liberar Vendas Online" : "Desativar Vendas Online"}
                                    </Button>
                                }
                                {/* <Button
                                    onClick={() => window.open(`https://api.whatsapp.com/send/?phone=11976548907&text=Olá, preciso de ajuda&type=phone_number&app_absent=0`, '_blank')}
                                    onMouseMove={() => setPassouMousePorCima(true)}
                                    onMouseLeave={() => setPassouMousePorCima(false)}
                                    variant='contained' style={{ background: passouMousePorCima ? '#f0f0f0' : 'white', color: '#f1416d', fontWeight: 'bold', marginRight: 15 }}>
                                    <QuestionMarkIcon style={{ fontSize: 20, marginRight: 5 }} />
                                    Ajuda
                                </Button> */}
                            </>
                        }

                        {
                            (!onBoard?.FlagCompletado && mostrarItensHeader) &&
                            <span title='Pendências' >
                                <Box style={{ marginRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box style={{ display: 'flex' }}>
                                        <WarningAmberIcon
                                            onClick={() => navigate('/on-board')}
                                            style={{ fontSize: 30, color: '#757575' }}
                                        />
                                        <StyleValueCart>
                                            {onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.FlagStatus === false).length || 0}
                                        </StyleValueCart>
                                    </Box>
                                </Box>
                            </span>
                        }

                        {
                            mostrarItensHeader &&
                            <>
                                <span
                                    title='Ver prévia da sua loja'
                                    onClick={async () => {
                                        try {
                                            setLoadingDominio(true);

                                            const tempo = getStorage('tmp_reg');
                                            if (tempo !== null) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    tipoComponente: 'alert',
                                                    titulo: 'Aviso',
                                                    message: 'Domínio em processo de registro, ainda não disponível.',
                                                });
                                                return;
                                            }

                                            let responseDominio = null;
                                            try {
                                                let dataDominioConsulta = JSON.stringify({
                                                    "domain": dominio.NomeSiteLeap,
                                                    "nome_domain": dominio.NomeSiteLeap.split('.')[0]
                                                });

                                                let config = {
                                                    method: 'post',
                                                    maxBodyLength: Infinity,
                                                    url: `${process.env.REACT_APP_API_SERVICOS_ROBO_FRONTDOOR}getDeploy`,
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    data: dataDominioConsulta
                                                };

                                                responseDominio = await axios.request(config);
                                            }
                                            catch { }

                                            if (responseDominio !== null && responseDominio.data?.dataAzure?.data?.properties?.deploymentStatus === 'Succeeded') {
                                                window.open(`https://${dominio.NomeSiteLeap}`, '_blank');
                                            }
                                            else {
                                                const obj = {
                                                    userName: process.env.REACT_APP_LOGIN_DESIGN,
                                                    password: process.env.REACT_APP_SENHA_DESIGN
                                                };

                                                const login = await POST_AUTH(obj);
                                                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${usuarioLogado.IdUsuario}`, login.token);

                                                let temasResult = await GET_DATA(`Tema/GetTemaById?id=${construtorSite.data.arquivo.tipoThema}`);

                                                if (temasResult === null || temasResult.IdTema === 1 || !temasResult.UrlTema) { //Shopleap não pode
                                                    temasResult = await GET_DATA(`Tema/GetTemaById?id=5`); //5 lollypop
                                                }

                                                const codigo = `${moment(usuarioLogado.DataCadastroEmpresa).format('YYYYMMDDHHmmss')}${usuarioLogado.IdUsuario}`;
                                                const url = `${temasResult?.UrlTema}?xyz=${codigo}`;

                                                window.open(url, '_blank');
                                            }
                                        } catch (error) {
                                            dispatch({
                                                type: CONTROLEMENSAGEM_AVISO,
                                                tipoComponente: 'alert',
                                                titulo: 'Aviso',
                                                message: 'Domínio não registrado',
                                            });
                                        }
                                        finally {
                                            setLoadingDominio(false);
                                        }
                                    }}
                                >
                                    <LanguageIcon
                                        style={{ fontSize: 30, marginRight: 20, color: '#757575' }}
                                    />
                                </span>
                                <Box style={{ marginRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box
                                        style={{ display: 'flex' }}
                                        onClick={() => dispatch({ type: NOTIFICACOES_OPEN })}
                                    >
                                        <NotificationsIcon style={{ fontSize: 30, color: '#757575' }} />
                                        {
                                            quantidadeNotificacoes > 0 &&
                                            <StyleValueCart>
                                                {quantidadeNotificacoes}
                                            </StyleValueCart>
                                        }
                                    </Box>
                                </Box>
                                <CalendarMonthIcon onClick={() => navigate('/calendario-vendas')} style={{ fontSize: 30, marginRight: 20, color: '#757575' }} />
                                {/* <HelpOutlineIcon style={{ fontSize: 30, marginRight: 20, color: '#757575' }} /> */}
                                <AccountCircleIcon style={{ fontSize: 30, marginRight: 20, color: '#757575' }} onClick={handleClick} />
                            </>
                        }


                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            style={{ padding: 20 }}
                        >
                            <MenuItem>
                                <Typography style={{ marginRight: 10, fontWeight: 'bold', textAlign: 'end', color: '#757575' }}>{usuarioLogado?.Email}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                navigate('/assinatura');
                                handleClose();
                            }}>
                                Assinatura
                            </MenuItem>
                            <MenuItem onClick={() => {
                                navigate('/config-loja');
                                handleClose();
                            }}>
                                Configurações
                            </MenuItem>
                            <MenuItem onClick={() => handleLogout()} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <Divider style={{ width: '100%', marginBottom: 10 }} />
                                <span>
                                    <LogoutIcon style={{ fontSize: 25, color: '#757575', marginRight: 5 }} />
                                    Sair da loja
                                </span>
                            </MenuItem>
                        </Menu>
                    </>
                }

                {
                    isMobile() &&
                    <LogoutIcon onClick={() => handleLogout()} style={{ fontSize: 25, color: '#757575', marginRight: 5 }} />
                }
                {
                    !mostrarItensHeader && !isMobile() &&
                    <LogoutIcon onClick={() => handleLogout()} style={{ fontSize: 25, color: '#757575', marginRight: 5 }} />
                }
            </Box>
        </Container>
    );
}

Header.propTypes = {
    handleLeftDrawerToggle: P.func
};

export default Header;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SettingsTwoTone, Visibility, VisibilityOff } from "@mui/icons-material";
import { POST_DATA, PUT_DATA } from '../../../services/API';
import { useDispatch, useSelector } from 'react-redux';
import { CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import { atualizarOnboardStore } from "../../../services/utils/functions";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useNavigate } from "react-router";

const MOCK_PAYMENT_OPTIONS_DATA = [
  {
    logo: "https://www.axisfocus.com/arquivos/global/imagens/geral/logistica_melhor_envio_logo.png",
    nome: "Melhor Envio",
    ativo: false,
  }
];

export default function OpcoesFormasEntrega() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const onBoard = useSelector((store) => store.onBoard);
  const userLoggedIn = useSelector((state) => state.usuarioLogado);
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const dispatch = useDispatch();
  const idTipoIntegracao = 2; //Formas de entrega

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    if (!userLoggedIn?.IdUsuario) return;

    (async () => {
      const result = await POST_DATA(`Integracao/GetAllIntegracao?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdIntegracao`);
      const options = result.filter((integracao) => integracao.IdIntegracaoTipo === idTipoIntegracao);
      setOptionsList(options);
      await fetchIntegracaoAtual(options);
    })();

  }, [userLoggedIn]);

  const fetchIntegracaoAtual = async (options) => {
    const tmpOptions = [...options]
    const result = await POST_DATA(`IntegracaoParametros/GetAllIntegracaoParametrosByIdIntegracaoTipoAndIdUsuario?IdIntegracaoTipo=${idTipoIntegracao}&IdUsuario=${userLoggedIn?.IdUsuario}`);
    tmpOptions.forEach(item => {
      const option = result.filter(a => a.IdIntegracao === item.IdIntegracao)[0];
      if (option) {
        item.IntegracaoParametros = option;
      }
      else {
        item.IntegracaoParametros = {
          IdUsuario: userLoggedIn.IdUsuario,
          IdIntegracao: item.IdIntegracao,
          Email: '',
          Token: '',
          FlagAtivo: false
        };
      }
    });
    setOptionsList(tmpOptions);
  };

  const handleSetOptions = (idIntegracaoTipo, idx, field, value) => {
    const config = optionsList.filter(item => item.IdIntegracaoTipo === idIntegracaoTipo)[idx];
    if (config) {
      const arr = [...optionsList];
      arr[idx].IntegracaoParametros = { ...config.IntegracaoParametros, [field]: value };
      setOptionsList(arr);
    }
  }

  const handleSalvar = async (id) => {
    const option = optionsList.filter(item => item.IdIntegracao === id)[0];
    const objCadastroFinal = {
      ...option.IntegracaoParametros,
      IdIntegracaoParametros: option.IntegracaoParametros?.IdIntegracaoParametros ? option.IntegracaoParametros.IdIntegracaoParametros : 0,
      IdIntegracao: id,
      IdUsuario: userLoggedIn.IdUsuario
    };

    await PUT_DATA("IntegracaoParametros/CadastroIntegracaoParametros", objCadastroFinal);
    dispatch({
      type: CONTROLEMENSAGEM_SUCESSO,
      tipoComponente: 'alert',
      titulo: 'Sucesso',
      message: 'Informações salvas!',
    });

    if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 7)[0].FlagStatus) { //Configure frete e entrega
      const objOnboard = { ...onBoard };
      objOnboard.IdUltimoOnboardingStoreItem = 7;

      const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 7)[0];
      itemOnboard.FlagStatus = true;

      atualizarOnboardStore(objOnboard, itemOnboard);
      dispatch({
        type: SET_DADOS_ON_BOARD,
        data: objOnboard
      });

      navigate('/on-board');
    }
  }

  return (
    <Stack sx={{ mt: 2 }}>
      {optionsList &&
        optionsList?.map((option, i) => {
          return option.IntegracaoParametros && (
            <Accordion
              key={i}
              expanded={expanded === `panel-${i}`}
              onChange={handleChange(`panel-${i}`)}
            >
              <AccordionSummary
                expandIcon={<SettingsTwoTone />}
                aria-controls={`panel-${i}-content`}
                id={`panel-${i}-header`}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={2}
                  width={"100%"}
                  sx={{ padding: "0 1rem" }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <img
                      alt='Logo'
                      src={option.logo ? option.logo : MOCK_PAYMENT_OPTIONS_DATA?.filter(item => item.nome === option.Nome)[0]?.logo}
                      style={{ width: "80px", height: "80px" }}
                    />
                    <Typography sx={{ color: "text.secondary", marginLeft: 10, fontWeight: 'bold' }}>
                      {option.Nome}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Switch
                      color="success"
                      checked={option.IntegracaoParametros.FlagAtivo}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSetOptions(option.IdIntegracaoTipo, i, 'FlagAtivo', !option?.IntegracaoParametros?.FlagAtivo);
                      }}
                    />
                    <Typography
                      sx={{ color: option?.IntegracaoParametros?.FlagAtivo ? "green" : "gray" }}
                    >
                      {option?.IntegracaoParametros?.FlagAtivo ? "LIGADO" : "DESLIGADO"}
                    </Typography>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" gap={1}>
                  <Alert severity="info">
                    <AlertTitle>Configurações</AlertTitle>
                    Configure o envio de e-mail com a plataforma{" "}
                    <strong>{option.Nome}</strong> seguindo esses passos:
                    <ol style={{ marginTop: "1rem" }}>
                      <li>
                        1. Crie uma conta na plataforma da{" "}
                        <strong>{option.Nome}</strong>.
                      </li>
                      <li>
                        2. Com a conta criada, informe <strong>E-mail</strong> e{" "}
                        <strong>chave de integração</strong> nos campos abaixo.
                      </li>
                    </ol>
                  </Alert>
                  <Stack direction="row" gap={1} width={"100%"}>
                    <TextField
                      label="E-mail"
                      variant="outlined"
                      type="email"
                      sx={{ flex: 1 }}
                      value={option.IntegracaoParametros.Email}
                      onChange={(e) => {
                        handleSetOptions(option.IdIntegracaoTipo, i, 'Email', e.target.value.trim());
                      }}
                    ></TextField>
                    <TextField
                      type={mostrarSenha ? "text" : "password"}
                      label="Chave de integração (token)"
                      variant="outlined"
                      sx={{ flex: 2 }}
                      value={option.IntegracaoParametros.Token}
                      onChange={(e) => {
                        handleSetOptions(option.IdIntegracaoTipo, i, 'Token', e.target.value.trim());
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setMostrarSenha(!mostrarSenha)}
                            edge="end"
                          >
                            {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }}
                    />
                    <Button variant="contained" onClick={() => handleSalvar(option.IdIntegracao)}>Salvar</Button>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Stack>
  );
}

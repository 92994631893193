export const convertUrlToImage = async (fileName, url) => {
  return await fetch(url)
    .then(async response => {
      const arr = url.split('.');
      const type = arr[arr.length - 1];
      const blob = await response.blob()
      const file = new File([blob], `${fileName}.${type}`, { type: `image/${type}` });
      return file;
    })
}

export const base64ToBlob = (base64, mimeType = 'image/jpeg') => {
  const byteString = atob(base64.split(',')[1]); // Decodifica a base64
  const arrayBuffer = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeType });
};

export const base64ToFile = (base64, fileName, mimeType = 'image/jpeg') => {
  const byteString = atob(base64.split(',')[1]);
  const arrayBuffer = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], `${fileName}.${mimeType.split('/')[1]}`, {
    type: mimeType,
  });
};
import { Box, Card, FormControl, Grid, MenuItem, TextField, Typography, Select, InputLabel, FormControlLabel, Switch, Button, Modal, DialogTitle, FormLabel, RadioGroup, Radio, Divider, InputAdornment } from "@mui/material";
import { Container } from "./styles";
import { useEffect, useRef, useState } from "react";
import { GET_DATA, GET_DATA_DESIGN, handleDownload, handleTrack, POST_AUTH, POST_DATA, POST_DATA_DESING, POST_DATA_FORM_DESING, POST_DATA_SERVICOS_ROBO, PUT_DATA, salvarArquivoDominios, SalvarLogSentry } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import * as moment from "moment";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useNavigate } from "react-router";
import { atualizarOnboardStore, validarDominio } from "../../../services/utils/functions";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import imagemGerandoSite from '../../../assets/imagemGerandoSite.png';
import axios from "axios";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";
import Countdown, { zeroPad } from "react-countdown";
import { getStorage, removeStorage, setStorage } from "../../../hooks/useStorage";
import LoadingAllPage from "../../../components/LoadingAllPage";
import Loading from "../../../layout/Loader";


const DominioLeap = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLogeedIn = useSelector((store) => store.usuarioLogado);
    const onBoard = useSelector((store) => store.onBoard);
    const [provedores, setProvedores] = useState([]);
    const [openModalLojaLiberacao, setOpenModalLojaLiberacao] = useState(false);
    const [dominio, setDominio] = useState({
        IdDominioLEAP: 0,
        IdUsuarioEmpresa: 0,
        IdProvedor: 16,
        IpServidor: '20.51.255.252',
        NomeSiteLeap: '',
        NomeSiteDominioGeral: '',
        DataCriacao: '',
        DataAtualizacao: null,
        FlagAtivo: true
    });

    const [tokenDesign, setTokenDesign] = useState(null);

    const [possuiDominioProprio, setPossuiDominioProprio] = useState("naoPossuiDominio");
    const [dataHoraRegistro, setDataHoraRegistro] = useState(null);

    const [possuiDominioRegistradoAzure, setPossuiDominioRegistradoAzure] = useState(true);
    const [dominioAzure, setDominioAzure] = useState(null);
    const refTimerDominioAzure = useRef(null);

    const [getConfirmationDominio, ConfirmationDominio] = useDialogConfirmationAsync();
    const [loadingSalvando, setLoadingSalvando] = useState(false);

    const [loadingDominio, setLoadingDominio] = useState(false);

    const [urlLojaPrevia, setUrlLojaPrevia] = useState('');

    const [dominioTmp, setDominioTmp] = useState(null);

    const [mensagemAtraso, setMensagemAtraso] = useState(null);

    const container = {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (onBoard !== null && !onBoard?.ListOnboardingStorexOnboardingStoreItem?.filter(a => a.IdOnboardingStoreItem === 3)[0]?.FlagStatus) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Tema da loja não configurado',
                message: `Para realizar o registro do domínio é necessário que se tenha configurado o desing da loja`
            });

            navigate(`/on-board`, { replace: true });
            return;
        }

        if (userLogeedIn === null) return;

        (async () => {
            try {
                await handleTrack(userLogeedIn.IdUsuario, 'DominioLeap', `Acessou a tela de configuração de domínio`);

                await getProvedores();
                await getDominios();

                const obj = {
                    userName: process.env.REACT_APP_LOGIN_DESIGN,
                    password: process.env.REACT_APP_SENHA_DESIGN
                };
                const login = await POST_AUTH(obj);
                setTokenDesign(login.token);

                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLogeedIn.IdUsuario}`, login.token);
                if (construtorSite.data.id > 0) {
                    const temasResult = await GET_DATA(`Tema/GetTemaById?id=${construtorSite.data.arquivo.tipoThema}`);
                    const codigo = construtorSite.data.urlSite.split('=');
                    const url = `${temasResult?.UrlTema}?xyz=${codigo[1]}`;

                    setUrlLojaPrevia(url);
                }

                const tempo = getStorage('tmp_reg');
                setDominioTmp(tempo);
                if (tempo !== null && tempo !== '') {
                    setDataHoraRegistro(JSON.parse(tempo));
                }
                else {
                    setDataHoraRegistro(Date.now());
                }
            }
            catch (err) {
                SalvarLogSentry(err, 'Domínio Leap');
            }
        })();
    }, [userLogeedIn]);

    useEffect(() => {
        const tempo = getStorage('tmp_reg');
        setDominioTmp(tempo);
        if (tempo !== null) {
            refTimerDominioAzure.current = setInterval(() => getDominios(), 30000);

            return () => {
                if (refTimerDominioAzure.current) {
                    clearInterval(refTimerDominioAzure.current);
                }
            };
        }
    }, [possuiDominioRegistradoAzure]);

    const getProvedores = async () => {
        const itens = await POST_DATA(`Provedor/GetAllProvedor?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdProvedor&Log=`);
        setProvedores(itens);
    }

    const getDominios = async () => {
        try {
            setLoadingDominio(true);

            const result = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${userLogeedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
            if (result !== null && result.length > 0) {
                result[0].IpServidor = '20.51.255.252';

                const dominioLeap = result[0];
                setDominio(dominioLeap);

                let dataDominioConsulta = JSON.stringify({
                    "domain": dominioLeap.NomeSiteLeap,
                    "nome_domain": dominioLeap.NomeSiteLeap.split('.')[0]
                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_SERVICOS_ROBO_FRONTDOOR}getDeploy`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: dataDominioConsulta
                };

                const responseDominio = await axios.request(config);
                if (responseDominio.data.success && responseDominio.data.data.length > 0) {
                    setDominioAzure(responseDominio.data);
                    setPossuiDominioRegistradoAzure(true);
                }
                else {
                    setPossuiDominioRegistradoAzure(false);
                }
            }

        } catch (error) {
            setLoadingDominio(false);
            setPossuiDominioRegistradoAzure(false);
            SalvarLogSentry(error, 'Domínio Leap');
        }
        finally {
            setLoadingDominio(false);
        }
    }

    const handleSalvar = async () => {
        try {
            let todosCamposPreenchidos = true;

            const dominioStr = dominio.NomeSiteLeap.trim().replaceAll('.leapstyle.me', '').replaceAll('leapstyle.me', '').replaceAll('.leapstyle.com.br', '').replaceAll('leapstyle.com.br', '').replaceAll('https://', '').replaceAll('http://', '').replaceAll('www.', '');

            if (dominioStr.length < 4) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'O domínio deve ter no mínimo 4 caracteres',
                });
                return;
            }

            if (dominio.IdDominioLEAP === 0 && possuiDominioProprio === 'naoPossuiDominio') {
                dominio.NomeSiteLeap = `${dominioStr}.leapstyle.com.br`;
                dominio.IdProvedor = 16; //Sem provedor
            }

            if (dominio.IdProvedor === 16) { //Sem provedor
                dominio.NomeSiteLeap = `${dominioStr}.leapstyle.com.br`;
            }

            if (!validarDominio(dominio.NomeSiteLeap)) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar um domínio válido para sua loja.\nSó é permitido um domínio com letras e números sem espaço.',
                });
                return;
            }

            setLoadingSalvando(true);

            dominio.NomeSiteLeap = dominio.NomeSiteLeap.toLowerCase().trim();
            dominio.NomeSiteDominioGeral = dominio.NomeSiteLeap;
            dominio.IdUsuarioEmpresa = userLogeedIn.IdUsuarioEmpresa;

            if (possuiDominioRegistradoAzure) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Domínio já registrado, não é possível realizar alterações, favor contatar o suporte',
                });

                return;
            }

            if ((possuiDominioProprio === 'possuiDominio' && dominio.IdProvedor === 0) || dominio.NomeSiteLeap.trim().length === 0 || dominio.NomeSiteDominioGeral.trim().length === 0)
                todosCamposPreenchidos = false;

            if (!todosCamposPreenchidos) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor preencher todos os campos',
                });
                return;
            }

            await salvarArquivoDominios(userLogeedIn, dominio);

            const dominios = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${dominio.NomeSiteLeap}&ColunaParaValorExato=NomeSiteLeap&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP`);
            if (dominios.filter(a => a.IdUsuarioEmpresa !== userLogeedIn.IdUsuarioEmpresa).length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Domínio já utilizado por outra loja',
                });
                setLoadingSalvando(false);
                return;
            }

            const objSave = { ...dominio };
            objSave.IdUsuarioEmpresa = userLogeedIn.IdUsuarioEmpresa;
            objSave.NomeSiteLeap = objSave.NomeSiteLeap.trim();
            objSave.NomeSiteDominioGeral = objSave.NomeSiteDominioGeral.trim();

            if (objSave.IdDominioLEAP === 0) {
                objSave.DataCriacao = moment().format("YYYY-MM-DD HH:mm:ss");
            }
            objSave.DataAtualizacao = moment().format("YYYY-MM-DD HH:mm:ss");

            await PUT_DATA(`DominioLEAP/CadastroDominioLEAP`, objSave);

            await getDominios();

            if (!possuiDominioRegistradoAzure) {
                getConfirmationDominio();
            }

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sucesso ao salvar o domínio',
            });

            setLoadingSalvando(false);

        } catch (err) {

            setLoadingSalvando(false);
            SalvarLogSentry(err, 'Erro ao salvar os dados da empresa');

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err,
            });
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') return false;
        if (reason === 'escapeKeyDown') return false;

        setOpenModalLojaLiberacao(false);

        if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 5)[0].FlagStatus) { //Liberar loja
            const objOnboard = { ...onBoard };
            objOnboard.IdUltimoOnboardingStoreItem = 5;

            const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 5)[0];
            itemOnboard.FlagStatus = true;

            atualizarOnboardStore(objOnboard, itemOnboard);

            dispatch({
                type: SET_DADOS_ON_BOARD,
                data: objOnboard
            });

            navigate('/on-board');
        }
    };

    const handleValidarDominioLeap = async () => {
        try {
            if (dominio.NomeSiteLeap.length < 4) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar um domínio válido',
                });
                return;
            }

            let dominioVerificacao = dominio.NomeSiteLeap;
            if (dominioVerificacao === 0 || dominio.IdProvedor === 16) { //Sem provedor
                dominioVerificacao = dominioVerificacao.replaceAll('.leapstyle.com.br', '');
                dominioVerificacao = `${dominioVerificacao}.leapstyle.com.br`;
            }

            if (!validarDominio(dominioVerificacao)) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar um domínio válido para sua loja.\nSó é permitido um domínio com letras e números sem espaço.',
                });
                return;
            }

            const dominios = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${dominioVerificacao}&ColunaParaValorExato=NomeSiteLeap&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP`);
            if (dominios.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Domínio já está em uso por outra loja',
                });
            }
            else {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Domínio disponível para uso',
                });
            }
        }
        catch (err) {
            SalvarLogSentry(err, 'DominioLeap');
        }
    }

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 15,
                            top: 35,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const getDomainValidationState = (status) => {
        switch (status) {
            case 'Approved':
                return <span style={{ color: '#256d08' }}>Aprovado</span>;
            case 'Creating':
                return <span style={{ color: '#88219c' }}>Criado</span>;
            case 'Submitting':
                return <span style={{ color: '#88219c' }}>Submetido</span>;
            case 'InternalError':
                return <span style={{ color: '#b33c3c' }}>Erro interno</span>;
            case 'Deleting':
                return <span style={{ color: '#b33c3c' }}>Deletado</span>;
            case 'Rejected':
                return <span style={{ color: '#b33c3c' }}>Rejeitado</span>;
            case 'TimedOut':
                return <span style={{ color: '#b33c3c' }}>Tempo esgotado</span>;
            case 'Unknown':
                return <span style={{ color: '#b33c3c' }}>Desconhecido</span>;
            case 'Pending':
                return <span style={{ color: '#e0a21d' }}>Pendente</span>;
            case 'PendingRevalidation':
                return <span style={{ color: '#e0a21d' }}>Pendente reavaliação</span>;
            case 'RefreshingValidationToken':
                return <span style={{ color: '#e0a21d' }}>Recarregando validação de token</span>;
            case 'Succeeded':
                return <span style={{ color: '#256d08' }}>Sucesso</span>;
            default:
                return status;
        }
    }

    const getAfdProvisioningState = (status) => {
        switch (status) {
            case 'Creating':
                return <span style={{ color: '#88219c' }}>Criado</span>;
            case 'Deleting':
                return <span style={{ color: '#b33c3c' }}>Deletado</span>;
            case 'Failed':
                return <span style={{ color: '#b33c3c' }}>Falha</span>;
            case 'Updating':
                return <span style={{ color: '#e0a21d' }}>Atualizando</span>;
            case 'Succeeded':
                return <span style={{ color: '#256d08' }}>Sucesso</span>;
            default:
                return status;
        }
    }

    const getDeploymentStatus = (status) => {
        switch (status) {
            case 'Failed':
                return <span style={{ color: '#b33c3c' }}>Falha</span>;
            case 'InProgress':
                return <span style={{ color: '#e0a21d' }}>Em progresso</span>;
            case 'NotStarted':
                return <span style={{ color: '#1c2aaa' }}>Não iniciado</span>;
            case 'Succeeded':
                return <span style={{ color: '#256d08' }}>Sucesso</span>;
            default:
                return status;
        }
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) { //|| dominioAzure?.dataAzure?.data?.properties?.deploymentStatus === 'Succeeded'          
            // Render a complete state
            return <Card style={{ width: '100%', padding: 15, border: '0.5px solid #dcd4d4' }}>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}><span style={{ fontWeight: 'bold' }}>Estado provisório do domínio:</span> {getAfdProvisioningState(dominioAzure?.dataAzure?.data?.properties?.provisioningState)}</Typography>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}><span style={{ fontWeight: 'bold' }}>Validação do domínio:</span> {getDomainValidationState(dominioAzure?.dataAzure?.data?.properties?.domainValidationState)}</Typography>
                <Typography style={{ fontSize: 18, fontWeight: 600 }}><span style={{ fontWeight: 'bold' }}>Domínio publicado:</span> {getDeploymentStatus(dominioAzure?.dataAzure?.data?.properties?.deploymentStatus)}</Typography>

                {
                    dominioAzure?.dataAzure?.data?.properties?.deploymentStatus === 'Succeeded' &&
                    <Button variant="contained" style={{ background: 'black', marginTop: 15 }} onClick={() => {
                        window.open('https://' + dominio.NomeSiteLeap, '_blank');
                    }}>Visualizar o site</Button>
                }
            </Card>
        } else {
            // Render a countdown
            return (
                <Card style={{ width: '100%', padding: 15, border: '0.5px solid #dcd4d4' }}>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}><span style={{ fontWeight: 'bold' }}>Estado provisório do domínio:</span> {getAfdProvisioningState(dominioAzure?.dataAzure?.data?.properties?.provisioningState)}</Typography>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}><span style={{ fontWeight: 'bold' }}>Validação do domínio:</span> {getDomainValidationState(dominioAzure?.dataAzure?.data?.properties?.domainValidationState)}</Typography>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}><span style={{ fontWeight: 'bold' }}>Domínio publicado:</span> {getDeploymentStatus(dominioAzure?.dataAzure?.data?.properties?.deploymentStatus)}</Typography>

                    {
                        mensagemAtraso &&
                        <Typography variant="h5" className="timer" style={{ color: 'red', fontWeight: 'bold', marginTop: 15, marginBottom: -15 }}>
                            {mensagemAtraso}
                        </Typography>
                    }
                    <Typography variant="h5" className="timer" style={{ color: 'red', fontWeight: 'bold', marginTop: 15 }}>
                        Em {zeroPad(minutes)}:{zeroPad(seconds)} minutos seu site estará disponível (liberado para uso).
                    </Typography>
                </Card>
            );
        }
    };

    try {
        return (
            <Container>
                {
                    loadingSalvando && <LoadingAllPage text="Salvando domínio..." />
                }
                <ConfirmationDominio
                    title="Registrar domínio?"
                    mensagem={`Ao prosseguir será registrado o domínio ${dominio.NomeSiteLeap}, não será possível mais editar, para fazer alguma mudança será necessário entrar em contato com o suporte, deseja prosseguir?`}
                    btn1={{ title: "Cancelar", onClick: null }}
                    btn2={{
                        title: "Registrar",
                        onClick: async () => {
                            let dataCriacao = JSON.stringify({
                                "domain": dominio.NomeSiteLeap,
                                "nome_domain": dominio.NomeSiteLeap.split('.')[0],
                                "created": false,
                                "idDomain": "",
                                "dominioAzureStatus": "",
                                "associadoEndPoint": false,
                                "flagIgnoraRobo": false,
                                "associadoRoutes": false,
                                "txtValidationName": "",
                                "txtValidationValue": "",
                                "cnameValidationName": "",
                                "cnameValidationValue": "",
                                "dnsStatusValidation": false
                            });

                            let config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: `${process.env.REACT_APP_API_SERVICOS_ROBO_FRONTDOOR}CreateDeploy`,
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                data: dataCriacao
                            };

                            axios.request(config)
                                .then(async (response) => {
                                    if (response.data.success) {
                                        const tempo = Date.now() + 900000;

                                        setDataHoraRegistro(tempo);
                                        setDominioTmp(tempo);
                                        setStorage(`tmp_reg`, JSON.stringify(tempo));
                                        setPossuiDominioRegistradoAzure(true);
                                        setOpenModalLojaLiberacao(true);

                                        dispatch({
                                            type: CONTROLEMENSAGEM_SUCESSO,
                                            tipoComponente: 'alert',
                                            titulo: 'Domínio Registrado com sucesso',
                                            message: `Em 15 minutos sua loja estará respondendo pelo domínio ${dominio.NomeSiteLeap} registrado.`
                                        });

                                    }

                                })
                                .catch((error) => {
                                    SalvarLogSentry(error);
                                });
                        }
                    }}
                />

                {
                    openModalLojaLiberacao &&
                    <Modal
                        key="modal"
                        open={openModalLojaLiberacao}
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '0%'
                        }}
                        keepMounted
                        disableEscapeKeyDown
                        disableAutoFocus
                    >
                        <Box
                            sx={[
                                container,
                                {
                                    width: '650px',
                                    height: 'auto',
                                    borderRadius: 2,
                                    backgroundColor: 'white',
                                },
                            ]}
                        >
                            <BootstrapDialogTitle id="componenteMensagem" onClose={handleClose}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>Loja em processo de liberação</Typography>
                                <Typography style={{ fontWeight: 'bold', fontSize: 18, color: '#dbdbdb', marginTop: 20 }}>O seu domínio oficial está em processo de liberação</Typography>
                            </BootstrapDialogTitle>
                            <img src={imagemGerandoSite} style={{ width: 580 }} />
                        </Box>
                    </Modal>
                }

                <Box sx={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Validação do nome da loja e Domínio</Typography>
                </Box>

                <Card style={{ width: '100%', minHeight: 500, height: 'auto', marginTop: 10, padding: 20, borderRadius: 20 }}>
                    {
                        dominio.IdDominioLEAP === 0 &&
                        <Grid item xs={12} style={{ marginBottom: 20, marginTop: 10 }}>
                            <Typography><span style={{ fontWeight: 'bold' }}>1) Você possui domínio próprio?</span> Exemplo, seunome.com.br</Typography>

                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={possuiDominioProprio}
                                    name="radio-buttons-group"
                                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                                    onChange={(e) => {
                                        setPossuiDominioProprio(e.target.value)
                                    }}
                                >
                                    <FormControlLabel value="possuiDominio" control={<Radio />} label="Possuí" />
                                    <FormControlLabel value="naoPossuiDominio" control={<Radio />} label="Não possuí" />
                                </RadioGroup>
                            </FormControl>

                        </Grid>
                    }

                    <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={12}>
                            <Divider style={{ width: '100%' }} />
                        </Grid>

                        {
                            !possuiDominioRegistradoAzure &&
                            <Grid item xs={12}>
                                <Typography><span style={{ fontWeight: 'bold' }}>2) Digite abaixo o nome que deseja para o seu site</span> <span style={{ color: '#c5c5c5' }}>(neste momento ainda é possível mudar esse nome)</span></Typography>
                            </Grid>
                        }

                        <Grid item xs={dominioAzure ? 12 : 6} style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                fullWidth
                                disabled={possuiDominioRegistradoAzure}
                                type="text"
                                style={{ minWidth: 300, maxWidth: 600 }}
                                InputLabelProps={{
                                    style: {
                                        color: 'black',
                                        fontSize: '14px'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: 'black',
                                        fontSize: '14px'
                                    },
                                    endAdornment: ((possuiDominioProprio === "naoPossuiDominio" || dominio.IdProvedor === 16) && dominio.IdDominioLEAP === 0) && <InputAdornment>
                                        <Typography style={{ color: '#c2c2c2' }}>+ .leapstyle.com.br</Typography>
                                    </InputAdornment>
                                }}
                                label={dominio.IdDominioLEAP > 0 ? "Domínio" : (possuiDominioProprio === "possuiDominio" ? "Seu domínio" : "")}
                                placeholder={dominio.IdDominioLEAP > 0 ? "Domínio" : (possuiDominioProprio === "possuiDominio" ? "Seu domínio" : "")}
                                inputProps={{ maxLength: 255 }}
                                value={dominio.NomeSiteLeap}
                                onChange={(e) => {
                                    setDominio({ ...dominio, NomeSiteLeap: e.target.value })
                                }}
                            />
                            {
                                dominioTmp !== null &&
                                <Button variant="contained" style={{ background: 'black', marginLeft: 10, minWidth: 160 }} onClick={() => {
                                    window.open(urlLojaPrevia, '_blank');
                                }}>
                                    Ver prévia
                                </Button>
                            }
                            {
                                (!possuiDominioRegistradoAzure && dominioTmp === null) &&
                                <Button variant="contained" onClick={() => handleValidarDominioLeap()} style={{ marginRight: 10, marginLeft: 10, background: 'black', fontSize: 11, minWidth: 150 }}>Verificar disponibilidade</Button>
                            }

                        </Grid>
                        {
                            possuiDominioRegistradoAzure &&
                            <Grid item xs={12} style={{ marginTop: -10 }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>Se você quer mudar o nome do domínio que já registrou, é só clicar no botão verde acima e pedir ajuda ao suporte técnico.</Typography>
                            </Grid>
                        }

                        {
                            (possuiDominioProprio === "possuiDominio" || (dominio.IdProvedor > 0 && dominio.IdProvedor !== 16)) && //16 = Sem provedor
                            <>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="provedor">Provedor</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="provedor"
                                            id="provedor"
                                            value={dominio.IdProvedor}
                                            label="Provedor"
                                            style={{ height: 50 }}
                                            onChange={(e) => setDominio({ ...dominio, IdProvedor: Number(e.target.value) })}
                                            MenuProps={{
                                                disablePortal: true,
                                                PaperProps: {
                                                    style: {
                                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem key={0} value={0}>Nenhum(a)</MenuItem>
                                            {
                                                provedores.map(provedor => {
                                                    return <MenuItem key={provedor.IdProvedor} value={provedor.IdProvedor}>{provedor.Nome}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        type="text"
                                        InputLabelProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        label="ip do servidor LeapStyle"
                                        placeholder="ip do servidor LeapStyle"
                                        inputProps={{ maxLength: 15 }}
                                        value={dominio.IpServidor}
                                    />
                                </Grid>
                            </>
                        }

                        {
                            !possuiDominioRegistradoAzure &&
                            <Grid item xs={12}>
                                <Divider style={{ width: '100%', marginBottom: 15 }} />
                                <Typography><span style={{ fontWeight: 'bold' }}>3) Se voce já achou o nome perfeito para seu site, agora basta registrar.</span></Typography>
                                <Typography style={{ color: '#c5c5c5' }}>(Após Registrar esse nome não podera ser alterado em até 24 horas)</Typography>
                            </Grid>
                        }


                        {/* <Grid item xs={1.5}>
                            <FormControlLabel labelPlacement="top" control={
                                <Switch
                                    checked={dominio.FlagAtivo}
                                    onChange={() => {
                                        setDominio({ ...dominio, FlagAtivo: !dominio.FlagAtivo });
                                    }} />}
                                label="Ativo"
                            />
                        </Grid> */}



                        {/* <Grid item xs={9}>
                            <TextField
                                fullWidth
                                type="text"
                                InputLabelProps={{
                                    style: {
                                        color: 'black',
                                        fontSize: '14px'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: 'black',
                                        fontSize: '14px'
                                    }
                                }}
                                label="Nome Site Domínio Geral"
                                placeholder="Nome Site Domínio Geral"
                                inputProps={{ maxLength: 255 }}
                                value={dominio.NomeSiteDominioGeral}
                                onChange={(e) => {
                                    setDominio({ ...dominio, NomeSiteDominioGeral: e.target.value })
                                }}
                            />
                        </Grid> */}

                        {
                            (!possuiDominioRegistradoAzure && dominioTmp === null) &&
                            <Grid item xs={5}>
                                <Button variant="contained" onClick={() => handleSalvar()}>Registrar</Button>
                            </Grid>
                        }

                    </Grid>

                    {
                        loadingDominio &&
                        <Loading texto="Buscando dados do domínio..." marginTop={'5%'} />
                    }

                    {
                        (!loadingDominio && possuiDominioRegistradoAzure && dataHoraRegistro !== null) &&
                        <Box style={{ marginTop: 15 }}>
                            <Countdown date={dataHoraRegistro} renderer={renderer} onComplete={async () => {

                                try {
                                    await fetch(`https://${dominio.NomeSiteLeap}`, {
                                        method: "GET",
                                        mode: "no-cors", // Para evitar erros CORS. Isso funciona para apenas saber se o site está online.
                                    });

                                    setMensagemAtraso(null);
                                    const tempo = getStorage('tmp_reg');


                                    if (tempo !== null && tempo !== '') {
                                        removeStorage('tmp_reg');
                                        setDominioTmp(null);

                                        dispatch({
                                            type: CONTROLEMENSAGEM_SUCESSO,
                                            tipoComponente: 'alert',
                                            titulo: 'Aviso',
                                            message: 'Domínio registrado com sucesso',
                                        });

                                        window.open(`https://${dominio.NomeSiteLeap}`, '_blank');
                                    }
                                } catch {
                                    setPossuiDominioRegistradoAzure(false);

                                    if (dominio.NomeSiteLeap.length > 0) {

                                        const tempo = Date.now() + 300000;

                                        setDataHoraRegistro(tempo);
                                        setStorage(`tmp_reg`, JSON.stringify(tempo));
                                        setDominioTmp(tempo);

                                        setMensagemAtraso('Precisamos de mais tempo, por causa da alta demanda.');
                                    }
                                }
                            }} />
                        </Box>
                    }

                </Card>
            </Container >
        )
    }
    catch (err) {
        SalvarLogSentry(err, 'Erro ao executar tela de domínio');

        navigate('/page-error', { state: { message: '1756 - Oi, houve um erro na operação que você solicitou. Tente novamente ou fale com o suporte abaixo.' } });
    }
}

export default DominioLeap;
import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)`
  padding-right: 50px;
  padding-bottom: 150px;
`;

export const ImgIcon = styled(Box, { shouldForwardProp: (prop) => prop !== 'imgSrc' })`
  width: 80px;
  height: 50px;
  margin-right: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ imgSrc }) => `${imgSrc}`});
`;

export const AlertError = styled(Typography)`
  padding-top: 10px;
  color: #e15126;
  font-weight: bold;
`;

import { Fragment, useEffect, useState } from "react";
import { Paper, useMediaQuery, Box, Stack, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, CircularProgress, InputAdornment, Button, TextField, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Pagination, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Container, Descricao, Title } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ImagemSemResultado, formatDateSimples, salvarCSV } from "../../../services/utils/functions";
import Card from "../../../components/Card";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import { POST_DATA, PUT_DATA } from "../../../services/API";
import { getStyleDestaqueSemaforo } from "../../../services/utils/statusPedido";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";
import PeriodPicker from "../../../components/PeriodPicker";
import * as moment from "moment";

const columns = [
    { id: "tipoMovimento", label: "Tipo de movimento", minWidth: 170, maxWidth: 270 },
    { id: "sku", label: "Sku", minWidth: 170, maxWidth: 270 },
    { id: "produto", label: "Produto", minWidth: 170, maxWidth: 270 },
    { id: "tamanho", label: "Tamanho", minWidth: 170, maxWidth: 270 },
    { id: "cor", label: "Cor", minWidth: 170, maxWidth: 270 },
    { id: "estampa", label: "Estampa", minWidth: 170, maxWidth: 270 },
    { id: "dataAlteracao", label: "Data Alteração", minWidth: 120, maxWidth: 170 },
    { id: "quantidadeAnterior", label: "Qtd. anterior", minWidth: 120, maxWidth: 170 },
    { id: "quantidade", label: "Qtd. Entrada", minWidth: 120, maxWidth: 170 }
];

const RelatorioMovimentoEstoque = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);
    const querySelector = useMediaQuery('(max-width:600px)');

    const [tipoFiltro, setTipoFiltro] = useState(1);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [movimentoEstoque, setMovimentoEstoque] = useState([]);

    const [tipoMovimento, setTipoMovimento] = useState(0);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        (async () => {
            await fetchEstoque();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await fetchEstoque();
        })();
    }, [orderList?.period, page, tipoMovimento]);

    useEffect(() => {
        const tempProdutos = movimentoEstoque?.map(movimento => {
            return <Row movimento={movimento} />
        });

        setRows(tempProdutos || []);
    }, [movimentoEstoque]);

    const fetchEstoque = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        if (orderList?.period === undefined || orderList?.period === null) return;

        setLoading(true);

        const movimentos = await POST_DATA(`MovimentacaoEstoque/GetMovimentacaoEstoqueByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}&dataInicio=${orderList.period.startDate}&dataFim=${orderList.period.endDate}&iTipoMovimento=${tipoMovimento}`);

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        for (const movimento of movimentos) {
            if(filtroStr.length > 0){
                if(tipoFiltro == 2) //sku
                {
                    movimento._Imagem._Etiquetas = movimento._Imagem._Etiquetas.filter(a => a.IdEtiqueta === movimento.IdEtiqueta).map(et => {
                        et._EtiquetaComplemento = et._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === movimento.IdEtiquetaComplemento && a.Sku.includes(filtroStr.trim())).map(etc => {                    
                            return [etc];
                        })[0];
                        return et;
                    });
                }
                else //Descrição
                {
                    movimento._Imagem._Etiquetas = movimento._Imagem._Etiquetas.filter(a => a.IdEtiqueta === movimento.IdEtiqueta && a.sDescricao.toUpperCase().trim().includes(filtroStr.toUpperCase().trim())).map(et => {                        
                        et._EtiquetaComplemento = et._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === movimento.IdEtiquetaComplemento).map(etc => {                    
                            return[etc];
                        })[0];
                        return et;
                    });
                }
            }
            else {
                movimento._Imagem._Etiquetas = movimento._Imagem._Etiquetas.filter(a => a.IdEtiqueta === movimento.IdEtiqueta).map(et => {
                    et._EtiquetaComplemento = et._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === movimento.IdEtiquetaComplemento).map(etc => {                    
                        return [etc];
                    })[0];
                    return et;
                });
            }
        }

        setMovimentoEstoque(movimentos);
        setLoading(false);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchExcel = async () => {
        if (movimentoEstoque === null || movimentoEstoque.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Tipo de movimento; Sku; Prdouto; Tamanho; Cor; Estampa; Data Alteração; Qtd. anterior; Qtd. Entrada`;
        let sku = '';
        movimentoEstoque?.forEach(movimento => {
            movimento._Imagem._Etiquetas.map(et => {
                return et._EtiquetaComplemento.map(etc => {
                    sku = etc.Sku !== null ? etc.Sku : '-';
                    dados += `\n${(movimento.ITipoMovimento == 1 ? 'Entrada' : 'Ajuste')}; ${sku}; ${et.sDescricao}; ${etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}; ${etc?._Cor?.Descricao ? etc?._Cor?.Descricao : '-'}; ${etc?._Estampa?.Descricao ? etc?._Estampa?.Descricao : '-'}; ${moment(movimento.DataMovimento).format('DD/MM/YYYY HH:mm:ss')}; ${movimento.IqtdAnterior}; ${movimento.IqtdEntrada}`;
                })
            })
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'MovimentoEstoque.csv', dados);
        window.open(url, '_blank');
    }

    const Row = ({ movimento }) => {
        return <Fragment key={movimento.IdMovimentacaoEstoque}>
            {
                movimento._Imagem._Etiquetas.filter(a => a.IdEtiqueta === movimento.IdEtiqueta).map(et => {
                    return et._EtiquetaComplemento.filter(a => a.IdEtiquetaComplemento === movimento.IdEtiquetaComplemento).map(etc => {
                        return <TableRow>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {movimento.ITipoMovimento == 1 ? 'Entrada' : 'Ajuste'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc.Sku === null ? '-' : etc.Sku}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {et.sDescricao}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc?.Tamanho?.Descricao ? etc?.Tamanho?.Descricao : '-'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc?._Cor?.Descricao ? etc?._Cor?.Descricao : '-'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {etc?._Estampa?.Descricao ? etc?._Estampa?.Descricao : '-'}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {moment(movimento.DataMovimento).format('DD/MM/YYYY HH:mm:ss')}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {movimento.IqtdAnterior}
                            </TableCell>
                            <TableCell align="left" style={{ minWidth: 100 }}>
                                {movimento.IqtdEntrada}
                            </TableCell>
                        </TableRow>
                    })
                })
            }
        </Fragment>
    }

    return (
        <Container>
            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
                <Grid container spacing={0} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'start' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Relatórios / Movimento de Estoque</Typography>
                    </Grid>
                    {/* <Grid item xs={2.5}>
                        <Card style={{ minHeight: '80px !important' }}>
                            <Title>Total de produtos</Title>
                            <Descricao>{totalProdutos}
                            </Descricao>
                        </Card>
                    </Grid> */}
                </Grid>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'nowrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <FormControl sx={{ marginTop: -2 }}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={1}
                                value={tipoFiltro}
                                name="radio-buttons-group"
                                onChange={(e) => { setTipoFiltro(e.target.value) }}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="Nome" />
                                <FormControlLabel value={2} control={<Radio />} label="Sku" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            label="Pesquisar"
                            placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchEstoque('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ height: '100%', marginLeft: 10, maxHeight: 43 }}
                            onClick={async () => {
                                if (page === 1)
                                    await fetchEstoque();
                                else
                                    setPage(1);
                            }}
                        >
                            Pesquisar
                        </Button>
                        <FormControl>
                            <InputLabel id="tipo-movimento-selector">Tipo do movimento</InputLabel>
                            <Select
                                labelId="tipo-movimento-selector"
                                id="tipo-movimento-selector-id"
                                value={tipoMovimento}
                                label="Tipo do movimento"
                                style={{ width: 200, marginLeft: 10, maxHeight: 43 }}
                                onChange={(e) => {
                                    setTipoMovimento(Number(e.target.value));
                                }}
                                MenuProps={{
                                    disablePortal: true,
                                    PaperProps: {
                                        style: {
                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={0}>Ambos</MenuItem>
                                <MenuItem value={1}>Entrada</MenuItem>
                                <MenuItem value={2}>Ajuste</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <PeriodPicker />
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando vendas</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                    <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
                            <Pagination
                                size="large"
                                color="primary"
                                showFirstButton
                                showLastButton
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Box>
                    </>
                )}
            </Paper>
            <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="contained"
                    style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                    onClick={() => fetchExcel()}
                >
                    <FileDownloadIcon />
                    Excel
                </Button>
            </Box>
        </Container>
    )
}

export default RelatorioMovimentoEstoque;
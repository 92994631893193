import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA, POST_DATA_DESING, PUT_DATA, PUT_DATA_DESIGN, UploadFileAzure } from "../../../services/API";
import { Box, Button, ButtonGroup, Card, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { AlertError, Container, ImgIcon } from "./styles";

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import visaSvg from '../../../assets/logos/visa.svg';
import mastercardSvg from '../../../assets/logos/mastercard.svg';
import lasy from '../../../assets/logos/lasy.svg';
import eloSvg from '../../../assets/logos/elo.svg';
import hypercard from '../../../assets/logos/hipercard.svg';
import americanExpress from '../../../assets/logos/american-express.svg';
import pix from '../../../assets/logos/pix.svg';

import vanilla from '../../../assets/vanilla.png';
import coffee from '../../../assets/coffee.png';
import chicago from '../../../assets/chicago.png';
import lollypop from '../../../assets/lollypop.png';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

import fireworks from '../../../assets/fireworks.gif';

import { TEMA_LOJA_ADD_CARROSSEL_IMAGEM, TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG, TEMA_LOJA_SET, TEMA_LOJA_SET_ABOUT_US, TEMA_LOJA_SET_CARD_PRODUTO_FAVORITO, TEMA_LOJA_SET_FALE_CONOSCO, TEMA_LOJA_SET_FOOTER, TEMA_LOJA_SET_GOOGLE_ANALITICS, TEMA_LOJA_SET_IMAGEM_PRODUTO, TEMA_LOJA_SET_LOGO, TEMA_LOJA_SET_MARKETING_SEO, TEMA_LOJA_SET_MENU, TEMA_LOJA_SET_POLITICAS, TEMA_LOJA_SET_THEME, TEMA_LOJA_SET_TIPO_LOGO, TEMA_TEMA_SELECIONADO } from "../../../store/TemaLojaReducer/types";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import UploadImagemDesign from "../../../components/UploadImagemDesign";
import * as moment from "moment";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useNavigate } from "react-router";
import { atualizarOnboardStore } from "../../../services/utils/functions";
import ExibicaoItens from "../../../components/ExibicaoItens";
import SuaLogo from '../../../assets/suaLogo.png';

import katex from "katex";
import "katex/dist/katex.min.css";
import './styles.css';

import Editor from "../../../components/QuillEditor";

import { SketchPicker } from 'react-color';
import CardItem from "./CardItem";
import CardFinalizar from "./CardFinalizar";
import axios from "axios";
import { getStorage, removeStorage, setStorage } from "../../../hooks/useStorage";


window.katex = katex;

const TikTokIcon = ({ color = "#000000" }) => {
    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="100%"
            height="100%"
        >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};


const ConstrutorEcommerce = () => {
    const dispatch = useDispatch();
    const superUser = useSelector((store) => store.superUser);
    const themeStorage = useSelector((store) => store.temaLoja);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const onBoard = useSelector((store) => store.onBoard);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [construtorSite, setConstrutorSite] = useState(null);
    const [themeStorageSelected, setThemeStorageSelected] = useState(null);
    const [tokenDesign, setTokenDesign] = useState(null);
    const [value, setValue] = useState(0);
    const [empresa, setEmpresa] = useState(null);
    const [validacoes, setValidacoes] = useState([]);

    const [avisoItensExibicao, setAvisoItensExibicao] = useState(null);

    const [dimensoesMobileStr, setDimensoesMobileStr] = useState("Tamanho máximo 765 x 429");
    const [widthMobile, setWidthMobile] = useState(765);
    const [heightMobile, setHeightMobile] = useState(429);

    const [dimensoesWebPadraoStr, setDimensoesWebPadraoStr] = useState("Tamanho máximo 1920 x 379");
    const [widthWebPadrao, setWidthWebPadrao] = useState(1920);
    const [heightWebPadrao, setHeightWebPadrao] = useState(379);

    const [dimensoesWebFullPageStr, setDimensoesWebFullPageStr] = useState("Tamanho máximo 1920 x 379");
    const [widthWebWebFullPage, setWidthWebWebFullPage] = useState(1920);
    const [heightWebFullPage, setHeightWebFullPage] = useState(379);

    useEffect(() => {
        (async () => {
            if (userLoggedIn === null) return;

            const onboardResult = await POST_DATA(`OnboardingStore/GetAllOnboardingStoreByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=OS.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=OS.IdOnboardingStore&Log=`);
            if (onboardResult[0].ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem == 3)[0].FlagStatus && superUser === null) {
                navigate('/configurar-ecommerce');
                return;
            }

            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };

            const login = await POST_AUTH(obj);
            setTokenDesign(login.token);
        })();
    }, [userLoggedIn]);

    useEffect(() => {
        (async () => {
            if (tokenDesign === null) return;
            await GetDadosTema();
        })();
    }, [tokenDesign]);

    const GetDadosTema = async () => {
        try {
            setLoading(true);

            const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);
            setEmpresa(userEmpresa);

            const store = getStorage('onboardLayout');

            if (store !== null) {
                const obj = JSON.parse(store);
                setConstrutorSite(obj);

                dispatch({
                    type: TEMA_LOJA_SET,
                    data: obj
                });
            }
            else {
                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
                setConstrutorSite(construtorSite.data);

                dispatch({
                    type: TEMA_LOJA_SET,
                    data: { ...construtorSite.data.arquivo, etapaAtual: 1 }
                });
            }

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (themeStorageSelected !== null && empresa !== null && (!themeStorageSelected?.faleConosco?.cep || themeStorageSelected?.faleConosco?.cep?.cep?.length < 8)) {
            dispatch({
                type: TEMA_LOJA_SET_FALE_CONOSCO,
                themeStorageId: construtorSite.arquivo.idThemeStoreSelected,
                faleConosco: {
                    contatoWhatsApp: empresa?.Telefone?.replace(/([^\d])+/gim, ''),
                    contatoEmail: empresa?.Email,
                    cep: empresa?.CEP?.replace(/([^\d])+/gim, ''),
                    cidade: empresa?.Cidade,
                    endereco: empresa?.Logradouro,
                    bairro: empresa?.Bairro,
                    numero: empresa?.Numero,
                    complemnto: empresa?.Complemento,
                    uf: empresa?.Estado
                }
            });

        }
        if (themeStorageSelected !== null) {
            handleTipoThema(themeStorage.tipoThema);
        }

        setTamanosBanners();
    }, [themeStorageSelected]);

    useEffect(() => {
        if (themeStorage !== null && themeStorage.themes_store) {
            const construtorSiteSelected = themeStorage.themes_store[0];
            setThemeStorageSelected(construtorSiteSelected);
            setTamanosBanners();

        }
    }, [themeStorage]);

    const setTamanosBanners = () => {
        if (themeStorage?.tipoThema === 2) { //Coffee
            setHeightWebFullPage(600);
            setHeightWebPadrao(600);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 600");
            setDimensoesWebPadraoStr("Tamanho máximo 1920 x 600");
        }
        if (themeStorage?.tipoThema === 3) { //Vanilla
            setHeightWebFullPage(553);
            setHeightWebPadrao(553);
            setWidthWebPadrao(1320);
            setDimensoesWebFullPageStr("Tamanho máximo 1420 x 553");
            setDimensoesWebPadraoStr("Tamanho máximo 1340 x 553");
        }
        if (themeStorage?.tipoThema === 4) { //Chicago
            setHeightWebFullPage(650);
            setHeightWebPadrao(650);
            setWidthWebPadrao(1320);
            setDimensoesWebFullPageStr("Tamanho máximo 1340 x 650");
            setDimensoesWebPadraoStr("Tamanho máximo 1340 x 650");
        }
        if (themeStorage?.tipoThema === 5) { //Lollypop
            setHeightWebFullPage(379);
            setHeightWebPadrao(379);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 379");
            setDimensoesWebPadraoStr("Tamanho máximo 1920 x 379");
        }
        if (themeStorage?.tipoThema === 6) { //Coral
            setHeightWebFullPage(1920);
            setHeightWebPadrao(510);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 650");
            setDimensoesWebPadraoStr("Tamanho máximo 1440 x 650");
        }
        if (themeStorage?.tipoThema === 7) { //Blush
            setHeightWebFullPage(1920);
            setHeightWebPadrao(510);
            setDimensoesWebFullPageStr("Tamanho máximo 1920 x 510");
            setDimensoesWebPadraoStr("Tamanho máximo 1125 x 510");
        }
    }

    useEffect(() => {
        if (themeStorage?.etapaAtual === 3) {
            setAvisoItensExibicao(null);
        }
    }, []);


    const enviarImagensToAzure = async (themeStorage) => {
        const theme = themeStorage.themes_store[0];
        const pasta = `${userLoggedIn.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}`

        if (theme.logo?.img !== null && theme.logo?.img !== undefined) {
            const nommeArquivo = `logo_${moment().format('YYYYMMDDHHmmss')}.${theme.logo.extensao}`;
            theme.logo.url = await UploadFileAzure(theme.logo.img, `${pasta}/images`, nommeArquivo, `image/${theme.logo.extensao}`);
        }

        for (const imagem of theme.carrossel_images) {
            let nommeArquivo = "";
            if (imagem.img !== null && imagem.img !== undefined) {
                nommeArquivo = `banner${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
            }

            //Mobile
            if (imagem?.img_mobile !== null && imagem?.img_mobile !== undefined) {
                nommeArquivo = `banner_mobile${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
            }

            if (themeStorage?.tipoThema === 2) { //Coffee
                imagem.height = "379px"
                imagem.width = "1024px"
            }
            if (themeStorage?.tipoThema === 3) { //Vanilla
                imagem.height = "553px"
                imagem.width = "1340px"
            }
            if (themeStorage?.tipoThema === 4) { //Chicago
                imagem.height = "650px"
                imagem.width = "1340px"
            }
            if (themeStorage?.tipoThema === 5) { //Lollypop
                imagem.height = "379px"
                imagem.width = "1024px"
            }
        }

        if (themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4) { //Coffee e chicago
            for (const imagem of theme.banner1) {
                let nommeArquivo = "";

                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `banner1${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `banner_mobile1${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }
            }

            for (const imagem of theme.banner2) {
                let nommeArquivo = "";
                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `banner2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `banner_mobile2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }
            }

            for (const item of theme.exibicao_itens) {
                if (item.imagem.img !== null && item.imagem.img !== undefined) {
                    const nommeArquivo = `${item.imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${item.imagem.extensao}`;
                    item.imagem.url = await UploadFileAzure(item.imagem.img, `${pasta}/images`, nommeArquivo, `image/${item.imagem.extensao}`);
                }
            }
        }

        if (themeStorage?.tipoThema === 3) { //Tema Vanilla
            for (const imagem of theme.banner2) {
                let nommeArquivo = "";
                if (imagem.img !== null && imagem.img !== undefined) {
                    nommeArquivo = `banner2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url = await UploadFileAzure(imagem.img, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }

                //Mobile
                if (imagem?.img_mobile !== null && imagem.img_mobile !== undefined) {
                    nommeArquivo = `banner_mobile2${imagem.id}_${moment().format('YYYYMMDDHHmmss')}.${imagem.extensao}`;
                    imagem.url_mobile = await UploadFileAzure(imagem.img_mobile, `${pasta}/images`, nommeArquivo, `image/${imagem.extensao}`);
                }
            }

            for (const produtoFavorito of theme.cardsProdutoFavorito) {
                let nommeArquivo = "";
                if (produtoFavorito.imagem.img !== null && produtoFavorito.imagem.img !== undefined) {
                    nommeArquivo = `image-${produtoFavorito.id}_${moment().format('YYYYMMDDHHmmss')}.${produtoFavorito.imagem.extensao}`;
                    produtoFavorito.imagem.url = await UploadFileAzure(produtoFavorito.imagem.img, `${pasta}/images`, nommeArquivo, `image/${produtoFavorito.imagem.extensao}`);
                }
            }
        }

        if (theme.aboutUs?.imagem1?.url !== null && theme.aboutUs.imagem1.img !== undefined) {
            const nommeArquivo = `${theme.aboutUs.imagem1.id}_${moment().format('YYYYMMDDHHmmss')}.${theme.aboutUs.imagem1.extensao}`;
            theme.aboutUs.imagem1.url = await UploadFileAzure(theme.aboutUs.imagem1.img, `${pasta}/images`, nommeArquivo, theme.aboutUs.imagem1.extensao);
        }

        if (theme.aboutUs?.imagem2?.url !== null && theme.aboutUs.imagem2.img !== undefined) {
            const nommeArquivo = `${theme.aboutUs.imagem2.id}_${moment().format('YYYYMMDDHHmmss')}.${theme.aboutUs.imagem2.extensao}`;
            theme.aboutUs.imagem2.url = await UploadFileAzure(theme.aboutUs.imagem2.img, `${pasta}/images`, nommeArquivo, theme.aboutUs.imagem2.extensao);
        }

        if (theme.marketingSEO?.favicon?.url !== null && theme.marketingSEO?.favicon.img !== undefined) {
            const nommeArquivo = `${theme.marketingSEO.favicon.id}_${moment().format('YYYYMMDDHHmmss')}.${theme.marketingSEO.favicon.extensao}`;
            theme.marketingSEO.favicon.url = await UploadFileAzure(theme.marketingSEO.favicon.img, `${pasta}/images`, nommeArquivo, theme.marketingSEO.favicon.extensao);
        }

        return themeStorage;
    }

    const handleValidacao = () => {
        let msgValidacao = '';
        let htmlValidacao = '';

        if (themeStorage === null || themeStorage?.tipoThema === 0) {
            msgValidacao += '<li><Typography>Favor selecionar o tema da loja</Typography></li>';
        }
        if (!themeStorageSelected?.logo?.url || themeStorageSelected?.logo?.url.length === 0) {
            msgValidacao += '<li><Typography>Favor informar a logo da loja</Typography></li>';
        }
        if (themeStorageSelected?.carrossel_images.filter(a => a.url !== null && a.url?.length > 0).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos um banner web para a loja</Typography></li>';
        }

        if (themeStorageSelected?.carrossel_images.filter(a => a.url_mobile !== null && a.url_mobile?.length > 0).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos um banner mobile para a loja</Typography></li>';
        }
        if (themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos uma rede social da loja</Typography></li>';
        }
        if (themeStorageSelected?.footer?.formas_pagamento?.filter(a => a.selected).length === 0) {
            msgValidacao += '<li><Typography>Favor informar ao menos uma forma de pagamento para loja</Typography></li>';
        }

        //Endereço
        if (!themeStorageSelected?.faleConosco?.cep || (themeStorageSelected?.faleConosco?.cep && themeStorageSelected?.faleConosco?.cep.length < 8)) {
            msgValidacao += '<li><Typography>Favor informar o CEP do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.endereco || (themeStorageSelected?.faleConosco?.endereco && themeStorageSelected?.faleConosco?.endereco.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar o logradouro do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.bairro || (themeStorageSelected?.faleConosco?.bairro && themeStorageSelected?.faleConosco?.bairro.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar o bairro do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.cidade || (themeStorageSelected?.faleConosco?.cidade && themeStorageSelected?.faleConosco?.cidade.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar a cidade do endereço da loja</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.uf || (themeStorageSelected?.faleConosco?.uf && themeStorageSelected?.faleConosco?.uf.length < 2)) {
            msgValidacao += '<li><Typography>Favor informar o UF do endereço da loja</Typography></li>';
        }

        //Contato
        if (!themeStorageSelected?.faleConosco?.contatoWhatsApp || (themeStorageSelected?.faleConosco?.contatoWhatsApp && themeStorageSelected?.faleConosco?.contatoWhatsApp.length < 11)) {
            msgValidacao += '<li><Typography>Na sessão de endereço e contato da loja, favor informar um Whatsapp válido</Typography></li>';
        }
        if (!themeStorageSelected?.faleConosco?.contatoEmail || (themeStorageSelected?.faleConosco?.contatoEmail && themeStorageSelected?.faleConosco?.contatoEmail.length === 0)) {
            msgValidacao += '<li><Typography>Favor informar o e-mail da loja</Typography></li>';
        }

        if (msgValidacao.length > 0) {
            htmlValidacao += `<Typography style='text-align: center;
            display: flex;
            justify-content: center;
            font-weight: bold;
            font-size: 21px;'>Itens pendentes para verificação</Typography>`;

            htmlValidacao += `<ul>`;

            htmlValidacao += msgValidacao;

            htmlValidacao += `</ul>`;
        }

        setValidacoes(htmlValidacao);
        return msgValidacao.length;
    }

    const getRemoveComplementoRedeSocial = (link) => {
        link = link.replace('https://www.facebook.com/', '');
        link = link.replace('https://www.instagram.com/', '');
        link = link.replace('https://br.pinterest.com/', '');
        link = link.replace('https://twitter.com/', '');
        link = link.replace('https://www.youtube.com/', '');
        link = link.replace('https://www.tiktok.com/', '');
        link = link.replace('https://www.linkedin.com/in/', '');

        return link;
    }

    const handlePublish = async (visualizar = true) => {
        try {
            const res = handleValidacao();
            if (res > 0) {
                window.scrollTo(0, 0);
                return;
            }

            setLoading(true);

            const theme_storage = { ...themeStorage };
            const themeToSave = await enviarImagensToAzure(theme_storage);

            const usuario = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
            if (usuario !== null) {
                themeToSave.themes_store[0].footer.redes_socias.forEach((item) => {

                    if (item.id === 'instagram')
                        usuario.Instagram = item.link;
                    else if (item.id === 'facebook')
                        usuario.Facebook = item.link;
                    else if (item.id === 'tiktok')
                        usuario.Tiktok = item.link;
                    else if (item.id === 'twitter')
                        usuario.Twitter = item.link;
                    else if (item.id === 'youtube')
                        usuario.Youtube = item.link;
                    else if (item.id === 'linkedin')
                        usuario.LinkedIn = item.link;
                });

                await POST_DATA(`Usuario/updateDadosUsuario`, usuario);
            }

            const construtorSiteObj = { ...construtorSite };
            if (construtorSiteObj.id > 0) {
                //construtorSite.arquivo = themeToSave;
                construtorSiteObj.ArquivoStr = JSON.stringify(themeToSave);

                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, construtorSiteObj);
            } else {
                const obj = {
                    id: 0,
                    idCliente: userLoggedIn.IdUsuario,
                    idUsuarioAlteracao: 1, //Admin
                    urlSite: '',
                    urlArquivoConfig: '',
                    status: 1,
                    arquivoStr: JSON.stringify(themeToSave)
                }
                await POST_DATA_DESING(`ConstrutorSite`, tokenDesign, obj);
            }

            await PUT_DATA('UsuarioEmpresa/AtualizarHoraAtendimentoUsuarioEmpresa', empresa);

            let resultConstrutorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
            setConstrutorSite(resultConstrutorSite.data);

            if (resultConstrutorSite.data.id > 0) {
                await PUT_DATA_DESIGN(`ConstrutorSite/AtualizarDominio?id=${resultConstrutorSite.data.id}&urlDominio=${moment().format('YYYYMMDDSS')}.me`, tokenDesign);
                resultConstrutorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLoggedIn.IdUsuario}`, tokenDesign);
                setConstrutorSite(resultConstrutorSite.data);
            }

            dispatch({
                type: TEMA_LOJA_SET,
                data: resultConstrutorSite.data.arquivo
            });

            removeStorage('onboardLayout');

            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus) { //Personalize o design
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 3;

                let itemOnboard = null;
                for (const onboardItem of objOnboard.ListOnboardingStorexOnboardingStoreItem) {
                    if (onboardItem.IdOnboardingStoreItem === 3) {
                        onboardItem.FlagStatus = true;
                        itemOnboard = onboardItem;
                    }
                }

                atualizarOnboardStore(objOnboard, itemOnboard);
                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });
            }

            Swal.fire({
                backdrop: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: "Parabéns! A sua loja já esta pronta.",
                width: 600,
                padding: "3em",
                color: "#33cccc",
                imageUrl: fireworks,
                imageHeight: 200,
                imageAlt: "Finalizar",
                confirmButtonText: "Ver sua loja",
                confirmButtonColor: '#F1416C'
            }).then((result) => {
                if (result.isConfirmed) {
                    handleOpenECommerce(resultConstrutorSite.data, true);
                }
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: 'Ocorreu um erro ao salvar os dados da loja',
            });
        }
    };

    const getIconSocialMedia = (id) => {
        if (id === undefined) return null;

        const estilo = {
            fontSize: '30px'
        }
        let Icon = null;
        if (id === 'facebook') {
            Icon = <FacebookIcon
                style={estilo}
            />
        }
        else if (id === 'instagram') {
            Icon = <InstagramIcon
                style={estilo}
            />
        }
        else if (id === 'twitter') {
            Icon = <XIcon
                style={estilo}
            />
        }
        else if (id === 'whatsapp') {
            Icon = <WhatsAppIcon
                style={estilo}
            />
        }
        else if (id === 'pinterest') {
            Icon = <PinterestIcon
                style={estilo}
            />
        }
        else if (id === 'youtube') {
            Icon = <YouTubeIcon
                style={estilo}
            />
        }
        else if (id === 'tiktok') {
            Icon = <TikTokIcon
                style={{ ...estilo, width: 20 }}
            />
        }
        else if (id === 'linkedin') {
            Icon = <LinkedInIcon
                style={estilo}
            />
        }

        const item = themeStorageSelected.footer.redes_socias.filter(a => a.id === id)[0];
        if (item === undefined || item === null) return null;

        const userRedeSocial = getRemoveComplementoRedeSocial(item.link);

        return (
            <Box key={id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, maxWidth: 300 }}>
                <FormControlLabel
                    labelPlacement="top"
                    control={<Box style={{ display: 'flex', alignItems: 'center' }}>
                        {Icon}
                        <TextField
                            title={id == 'twitter' ? 'Twitter/X' : item.name}
                            style={{ margin: 10, minWidth: 200 }}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            value={userRedeSocial}
                            onChange={(e) => {
                                if (e.target.value.length === 0) {
                                    handleFooterCheckProperty(item.id, 'redes_socias', false);
                                }
                                else {
                                    handleFooterCheckProperty(item.id, 'redes_socias', true);
                                }

                                let link = getRemoveComplementoRedeSocial(e.target.value.trim());

                                handleChangeDataFooter(item.id, 'redes_socias', 'link', link);
                            }}

                            id="outlined-basic"
                            label={id === 'whatsapp' ? 'Digite o número' : 'Digite o link'}
                        />
                    </Box>}
                    label={item.id == 'twitter' ? 'Twitter/X' : item.name}
                />
            </Box>
        );
    }

    const getIconsPaymentsTypes = (item) => {
        if (item === undefined || item === null || item?.id === 'boleto') return null;

        let icon = null;
        if (item.id === 'visa') {
            icon = <ImgIcon imgSrc={visaSvg} />
        }
        else if (item.id === 'mastercard') {
            icon = <ImgIcon imgSrc={mastercardSvg} />
        }
        else if (item.id === 'lasy') {
            icon = <ImgIcon imgSrc={lasy} />
        }
        else if (item.id === 'elo') {
            icon = <ImgIcon imgSrc={eloSvg} />
        }
        else if (item.id === 'hypercard') {
            icon = <ImgIcon imgSrc={hypercard} />
        }
        else if (item.id === 'american_express') {
            icon = <ImgIcon imgSrc={americanExpress} />
        }
        else if (item.id === 'pix') {
            icon = <ImgIcon imgSrc={pix} />
        }

        return (
            <Box key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    name={item.id}
                    id={item.id}
                    checked={item.selected}
                    onChange={() => handleFooterCheckProperty(item.id, 'formas_pagamento')}
                />
                {icon}
            </Box>
        );
    }

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTipoThema = (idTipoThema) => {
        dispatch({
            type: TEMA_LOJA_SET,
            data: { ...themeStorage, tipoThema: idTipoThema }
        });

        if (idTipoThema === 2) {  //Coffee
            handleChangeTemeSelected('footer', 'newSletter', true);

            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#ffff');
            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');

            //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', 'black');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#ffff');

            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Avenir light');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 16);
        }
        else if (idTipoThema === 3) { //Vanilla
            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');

            //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#FE9E40');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Avenir Light');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 18);
        }
        else if (idTipoThema === 4) {  //Chicago
            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');

            //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#e23009');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, '"Roboto","Helvetica","Arial", sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 18);
        }
        else if (idTipoThema === 5) {  //Lolly Pop
            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');

            //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#F1416C');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, '"Roboto","Helvetica","Arial", sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 18);
        }
        else if (idTipoThema === 6) {  //Coral
            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, '#282828');

            //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#D0451B');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', 'white');

            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, '"Brandon grotesque","Helvetica","Arial", sans-serif');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 20);
        }
        else if (idTipoThema === 7) {  //Blush
            //Plano  de fundo
            handleChangeDataTema('palette', 'personal', 'background', 'paper', '#FFFFFF');
            //Cor da fonte
            handleChangeDataTema('typograpy', 'color', null, null, 'black');

            //Componente principal
            handleChangeDataTema('palette', 'personal', 'component', 'background', '#4A4A4A');
            //Cor fonte
            handleChangeDataTema('palette', 'personal', 'component', 'color', '#fcfcfc');

            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontFamily', null, null, 'Proxima nova');
            //Tipo de fonte
            handleChangeDataTema('typograpy', 'fontSize', null, null, 14);
        }

        handleSelectMenu();
        handleSelecionarCategoriasFooter();
    }

    const handleOnChangeLogo = (dataLogo) => {
        dispatch({
            type: TEMA_LOJA_SET_LOGO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            logo: dataLogo
        });
    }

    const handleChangeTipoLogo = (tipoLogo) => {
        dispatch({
            type: TEMA_LOJA_SET_TIPO_LOGO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            tipo_logo: tipoLogo
        });
    }

    const handleOnChangeImagemCarrossel = (dataImagem, property = 'carrossel_images') => {
        dispatch({
            type: TEMA_LOJA_ADD_CARROSSEL_IMAGEM,
            property: property,
            themeStorageId: themeStorage.idThemeStoreSelected,
            imagem: dataImagem
        });
    }

    const handleChangeAboutUs = (property, value) => {
        if (themeStorageSelected?.aboutUs === undefined) return;

        const data = { ...themeStorageSelected?.aboutUs };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_ABOUT_US,
            themeStorageId: themeStorage.idThemeStoreSelected,
            aboutUs: data
        });
    }

    const handleChangeEndereco = (property, value) => {
        const data = { ...themeStorageSelected?.faleConosco };
        data[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_FALE_CONOSCO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            faleConosco: data
        });
    }

    const handleChangeTemeSelected = (property, subproperty, value) => {
        const data = { ...themeStorageSelected };

        if (subproperty !== null) {
            data[property][subproperty] = value;
        }
        else {
            data[property] = value;
        }

        dispatch({
            type: TEMA_TEMA_SELECIONADO,
            themeStorageId: themeStorage.idThemeStoreSelected,
            data: data
        });
    }

    const handleSelecionarCategoriasFooter = () => {
        const footer = { ...themeStorageSelected.footer };

        footer.categorias.forEach(categoria => {
            categoria.selected = true;
            return categoria;
        });

        footer.ajuda.forEach(ajuda => {
            ajuda.selected = true;
            return ajuda;
        });

        dispatch({
            type: TEMA_LOJA_SET_FOOTER,
            themeStorageId: themeStorage.idThemeStoreSelected,
            data: footer
        });
    }

    const handleCheckFullPageBanners = (property, value) => {
        const objs = [...themeStorageSelected[property]];
        let obj = objs.filter(a => a.type === 'config')[0];

        if (obj !== null && obj !== undefined) {
            obj = {
                ...obj,
                fullPage: value
            }
        }
        else {
            obj = {
                id: 4,
                type: 'config',
                fullPage: value
            }
        }

        dispatch({
            type: TEMA_LOJA_ADD_CARROSSEL_IMAGEM_CONFIG,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            value: obj
        });
    }

    const handleChangeDataPageBanners = (idx, property, subproperty, value) => {
        const obj = [...themeStorageSelected[property]][idx];
        obj[subproperty] = value;

        dispatch({
            type: TEMA_LOJA_ADD_CARROSSEL_IMAGEM,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: 'carrossel_images',
            imagem: obj
        });
    }

    const handleFooterCheckProperty = (id, property, valor = null) => {
        const data = [...themeStorageSelected.footer[property]];

        data.forEach(footer => {
            if (footer.id === id) {
                footer.selected = valor !== null ? valor : !footer.selected;
            }
            return footer;
        });

        dispatch({
            type: TEMA_LOJA_SET_FOOTER,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            data: data
        });
    }

    const handleChangeDataFooter = (id, property, item, value) => {
        let data = null;
        if (property !== undefined && property !== null) {
            data = [...themeStorageSelected.footer[property]];
            data.forEach(footer => {
                if (footer.id === id) {
                    footer[item] = value;
                }
                return footer;
            });
        }
        else {
            data = { ...themeStorageSelected.footer };
            if (id !== undefined && id !== null) {
                data.forEach(footer => {
                    if (footer.id === id) {
                        footer[item] = value;
                    }
                    return footer;
                });
            } else {
                data[item] = value;
            }
        }

        dispatch({
            type: TEMA_LOJA_SET_FOOTER,
            themeStorageId: themeStorage.idThemeStoreSelected,
            property: property,
            data: data
        });
    }

    const handleOpenECommerce = (construtor, redirecionar = false) => {
        if (construtor !== null && construtor.id > 0 && construtor.urlSite) {
            const codigo = construtor.urlSite.split('=');
            let url = `http://chicagodev.leapstyle.com.br/?xyz=${codigo[1]}`; //DEV

            if (process.env.REACT_APP_AMBIENTE === 'PRD') {
                if (themeStorage?.tipoThema === 2) //Coffee
                    url = `https://coffe.leapstyle.com.br/?xyz=${codigo[1]}`;
                else if (themeStorage?.tipoThema === 3) //Vanilla
                    url = `https://vanilla.leapstyle.com.br/?xyz=${codigo[1]}`;
                else if (themeStorage?.tipoThema === 4) //Chicago
                    url = `https://chicago.leapstyle.com.br/?xyz=${codigo[1]}`;
                else if (themeStorage?.tipoThema === 5) //Lollypop
                    url = `https://lollypop.leapstyle.com.br/?xyz=${codigo[1]}`;
                else if (themeStorage?.tipoThema === 6) //Coral
                    url = `https://coral.leapstyle.com.br/?xyz=${codigo[1]}`;
                else if (themeStorage?.tipoThema === 7) //Blush
                    url = `https://blush.leapstyle.com.br/?xyz=${codigo[1]}`;
            }

            window.open(url, '_blank');

            if (redirecionar) {
                navigate('/on-board');
            }
        }
    }

    const marcouDiaSemana = empresa?.FlgAbreSeg || empresa?.FlgAbreTer || empresa?.FlgAbreQua || empresa?.FlgAbreQui || empresa?.FlgAbreSex;
    const handleChangeEmpresa = (property, value) => {
        const tmp = { ...empresa };
        tmp[property] = value;

        setEmpresa(tmp);
    }

    const handleChangeDataTema = (property, subproperty, subsubproperty, subsubsubproperty, value) => {
        const theme = { ...themeStorageSelected.theme };

        if (subproperty)
            if (subsubproperty !== null)
                if (subsubsubproperty !== null)
                    theme[property][subproperty][subsubproperty][subsubsubproperty] = value;
                else
                    theme[property][subproperty][subsubproperty] = value;
            else
                theme[property][subproperty] = value;
        else
            theme[property] = value;

        dispatch({
            type: TEMA_LOJA_SET_THEME,
            themeStorageId: themeStorage.idThemeStoreSelected,
            theme: theme
        });
    }

    const handleSelectMenu = () => {
        const menus = [...themeStorageSelected.menu];
        menus.forEach(m => {
            //Padrão
            if (themeStorage.tipoThema === 1 && m.type === 'padrao') {
                if (m.id === 'home' || m.id === 'produtos' || m.id === 'lojas' || m.id === 'promocoes' || m.id === 'lancamentos' || m.id === 'aboutUs' || m.id === 'contact' || m.id === 'newIn') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Coffee
            else if (themeStorage.tipoThema === 2 && m.type === 'coffee') {
                if (m.id === 'paginaInicial' || m.id === 'colecao' || m.id === 'nossoHistoria' || m.id === 'faleConosco') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Vanilla
            else if (themeStorage.tipoThema === 3 && m.type === 'vanilla') {
                if (m.id === 'paginaInicial' || m.id === 'nossaHistoria' || m.id === 'lojaVanilla' || m.id === 'contatoVanilla') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Chicago
            else if (themeStorage.tipoThema === 4 && m.type === 'chicago') {
                if (m.id === 'paginaInicial' || m.id === 'ofertas' || m.id === 'produtos' || m.id === 'nossaHistoria') {
                    m.selected = true;
                }
                else
                    m.selected = false;
            }
            //Lolly
            else if (themeStorage.tipoThema === 5 && m.type === 'lollyPop') {
                if (m.id === 'paginaInicial' || m.id === 'produtos' || m.id === 'ofertas' || m.id === 'faleConosco' || m.id === 'nossaHistoria')
                    m.selected = true;
                else
                    m.selected = false;
            }
            else {
                m.selected = false;
            }

            return m;
        });

        dispatch({
            type: TEMA_LOJA_SET_MENU,
            themeStorageId: themeStorage.idThemeStoreSelected,
            opcoesMenu: menus
        });
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleCep = (e) => {
        if (e.target.value.length === 8) {
            axios
                .get(`https://viacep.com.br/ws/${e.target.value}/json/`)
                .then((res) => {
                    if (!res.data.erro) {
                        handleChangeEndereco(`endereco`, res.data.logradouro);
                        handleChangeEndereco(`complemento`, res.data.complemento);
                        handleChangeEndereco(`numero`, res.data.siafi);
                        handleChangeEndereco(`bairro`, res.data.bairro);
                        handleChangeEndereco(`cidade`, res.data.localidade);
                        handleChangeEndereco(`uf`, res.data.uf);
                    }
                });
        }
    };


    return loading ? (
        <Stack
            sx={{ height: "450px", marginTop: '10%' }}
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>Carregando dados do eCommerce</Typography>
        </Stack>
    ) : (
        <Container>
            <Grid container spacing={3} style={{ marginTop: 2 }}>
                {
                    validacoes?.length > 0 &&
                    <Grid item xs={12} style={{ padding: 20, paddingLeft: 50, paddingRight: 50 }}>
                        <Box style={{ width: '100%', border: '1px solid #d4d4d4', height: 'auto', padding: 20, borderRadius: 20 }} dangerouslySetInnerHTML={{ __html: validacoes }} />
                    </Grid>
                }

                {
                    themeStorage?.etapaAtual === 1 &&
                    <Grid item xs={12}>
                        <CardItem
                            etapa={{
                                numero: 1,
                                descricao: 'Layout da loja'
                            }}
                            title='Escolha o estilo da sua loja'
                            descricao='Selecione o estilo visual que melhor representa a identidade da sua loja'
                        >
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                {
                                    superUser !== null && userLoggedIn.IdUsuario === 188 &&
                                    <FormControlLabel
                                        labelPlacement="top"
                                        control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <img alt="vanilla" src={coffee} style={{ width: 100, height: 170 }} />
                                            {
                                                themeStorage?.tipoThema === 1 &&
                                                <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                            }
                                            {
                                                themeStorage?.tipoThema !== 1 &&
                                                <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(1)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                            }
                                        </Box>}
                                        label={<Typography style={{ fontWeight: 'bold' }}>Padrão</Typography>}
                                    />
                                }

                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <img alt="coffee" src={coffee} style={{ width: 100, height: 170 }} />

                                        <Button onClick={() => window.open('https://coffe.leapstyle.com.br/', '_blank')} variant="outlined" style={{ marginTop: 10 }}>Ver modelo</Button>
                                        {
                                            themeStorage?.tipoThema === 2 &&
                                            <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                        }
                                        {
                                            themeStorage?.tipoThema !== 2 &&
                                            <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(2)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                        }
                                    </Box>}
                                    label={<Typography style={{ fontWeight: 'bold' }}>Coffee</Typography>}
                                />

                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <img alt="vanilla" src={vanilla} style={{ width: 100, height: 170 }} />
                                        <Button onClick={() => window.open('https://vanilla.leapstyle.com.br/', '_blank')} variant="outlined" style={{ marginTop: 10 }}>Ver modelo</Button>
                                        {
                                            themeStorage?.tipoThema === 3 &&
                                            <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                        }
                                        {
                                            themeStorage?.tipoThema !== 3 &&
                                            <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(3)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                        }
                                    </Box>}
                                    label={<Typography style={{ fontWeight: 'bold' }}>Vanilla</Typography>}
                                />

                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <img alt="chicago" src={chicago} style={{ width: 100, height: 170 }} />
                                        <Button onClick={() => window.open('https://chicago.leapstyle.com.br/', '_blank')} variant="outlined" style={{ marginTop: 10 }}>Ver modelo</Button>
                                        {
                                            themeStorage?.tipoThema === 4 &&
                                            <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                        }
                                        {
                                            themeStorage?.tipoThema !== 4 &&
                                            <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(4)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                        }
                                    </Box>}
                                    label={<Typography style={{ fontWeight: 'bold' }}>Chicago</Typography>}
                                />

                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <img alt="lollypop" src={lollypop} style={{ width: 100, height: 170 }} />
                                        <Button onClick={() => window.open('https://lollypop.leapstyle.com.br/', '_blank')} variant="outlined" style={{ marginTop: 10 }}>Ver modelo</Button>
                                        {
                                            themeStorage?.tipoThema === 5 &&
                                            <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                        }
                                        {
                                            themeStorage?.tipoThema !== 5 &&
                                            <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(5)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                        }
                                    </Box>}
                                    label={<Typography style={{ fontWeight: 'bold' }}>LollyPop</Typography>}
                                />

                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <img alt="coral" src={coffee} style={{ width: 100, height: 170 }} />
                                        <Button onClick={() => window.open('https://coral.leapstyle.com.br/', '_blank')} variant="outlined" style={{ marginTop: 10 }}>Ver modelo</Button>
                                        {
                                            themeStorage?.tipoThema === 6 &&
                                            <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                        }
                                        {
                                            themeStorage?.tipoThema !== 6 &&
                                            <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(6)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                        }
                                    </Box>}
                                    label={<Typography style={{ fontWeight: 'bold' }}>Coral</Typography>}
                                />

                                <FormControlLabel
                                    labelPlacement="top"
                                    control={<Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <img alt="blush" src={coffee} style={{ width: 100, height: 170 }} />
                                        <Button onClick={() => window.open('https://blush.leapstyle.com.br/', '_blank')} variant="outlined" style={{ marginTop: 10 }}>Ver modelo</Button>
                                        {
                                            themeStorage?.tipoThema === 7 &&
                                            <Button variant="contained" style={{ marginTop: 10, background: 'black', color: 'white' }}>Selecionado</Button>
                                        }
                                        {
                                            themeStorage?.tipoThema !== 7 &&
                                            <Button disabled={construtorSite !== null && construtorSite.id > 0} onClick={() => handleTipoThema(7)} variant="contained" style={{ marginTop: 10 }}>Selecionar</Button>
                                        }
                                    </Box>}
                                    label={<Typography style={{ fontWeight: 'bold' }}>Blush</Typography>}
                                />
                            </Box>
                        </CardItem>
                        <CardItem
                            title='Disposição'
                            descricao='Selecione o formato do logtipo que vai aparecer na sua loja'
                        >
                            {
                                themeStorage?.tipoThema === 2 &&
                                <img src='https://leapstyle.blob.core.windows.net/temas-template/estrutura_coffee_template.png' width={'100%'} alt='tema coffee' />
                            }
                            {
                                themeStorage?.tipoThema === 3 &&
                                <img src='https://leapstyle.blob.core.windows.net/temas-template/estrutura_vanilla_template.png' width={'100%'} alt='tema vanilla' />
                            }
                            {
                                themeStorage?.tipoThema === 4 &&
                                <img src='https://leapstyle.blob.core.windows.net/temas-template/estrutura_chicago_template.png' width={'100%'} alt='tema chicago' />
                            }
                            {
                                themeStorage?.tipoThema === 5 &&
                                <img src='https://leapstyle.blob.core.windows.net/temas-template/estrutura_lollypop_template.png' width={'100%'} alt='tema lollypop' />
                            }
                        </CardItem>

                        <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                            <CardFinalizar
                                themeStorage={themeStorage}
                                proximo={{
                                    etapaExecutada: 1,
                                    etapaAtual: 2
                                }}
                                onClickProximo={() => {
                                    if (themeStorage === null || themeStorage?.tipoThema === 0 || (themeStorage?.tipoThema === 1 && superUser === null)) {
                                        dispatch({
                                            type: CONTROLEMENSAGEM_AVISO,
                                            tipoComponente: 'alert',
                                            titulo: 'Aviso',
                                            message: 'Favor selecionar o tema da loja'
                                        });
                                        return false;
                                    }

                                    const obj = { ...themeStorage };
                                    obj.themes_store[0] = themeStorageSelected;
                                    setStorage('onboardLayout', JSON.stringify(obj));

                                    return true;
                                }}
                            />
                        </Box>
                    </Grid>
                }

                {
                    themeStorage && ((superUser != null && themeStorage?.tipoThema === 1) || themeStorage?.tipoThema > 1) &&
                    <>
                        {
                            themeStorage?.etapaAtual === 2 &&
                            <Grid item xs={12}>
                                <CardItem
                                    etapa={{
                                        numero: 2,
                                        descricao: 'Etapa',
                                        identificacao: {
                                            title: 'Identificação',
                                            descricao: 'Adicione o logo da sua loja para começarmos a personalização.'
                                        }
                                    }}
                                    title='Logo'
                                >
                                    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        <UploadImagemDesign
                                            dados={themeStorageSelected?.logo}
                                            isEditing={true}
                                            key={'logo_1'}
                                            id={'logo_1'}
                                            idComponent={'logo_1'}
                                            imgData={themeStorageSelected?.logo?.url}
                                            onChange={handleOnChangeLogo}
                                            borderRadiusImg={0}
                                            paddingTopImg={0}
                                            widthImg='100%'
                                            heightImg={140}
                                            style={{ width: 180, height: 220, marginTop: 10 }}
                                            descricao="200x200"

                                            dimensaoHeightImg={200}
                                            dimensaoWidthImg={200}
                                        />
                                        <Typography style={{ fontSize: 18, color: 'gray', marginTop: 10 }}>Insira sua logo aqui</Typography>
                                        <Typography style={{ fontSize: 13, color: 'gray' }}>Tamanho máximo 1MB.</Typography>
                                        <Typography style={{ fontSize: 13, color: 'gray' }}>Todos os logos serão redimensionados para o tamanho máximo de 600x600 pixels.</Typography>
                                    </Box>
                                </CardItem>
                                <CardItem
                                    title='Formato'
                                    descricao='Selecione o formato do logtipo que vai aparecer na sua loja'
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} style={{ display: 'flex', width: 250, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <img
                                                style={{ borderRadius: 50, height: 100, width: 100, padding: 2, border: '1px dashed black' }}
                                                src={themeStorageSelected?.logo?.url ? themeStorageSelected?.logo?.url : SuaLogo}
                                            />
                                            <Typography style={{ textAlign: 'center', marginTop: 5 }}>Circulo</Typography>
                                            <Checkbox
                                                name='Circulor'
                                                id='circulo'
                                                checked={themeStorageSelected?.logo?.tipo_logo === 1 ? true : false}
                                                onChange={() => handleChangeTipoLogo(1)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} style={{ display: 'flex', width: 250, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <img
                                                style={{ width: 100, height: 100, border: '1px dashed black' }}
                                                src={themeStorageSelected?.logo?.url ? themeStorageSelected?.logo?.url : SuaLogo}
                                            />
                                            <Typography style={{ textAlign: 'center', marginTop: 5 }}>Quadrado</Typography>
                                            <Checkbox
                                                name='Circulor'
                                                id='circulo'
                                                checked={themeStorageSelected?.logo?.tipo_logo === 2 ? true : false}
                                                onChange={() => handleChangeTipoLogo(2)}
                                            />
                                        </Grid>
                                        <Grid item xs={4} style={{ display: 'flex', width: 250, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <img
                                                style={{ width: 150, height: 100, border: '1px dashed black' }}
                                                src={themeStorageSelected?.logo?.url ? themeStorageSelected?.logo?.url : SuaLogo}
                                            />
                                            <Typography style={{ textAlign: 'center', marginTop: 5 }}>Retangulo</Typography>
                                            <Checkbox
                                                name='Circulor'
                                                id='circulo'
                                                checked={themeStorageSelected?.logo?.tipo_logo === 3 ? true : false}
                                                onChange={() => handleChangeTipoLogo(3)}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardItem>
                                <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                    <CardFinalizar
                                        themeStorage={themeStorage}
                                        voltar={{
                                            etapaExecutada: 1,
                                            etapaAtual: 1
                                        }}
                                        proximo={{
                                            etapaExecutada: 2,
                                            etapaAtual: 3
                                        }}
                                        onClickProximo={() => {
                                            let msg = '';
                                            if (themeStorageSelected?.logo?.url === undefined) {
                                                msg += '\nFavor informar um ícone.';
                                            }
                                            if (themeStorageSelected?.logo?.tipo_logo === undefined) {
                                                msg += '\nFavor selecionar o formato do ícone.';
                                            }
                                            if (msg.length > 0) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    tipoComponente: 'alert',
                                                    titulo: 'Aviso',
                                                    message: msg
                                                });
                                                return false;
                                            }

                                            const obj = { ...themeStorage };
                                            obj.themes_store[0] = themeStorageSelected;
                                            setStorage('onboardLayout', JSON.stringify(obj));

                                            return true;
                                        }}
                                    />
                                </Box>
                            </Grid>
                        }

                        {
                            themeStorage?.etapaAtual === 3 &&
                            <Grid item xs={12}>
                                <CardItem
                                    etapa={{
                                        numero: 3,
                                        descricao: 'Banners',
                                        identificacao: {
                                            descricao: 'Os tamanhos dos banners variam de acordo com a disposição escolhida.'
                                        }
                                    }}
                                >
                                    <CardItem
                                        borda={true}
                                        title='Banner Topo'
                                    >
                                        <Box sx={{ width: '100%', marginTop: 2 }}>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    name='bannerTopo'
                                                    id='bannerTopo'
                                                    checked={themeStorageSelected?.carrossel_images?.length > 0 ? themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                    onChange={() => {
                                                        const chk = themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage;
                                                        handleCheckFullPageBanners('carrossel_images', !chk);
                                                    }}
                                                />
                                                <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>
                                            </Box>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
                                                    {themeStorageSelected?.carrossel_images && themeStorageSelected.carrossel_images.filter(a => a.type !== 'config').map((imagem, index) => {
                                                        return (
                                                            <Tab key={index} style={{ fontSize: '16px' }} label={`Banner Imagem ${index + 1}`} {...a11yProps(index)} />
                                                        )
                                                    })}
                                                </Tabs>
                                            </Box>
                                            {themeStorageSelected?.carrossel_images && themeStorageSelected.carrossel_images.filter(a => a.type !== 'config').map((imagem, index) => {
                                                return (
                                                    value === index &&
                                                    <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                        <Box style={{ display: 'flex' }}>
                                                            <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Web Topo Loja</Typography>
                                                                <UploadImagemDesign
                                                                    dados={imagem}
                                                                    isEditing={true}
                                                                    key={imagem.id}
                                                                    id={imagem.id}
                                                                    idComponent={`bannerTop_web_${index}`}
                                                                    dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                    dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                    imgData={imagem.url}
                                                                    onChange={handleOnChangeImagemCarrossel}
                                                                    borderRadiusImg={0}
                                                                    paddingTopImg={0}
                                                                    widthImg='100%'
                                                                    heightImg={100}
                                                                    style={{ height: 200 }}
                                                                    descricao={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                />
                                                            </Box>
                                                            <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Mobile Topo Loja</Typography>
                                                                <UploadImagemDesign
                                                                    dados={imagem}
                                                                    isEditing={true}
                                                                    mobile={true}
                                                                    key={imagem.id}
                                                                    id={imagem.id}
                                                                    idComponent={`bannerTop_mobile_${index}`}
                                                                    dimensaoHeightImg={heightMobile}
                                                                    dimensaoWidthImg={widthMobile}
                                                                    imgData={imagem.url_mobile}
                                                                    onChange={handleOnChangeImagemCarrossel}
                                                                    borderRadiusImg={0}
                                                                    paddingTopImg={0}
                                                                    widthImg='100%'
                                                                    heightImg={100}
                                                                    style={{ height: 200 }}
                                                                    descricao={dimensoesMobileStr}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Grid container spacing={2} style={{ marginTop: 10, paddingLeft: 25 }}>
                                                            <Grid item xs={12}>
                                                                <FormControl>
                                                                    <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                        name="radio-buttons-group"
                                                                        direction="row"
                                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                                        onChange={(e) => {
                                                                            handleChangeDataPageBanners(index, 'carrossel_images', 'filtrarProdutoBy', e.target.value)
                                                                        }}
                                                                    >
                                                                        <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                        <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                        <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                        <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider style={{ height: 2, background: 'gray', opacity: .5, marginTop: 10 }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </CardItem>

                                    <CardItem
                                        borda={true}
                                        title='Banner meio loja'
                                    >
                                        <Box sx={{ width: '100%', marginTop: 3 }}>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    name='bannerTopo'
                                                    id='bannerTopo'
                                                    checked={themeStorageSelected?.banner1?.length > 0 ? themeStorageSelected?.banner1.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                    onChange={() => {
                                                        const chk = themeStorageSelected?.banner1.filter(a => a.type === 'config')[0]?.fullPage;
                                                        handleCheckFullPageBanners('banner1', !chk);
                                                    }}
                                                />
                                                <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>
                                            </Box>
                                            {themeStorageSelected?.banner1 && themeStorageSelected.banner1.filter(a => a.type !== 'config').map((imagem, index) => {
                                                return (
                                                    0 === index &&
                                                    <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                        <Box style={{ display: 'flex' }}>
                                                            <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Web Meio Loja</Typography>
                                                                <UploadImagemDesign
                                                                    dados={imagem}
                                                                    isEditing={true}
                                                                    key={imagem.id}
                                                                    id={imagem.id}
                                                                    idComponent={`bannerMeio_web_${index}`}
                                                                    dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                    dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                    imgData={imagem.url}
                                                                    onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner1')}
                                                                    borderRadiusImg={0}
                                                                    paddingTopImg={0}
                                                                    widthImg='100%'
                                                                    heightImg={100}
                                                                    style={{ height: 200 }}
                                                                    descricao={themeStorageSelected?.banner1?.length > 0 && themeStorageSelected?.banner1.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                />
                                                            </Box>
                                                            <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Mobile Meio Loja</Typography>
                                                                <UploadImagemDesign
                                                                    dados={imagem}
                                                                    isEditing={true}
                                                                    mobile={true}
                                                                    key={imagem.id}
                                                                    id={imagem.id}
                                                                    idComponent={`bannerMeio_mobile_${index}`}
                                                                    dimensaoHeightImg={heightMobile}
                                                                    dimensaoWidthImg={widthMobile}
                                                                    imgData={imagem.url_mobile}
                                                                    onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner1')}
                                                                    borderRadiusImg={0}
                                                                    paddingTopImg={0}
                                                                    widthImg='100%'
                                                                    heightImg={100}
                                                                    style={{ height: 200 }}
                                                                    descricao={dimensoesMobileStr}
                                                                />
                                                            </Box>
                                                        </Box>

                                                        <Grid container spacing={2} style={{ marginTop: 10, paddingLeft: 25 }}>
                                                            <Grid item xs={12}>
                                                                <FormControl>
                                                                    <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                        name="radio-buttons-group"
                                                                        direction="row"
                                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                                        onChange={(e) => {
                                                                            handleChangeDataPageBanners(index, 'banner1', 'filtrarProdutoBy', e.target.value)
                                                                        }}
                                                                    >
                                                                        <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                        <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                        <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                        <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider style={{ height: 2, background: 'gray', opacity: .5, marginTop: 10 }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </CardItem>

                                    <CardItem
                                        borda={true}
                                        title='Banner fim loja'
                                    >
                                        <Box sx={{ width: '100%', marginTop: 3 }}>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    name='bannerTopo'
                                                    id='bannerTopo'
                                                    checked={themeStorageSelected?.banner2?.length > 0 ? themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage : false}
                                                    onChange={() => {
                                                        const chk = themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage;
                                                        handleCheckFullPageBanners('banner2', !chk);
                                                    }}
                                                />
                                                <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>Utilizar banner em tela cheia</Typography>
                                            </Box>
                                            {themeStorageSelected?.banner2 && themeStorageSelected.banner2.filter(a => a.type !== 'config').map((imagem, index) => {
                                                return (
                                                    0 === index &&
                                                    <Box style={{ with: '100%', display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                                        <Box style={{ display: 'flex' }}>
                                                            <Box style={{ width: '58%', marginLeft: 10 }}>
                                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Web Fim Loja</Typography>
                                                                <UploadImagemDesign
                                                                    dados={imagem}
                                                                    isEditing={true}
                                                                    mobile={false}
                                                                    key={imagem.id}
                                                                    id={imagem.id}
                                                                    idComponent={`bannerFim_web_${index}`}
                                                                    dimensaoHeightImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? heightWebFullPage : heightWebPadrao}
                                                                    dimensaoWidthImg={themeStorageSelected?.carrossel_images?.length > 0 && themeStorageSelected?.carrossel_images.filter(a => a.type === 'config')[0]?.fullPage ? widthWebWebFullPage : widthWebPadrao}
                                                                    imgData={imagem.url}
                                                                    onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner2')}
                                                                    borderRadiusImg={0}
                                                                    paddingTopImg={0}
                                                                    widthImg='100%'
                                                                    heightImg={100}
                                                                    style={{ height: 200 }}
                                                                    descricao={themeStorageSelected?.banner2?.length > 0 && themeStorageSelected?.banner2.filter(a => a.type === 'config')[0]?.fullPage ? dimensoesWebFullPageStr : dimensoesWebPadraoStr}
                                                                />
                                                            </Box>
                                                            <Box style={{ width: '38%', marginLeft: 10, marginRight: 20 }}>
                                                                <Typography style={{ fontWeight: 'bold', marginBottom: 5 }}>Banner Mobile Fim Loja</Typography>
                                                                <UploadImagemDesign
                                                                    dados={imagem}
                                                                    isEditing={true}
                                                                    mobile={true}
                                                                    key={imagem.id}
                                                                    id={imagem.id}
                                                                    idComponent={`bannerFim_mobile_${index}`}
                                                                    dimensaoHeightImg={heightMobile}
                                                                    dimensaoWidthImg={widthMobile}
                                                                    imgData={imagem.url_mobile}
                                                                    onChange={(data) => handleOnChangeImagemCarrossel(data, 'banner2')}
                                                                    borderRadiusImg={0}
                                                                    paddingTopImg={0}
                                                                    widthImg='100%'
                                                                    heightImg={100}
                                                                    style={{ height: 200 }}
                                                                    descricao={dimensoesMobileStr}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Grid container spacing={2} style={{ marginTop: 10, paddingLeft: 25 }}>
                                                            <Grid item xs={12}>
                                                                <FormControl>
                                                                    <FormLabel id="demo-radio-buttons-group-label">Filtrar produtos por:</FormLabel>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue={imagem.filtrarProdutoBy || 'nenhum'}
                                                                        name="radio-buttons-group"
                                                                        direction="row"
                                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                                        onChange={(e) => {
                                                                            handleChangeDataPageBanners(index, 'banner2', 'filtrarProdutoBy', e.target.value)
                                                                        }}
                                                                    >
                                                                        <FormControlLabel value="nenhum" control={<Radio />} label="Nenhum" />
                                                                        <FormControlLabel value="promocao" control={<Radio />} label="Promoções" />
                                                                        <FormControlLabel value="liquidacao" control={<Radio />} label="Liquidação" />
                                                                        <FormControlLabel value="lancamento" control={<Radio />} label="Lançamento" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider style={{ height: 2, background: 'gray', opacity: .5, marginTop: 10 }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </CardItem>
                                    {
                                        (themeStorage?.tipoThema === 1 || themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4) &&
                                        <CardItem
                                            borda={true}
                                            title='Banners das Catergorias de destaques'
                                            descricao='Selecione as imagens e escreva o título e selecione a categoria dos banners.'
                                        >
                                            <ExibicaoItens />
                                            {
                                                avisoItensExibicao !== null &&
                                                <AlertError className="msg-error">{avisoItensExibicao}</AlertError>
                                            }
                                        </CardItem>
                                    }
                                </CardItem>

                                <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                                    <CardFinalizar
                                        themeStorage={themeStorage}
                                        voltar={{
                                            etapaExecutada: 2,
                                            etapaAtual: 2
                                        }}
                                        proximo={{
                                            etapaExecutada: 3,
                                            etapaAtual: 4
                                        }}
                                        onClickProximo={() => {
                                            let msg = '';
                                            if (themeStorageSelected?.carrossel_images.filter(a => a.url !== null && a.url?.length > 0).length === 0) {
                                                msg += '\nFavor informar ao menos um banner web para a loja.';
                                            }
                                            if (themeStorageSelected?.carrossel_images.filter(a => a.url_mobile !== null && a.url_mobile?.length > 0).length === 0) {
                                                msg += '\nFavor informar ao menos um banner mobile para a loja';
                                            }

                                            if (themeStorage?.tipoThema === 1 || themeStorage?.tipoThema === 2 || themeStorage?.tipoThema === 4) {
                                                themeStorageSelected.exibicao_itens.filter(a => a.imagem.url !== null && a.categoria_id == "0").forEach(item => {
                                                    setAvisoItensExibicao(`*Favor selecionar uma categoria para o item de exibição ${item.title}`);
                                                    msg += '\nFavor selecionar uma categoria para o item de exibição sem categoria';
                                                });

                                                //if (themeStorageSelected.exibicao_itens.filter(a => a.imagem.url !== null && a.categoria_id == "0").length > 0) { }
                                            }

                                            if (msg.length > 0) {
                                                dispatch({
                                                    type: CONTROLEMENSAGEM_AVISO,
                                                    tipoComponente: 'alert',
                                                    titulo: 'Aviso',
                                                    message: msg
                                                });
                                                return false;
                                            }

                                            const obj = { ...themeStorage };
                                            obj.themes_store[0] = themeStorageSelected;
                                            setStorage('onboardLayout', JSON.stringify(obj));

                                            return true;
                                        }}
                                    />
                                </Box>
                            </Grid>
                        }
                    </>
                }

                {
                    themeStorage?.etapaAtual === 4 &&
                    <Grid item xs={12}>
                        <CardItem
                            etapa={{
                                numero: 4,
                                descricao: 'História da Loja'
                            }}
                            descricao='Selecione a imagem, vídeo e texto da história da loja.'
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <CardItem
                                        borda={true}
                                        title='Banner 1 - Pequeno'
                                    >
                                        <UploadImagemDesign
                                            dados={themeStorageSelected?.aboutUs?.imagem1}
                                            isEditing={true}
                                            mobile={false}
                                            key={themeStorageSelected?.aboutUs?.imagem1.id}
                                            id={themeStorageSelected?.aboutUs?.imagem1.id}
                                            idComponent={`aboutUs1`}
                                            dimensaoWidthImg={740}
                                            dimensaoHeightImg={430}
                                            imgData={themeStorageSelected?.aboutUs?.imagem1.url}
                                            onChange={(value) => handleChangeAboutUs('imagem1', value)}
                                            borderRadiusImg={0}
                                            paddingTopImg={0}
                                            widthImg='100%'
                                            heightImg={250}
                                            style={{ height: 'auto' }}
                                            descricao={'Tamanho máximo 740 x 430'}
                                        />
                                    </CardItem>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <CardItem
                                        borda={true}
                                        title='Banner 2 - Pequeno'
                                    >
                                        <UploadImagemDesign
                                            dados={themeStorageSelected?.aboutUs?.imagem2}
                                            isEditing={true}
                                            mobile={false}
                                            key={themeStorageSelected?.aboutUs?.imagem2.id}
                                            id={themeStorageSelected?.aboutUs?.imagem2.id}
                                            idComponent={`aboutUs2`}
                                            dimensaoWidthImg={740}
                                            dimensaoHeightImg={430}
                                            imgData={themeStorageSelected?.aboutUs?.imagem2.url}
                                            onChange={(value) => handleChangeAboutUs('imagem2', value)}
                                            borderRadiusImg={0}
                                            paddingTopImg={0}
                                            widthImg='100%'
                                            heightImg={250}
                                            style={{ height: 'auto' }}
                                            descricao={'Tamanho máximo 740 x 430'}
                                        />
                                    </CardItem>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        title={'Título'}
                                        style={{ margin: 10, marginLeft: -2 }}
                                        InputLabelProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        value={themeStorageSelected?.aboutUs?.titulo1}
                                        onChange={(e) => {
                                            handleChangeAboutUs('titulo1', e.target.value);
                                        }}
                                        id={`historia-loja-titulo-1`}
                                        label='Título'
                                    />
                                    <Editor
                                        toolbarId='historia-loja-sobre-a-marca'
                                        className='ql-editor2'
                                        text={themeStorageSelected?.aboutUs?.textoExplicativo}
                                        handleChange={(valor) => handleChangeAboutUs('textoExplicativo', valor)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        title={'Título'}
                                        style={{ margin: 10, marginLeft: -2 }}
                                        InputLabelProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        value={themeStorageSelected?.aboutUs?.titulo2}
                                        onChange={(e) => {
                                            handleChangeAboutUs('titulo2', e.target.value);
                                        }}
                                        id={`historia-loja-titulo-2`}
                                        label='Título'
                                    />
                                    <Editor
                                        toolbarId='historia-loja-as-criadoras'
                                        className='ql-editor2'
                                        text={themeStorageSelected?.aboutUs?.textoExplicativo2}
                                        handleChange={(valor) => handleChangeAboutUs('textoExplicativo2', valor)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        title={'Link do vídeo'}
                                        style={{ marginTop: 2, marginLeft: -2 }}
                                        InputLabelProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                fontSize: '14px'
                                            }
                                        }}
                                        value={themeStorageSelected?.aboutUs?.linkVideo}
                                        onChange={(e) => {
                                            handleChangeAboutUs('linkVideo', e.target.value)
                                        }}
                                        id="outlined-basic"
                                        label='Digite o link do vídeo'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CardItem
                                        borda={true}
                                        title='Cor do bloco'
                                    >
                                        <Box style={{ marginTop: 10, marginBottom: 15 }}>
                                            <Typography style={{ fontWeight: 'bold' }}>Cor do card</Typography>
                                            <SketchPicker
                                                color={themeStorageSelected?.aboutUs?.cor1 || '#fffff'}
                                                onChangeComplete={(color) => {
                                                    handleChangeAboutUs('cor1', color.hex);
                                                }}
                                            />
                                        </Box>
                                    </CardItem>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <CardItem
                                        borda={true}
                                        title='Cor do bloco'
                                    >
                                        <Box style={{ marginTop: 10, marginBottom: 15 }}>
                                            <Typography style={{ fontWeight: 'bold' }}>Cor do card</Typography>
                                            <SketchPicker
                                                color={themeStorageSelected?.aboutUs?.cor2 || '#fffff'}
                                                onChangeComplete={(color) => {
                                                    handleChangeAboutUs('cor2', color.hex);
                                                }}
                                            />
                                        </Box>
                                    </CardItem>
                                </Grid>
                            </Grid>
                        </CardItem>

                        <CardFinalizar
                            themeStorage={themeStorage}
                            voltar={{
                                etapaExecutada: 3,
                                etapaAtual: 3
                            }}
                            proximo={{
                                etapaExecutada: 4,
                                etapaAtual: 5
                            }}
                            onClickProximo={() => {
                                const obj = { ...themeStorage };
                                obj.themes_store[0] = themeStorageSelected;
                                setStorage('onboardLayout', JSON.stringify(obj));

                                return true;
                            }}
                            onClickFinalizar={null}
                        />
                    </Grid>
                }

                {
                    themeStorage?.etapaAtual === 5 &&
                    <Grid item xs={12}>
                        <CardItem
                            borda={true}
                            etapa={{
                                numero: 5,
                                descricao: 'Informações da Loja',
                            }}
                        >
                            <CardItem
                                borda={true}
                                title='Endereço da loja'
                                descricao='Este endereço será usado na impressão do pedido e no formulário de contato.'
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={2.2}>
                                        <TextField
                                            variant="outlined"
                                            type='phone'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '90%' }}
                                            label='CEP'
                                            value={themeStorageSelected?.faleConosco?.cep}
                                            onChange={(e) => {
                                                const cep = e.target.value.replace(/([^\d])+/gim, '');
                                                handleChangeEndereco('cep', cep);
                                                handleCep(e);
                                            }}
                                            inputProps={{ maxLength: 8 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '90%' }}
                                            label='Endereço'
                                            value={themeStorageSelected?.faleConosco?.endereco}
                                            onChange={(e) => handleChangeEndereco('endereco', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '90%' }}
                                            label='Bairro'
                                            value={themeStorageSelected?.faleConosco?.bairro}
                                            onChange={(e) => handleChangeEndereco('bairro', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={1.8}>
                                        <TextField
                                            variant="outlined"
                                            type='phone'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '90%' }}
                                            label='Número'
                                            value={themeStorageSelected?.faleConosco?.numero}
                                            onChange={(e) => {
                                                const cep = e.target.value.replace(/([^\d])+/gim, '');
                                                handleChangeEndereco('numero', cep)
                                            }}
                                            inputProps={{ maxLength: 8 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} style={{ marginTop: 10 }}>
                                        <TextField
                                            variant="outlined"
                                            type='text'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '96%' }}
                                            label='Cidade'
                                            value={themeStorageSelected?.faleConosco?.cidade}
                                            onChange={(e) => {
                                                handleChangeEndereco('cidade', e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} style={{ marginTop: 10 }}>
                                        <TextField
                                            variant="outlined"
                                            type='string'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '90%' }}
                                            label='UF'
                                            value={themeStorageSelected?.faleConosco?.uf}
                                            onChange={(e) => {
                                                handleChangeEndereco('uf', e.target.value)
                                            }}
                                            inputProps={{ maxLength: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5} style={{ marginTop: 10 }}>
                                        <TextField
                                            variant="outlined"
                                            type='string'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '96%' }}
                                            label='Latitude'
                                            value={themeStorageSelected?.faleConosco?.latitude}
                                            onChange={(e) => {
                                                handleChangeEndereco('latitude', e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5} style={{ marginTop: 10 }}>
                                        <TextField
                                            variant="outlined"
                                            type='string'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '96%' }}
                                            label='Longitude'
                                            value={themeStorageSelected?.faleConosco?.longitude}
                                            onChange={(e) => {
                                                handleChangeEndereco('longitude', e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 25, marginBottom: 15 }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>Contato</Typography>
                                    </Grid>
                                </Grid>
                            </CardItem>

                            <CardItem
                                borda={true}
                                title='Dados da loja'
                                descricao='Este contato vai ficar disponível na sua loja e com botão flutuante de Whatsapp.'
                            >
                                <Grid container style={{ display: 'flex', width: '100%', marginBottom: 20 }}>
                                    <Grid item xs={5}>
                                        <TextField
                                            variant="outlined"
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            InputProps={{ style: { fontSize: 16 } }}
                                            style={{ width: '97%' }}
                                            label='E-mail'
                                            value={themeStorageSelected?.faleConosco?.contatoEmail}
                                            onChange={(e) => handleChangeEndereco('contatoEmail', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            type='text'
                                            InputLabelProps={{ style: { fontSize: 16 } }}
                                            inputProps={{ style: { fontSize: 16 }, maxLength: 11 }}
                                            style={{ width: '97%' }}
                                            label='WhatsApp'
                                            value={themeStorageSelected?.faleConosco?.contatoWhatsApp}
                                            onChange={(e) => handleChangeEndereco('contatoWhatsApp', e.target.value.replace(/([^\d])+/gim, ''))}
                                        />
                                    </Grid>
                                    <Grid item xs={3} style={{ paddingRight: 6 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Cor botão Whatsapp</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="corBotaoWhastapp"
                                                defaultValue='32f269'
                                                value={themeStorageSelected?.faleConosco?.corBotaoWhatsapp}
                                                label="Cor botão Whatsapp"
                                                onChange={(e) => {
                                                    handleChangeEndereco('corBotaoWhatsapp', e.target.value);
                                                }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                    PaperProps: {
                                                        style: {
                                                            transform: 'scale(1)', // Ajusta o tamanho se necessário
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem value={'#32f269'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#32f269', marginRight: 10 }}></span> Verde</MenuItem>
                                                <MenuItem value={'#000000'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#000000', marginRight: 10 }}></span> Preto</MenuItem>
                                                <MenuItem value={'#f1416c'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#f1416c', marginRight: 10 }}></span> Rosa</MenuItem>
                                                <MenuItem value={'#fe9e40'}><span style={{ borderRadius: 50, width: 14, height: 14, background: '#fe9e40', marginRight: 10 }}></span> Laranja</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </CardItem>

                            <CardItem
                                borda={true}
                                title='Horário de atendimento'
                                descricao='Horário que a loja vai estar disponível para contato.'
                            >
                                <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>
                                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={<ButtonGroup variant="outlined" aria-label="outlined button group" style={{ paddingTop: 10 }}>
                                                <Button variant={empresa?.FlgAbreSeg ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreSeg`, !empresa?.FlgAbreSeg)}>Seg</Button>
                                                <Button variant={empresa?.FlgAbreTer ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreTer`, !empresa?.FlgAbreTer)}>Ter</Button>
                                                <Button variant={empresa?.FlgAbreQua ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreQua`, !empresa?.FlgAbreQua)}>Qua</Button>
                                                <Button variant={empresa?.FlgAbreQui ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreQui`, !empresa?.FlgAbreQui)}>Qui</Button>
                                                <Button variant={empresa?.FlgAbreSex ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreSex`, !empresa?.FlgAbreSex)}>Sex</Button>
                                                <Button variant={empresa?.FlgAbreSab ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreSab`, !empresa?.FlgAbreSab)}>Sáb</Button>
                                                <Button variant={empresa?.FlgAbreDom ? 'contained' : 'outlined'} onClick={() => handleChangeEmpresa(`FlgAbreDom`, !empresa?.FlgAbreDom)}>Dom</Button>
                                            </ButtonGroup>}
                                            label="Dias da semana"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                <TextField
                                                    disabled={!marcouDiaSemana}
                                                    label={'Início'}
                                                    placeholder="08:00"
                                                    style={{ width: '50%' }}
                                                    value={empresa?.HoraInicioSemana}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraInicioSemana`, valor);
                                                    }}
                                                />
                                                <TextField
                                                    disabled={!marcouDiaSemana}
                                                    label={'Fim'}
                                                    placeholder="18:00"
                                                    value={empresa?.HoraFimSemana}
                                                    style={{ width: '50%', marginLeft: 5 }}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraFimSemana`, valor);
                                                    }}
                                                />
                                            </Box>}
                                            label="Horários semana"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                <TextField
                                                    disabled={!empresa?.FlgAbreSab}
                                                    label={'Início'}
                                                    placeholder="08:00"
                                                    style={{ width: '50%' }}
                                                    value={empresa?.HoraInicioSabado}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraInicioSabado`, valor);
                                                    }}
                                                />
                                                <TextField
                                                    disabled={!empresa?.FlgAbreSab}
                                                    label={'Fim'}
                                                    placeholder="18:00"
                                                    value={empresa?.HoraFimSabado}
                                                    style={{ width: '50%', marginLeft: 5 }}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraFimSabado`, valor);
                                                    }}
                                                />
                                            </Box>}
                                            label="Horários sábado"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                <TextField
                                                    disabled={!empresa?.FlgAbreDom}
                                                    label={'Início'}
                                                    placeholder="08:00"
                                                    style={{ width: '50%' }}
                                                    value={empresa?.HoraInicioDomingo}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraInicioDomingo`, valor);
                                                    }}
                                                />
                                                <TextField
                                                    disabled={!empresa?.FlgAbreDom}
                                                    label={'Fim'}
                                                    placeholder="18:00"
                                                    value={empresa?.HoraFimDomingo}
                                                    style={{ width: '50%', marginLeft: 5 }}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraFimDomingo`, valor);
                                                    }}
                                                />
                                            </Box>}
                                            label="Horários Domingo"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                <TextField
                                                    label={'Início'}
                                                    placeholder="08:00"
                                                    style={{ width: '50%' }}
                                                    value={empresa?.HoraInicioFeriado}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraInicioFeriado`, valor);
                                                    }}
                                                />
                                                <TextField
                                                    label={'Fim'}
                                                    placeholder="18:00"
                                                    value={empresa?.HoraFimFeriado}
                                                    style={{ width: '50%', marginLeft: 5 }}
                                                    inputProps={{ maxLength: 5 }}
                                                    onChange={(e) => {
                                                        const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                        handleChangeEmpresa(`HoraFimFeriado`, valor);
                                                    }}
                                                />
                                            </Box>}
                                            label="Horários Feriado"
                                        />
                                    </Grid>
                                </Grid>
                            </CardItem>

                            <CardItem
                                borda={true}
                                title='Meios de pagamento'
                                descricao='Selecione os meios de pagametos aceitos que vão ficar disponível na sua loja'
                            >
                                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {themeStorageSelected?.footer?.formas_pagamento?.map((item) => {
                                        return getIconsPaymentsTypes(item);
                                    })}
                                </Box>
                            </CardItem>

                            <CardItem
                                borda={true}
                                title='Redes Sociais'
                                descricao='Insira o link das suas mídias sociais que vão ficar disponível na sua loja.'
                            >
                                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {themeStorageSelected?.footer?.redes_socias?.map((item) => {
                                        return getIconSocialMedia(item.id);
                                    })}
                                </Box>
                            </CardItem>
                        </CardItem>
                        <CardFinalizar
                            themeStorage={themeStorage}
                            voltar={{
                                etapaExecutada: 4,
                                etapaAtual: 4
                            }}
                            onClickFinalizar={() => {
                                let msg = '';
                                if (themeStorageSelected?.footer?.redes_socias?.filter(a => a.selected).length === 0) {
                                    msg += '\nFavor informar ao menos uma rede social da loja';
                                }
                                if (themeStorageSelected?.footer?.formas_pagamento?.filter(a => a.selected).length === 0) {
                                    msg += '\nFavor informar ao menos uma forma de pagamento para loja';
                                }

                                //Endereço
                                if (!themeStorageSelected?.faleConosco?.cep || (themeStorageSelected?.faleConosco?.cep && themeStorageSelected?.faleConosco?.cep.length < 8)) {
                                    msg += '\nFavor informar o CEP do endereço da loja';
                                }
                                if (!themeStorageSelected?.faleConosco?.endereco || (themeStorageSelected?.faleConosco?.endereco && themeStorageSelected?.faleConosco?.endereco.length === 0)) {
                                    msg += '\nFavor informar o logradouro do endereço da loja';
                                }
                                if (!themeStorageSelected?.faleConosco?.bairro || (themeStorageSelected?.faleConosco?.bairro && themeStorageSelected?.faleConosco?.bairro.length === 0)) {
                                    msg += '\nFavor informar o bairro do endereço da loja';
                                }
                                if (!themeStorageSelected?.faleConosco?.cidade || (themeStorageSelected?.faleConosco?.cidade && themeStorageSelected?.faleConosco?.cidade.length === 0)) {
                                    msg += '\nFavor informar a cidade do endereço da loja';
                                }
                                if (!themeStorageSelected?.faleConosco?.uf || (themeStorageSelected?.faleConosco?.uf && themeStorageSelected?.faleConosco?.uf.length < 2)) {
                                    msg += '\nFavor informar o UF do endereço da loja';
                                }

                                //Contato
                                if (!themeStorageSelected?.faleConosco?.contatoWhatsApp || (themeStorageSelected?.faleConosco?.contatoWhatsApp && themeStorageSelected?.faleConosco?.contatoWhatsApp.length < 11)) {
                                    msg += '\nNa sessão de endereço e contato da loja, favor informar um Whatsapp válido';
                                }
                                if (!themeStorageSelected?.faleConosco?.contatoEmail || (themeStorageSelected?.faleConosco?.contatoEmail && themeStorageSelected?.faleConosco?.contatoEmail.length === 0)) {
                                    msg += '\nFavor informar o e-mail da loja';
                                }

                                if (msg.length > 0) {
                                    dispatch({
                                        type: CONTROLEMENSAGEM_AVISO,
                                        tipoComponente: 'alert',
                                        titulo: 'Aviso',
                                        message: msg
                                    });
                                    return false;
                                }

                                const obj = { ...themeStorage };
                                obj.themes_store[0] = themeStorageSelected;
                                setStorage('onboardLayout', JSON.stringify(obj));

                                handlePublish();
                                return true;
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </Container >
    );
}

export default ConstrutorEcommerce;

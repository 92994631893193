import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { Container } from "./styles";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import * as moment from "moment";
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ImagemSemResultado, salvarCSV, truncate } from "../../../services/utils/functions";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "dayjs/locale/pt";
import { pt } from 'date-fns/locale';
import { getCorByStatusPedido } from "../../../services/utils/statusPedido";


const stautsPedido = [
    { id: 1, nome: 'Em análise' },
    { id: 2, nome: 'Aprovado' },
    { id: 3, nome: 'Encaminhado para entrega' },
    { id: 4, nome: 'Entregue' },
    { id: 6, nome: 'Reprovado' },
    { id: 8, nome: 'Cancelado Vendedor' },
    { id: 9, nome: 'Devolução Solicitada' },
    { id: 10, nome: 'Devolução Confirmada' },
    { id: 11, nome: 'Em Preparação' }
]

const columnsPedidos = [
    { id: "expandir", label: "", minWidth: 50 },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 150,
        align: "center",
    },
    {
        id: "status",
        label: "Status",
        minWidth: 140,
        align: "left",
    },

    { id: "data", label: "Data", minWidth: 180 },
    {
        id: "valor",
        label: "Total",
        maxWidth: 150,
        align: "left",
    },
    { id: "comprador", label: "Comprador", minWidth: 180 },
    { id: "cpfCnpjComprador", label: "CPF/CNPJ Comprador", minWidth: 180 },
    { id: "celularComprador", label: "Celular Comprador", minWidth: 180 },

    { id: "fornecedor", label: "Fornecedor", minWidth: 180 },
    {
        id: "formaPagamento",
        label: "Forma de Pagamento",
        maxWidth: 120,
        align: "left",
    },

    {
        id: "linkPagamentoPix",
        label: "Link de pagamento",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "pedidoCancelado",
        label: "Pedido Cancelado?",
        maxWidth: 150,
        align: "left",
    }
];

const columnsProduto = [
    { id: "id", label: "Id", minWidth: 100 },
    { id: "idProduto", label: "Id Produto", minWidth: 100 },
    {
        id: "nome",
        label: "Nome",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "sku",
        label: "SKU",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "categoria",
        label: "Categoria",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "cor",
        label: "Cor",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "tamanho",
        label: "Tamanho",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "quantidade",
        label: "Quantidade",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "valorPromocional",
        label: "Valor promocional",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "valor",
        label: "Valor",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "total",
        label: "Total",
        maxWidth: 150,
        align: "left",
    },
];

const containerModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 2,
    boxShadow: 24,
    p: 4
};

const Row = ({ row, onBuscarPedido }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [produtos, setProdutos] = useState([]);

    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState(1);
    const [motivoCancelamento, setMotivoCancelamento] = useState('');

    const [openModalStatus, setOpenModalStatus] = useState(false);

    const [openModalLinkPagamento, setOpenModalLinkPagamento] = useState(false);
    const [linkPagamento, setLinkPagamento] = useState('');

    useEffect(() => {
        (async () => {
            if (open && produtos.length === 0) {
                try {
                    setLoading(true);
                    const query = [
                        {
                            "sAndOr": "and",
                            "sCampo": "IdPedidoVesti",
                            "sOrder": "Nome ASC",
                            "sCondicao": "=",
                            "sValorCondicao": row.IdPedidoVesti.toString(),
                            "sTipoCampo": "int"
                        }
                    ];

                    const produtosPedido = await POST_DATA(`PedidoProdutoVesti/FiltroQuery`, query);

                    setProdutos(produtosPedido);
                    setLoading(false);
                }
                catch (err) {
                    setLoading(false);
                    SalvarLogSentry(err);
                }
            }
        })();
    }, [open]);

    const fetchExcel = async () => {
        let prods = produtos;

        if (produtos.length === 0) {
            const query = [
                {
                    "sAndOr": "and",
                    "sCampo": "IdPedidoVesti",
                    "sOrder": "Nome ASC",
                    "sCondicao": "=",
                    "sValorCondicao": row.IdPedidoVesti.toString(),
                    "sTipoCampo": "int"
                }
            ];

            prods = await POST_DATA(`PedidoProdutoVesti/FiltroQuery`, query);
        }

        let dados = `Fornecedor: ${row._FornecedorVesti.Nome} (${row._FornecedorVesti.CNPJ}) - Company: ${row._FornecedorVesti.Company}`;

        dados += `\n\nComprador: ${row._Usuario.Nome} - ${row._Usuario.Cpf}`;
        dados += `\nContato comprador: E-mail ${row._Usuario.Email} - Celualr ${row._Usuario.Celular}`;
        dados += row._EnderecoUsuario ? `\nEndereço comprador: ${row._EnderecoUsuario?.END_Logradouro}, n° ${row._EnderecoUsuario?.END_Numero ? row._EnderecoUsuario?.END_Numero : ''}, bairro ${row._EnderecoUsuario?.END_Bairro}, cidade ${row._EnderecoUsuario?.END_Cidade} - ${row._EnderecoUsuario?.END_Estado} - CEP ${row._EnderecoUsuario?.END_CEP}` : '\nEndereço comprador: Sem dados de endereço';

        dados += `\n\nPedido: ${row.NumeroPedido}`;
        dados += `\nStatus; Data; Fornecedor; Forma de Pagamento; Produtos; Total; Link de pagamento; Motivo Cancelamento`;

        dados += `\n${row._PedidoStatus.Descricao}; ${moment(row.DataCadastro).format('DD/MM/YYYY HH:mm:ss')}; ${row._FornecedorVesti.Nome}; ${row._TipoPagamento.Descricao}; ${row.ValorProdutos.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        })}; ${row.ValorTotal.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        })}; ${row.UrlPagamentoPixVesti ? row.UrlPagamentoPixVesti : '-'}; ${row.MotivoCancelamento ? row.MotivoCancelamento : '-'}`;

        dados += `\n\nProdutos:`;

        dados += `\nId; Id Produto; Nome; SKU; Categoria; Cor; Tamanho; Quantidade; Valor Promocional; Valor; Total`;

        prods?.forEach(produto => {
            dados += `\n${produto.IdPedidoProdutosVesti}; ${produto.IdProduto}; ${produto.Nome};  ${produto.Sku.toString()};  ${produto.Categoria};  ${produto.Cor};  ${produto.Tamanho};  ${produto.Quantidade};  ${produto.ValorPromocional.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })};  ${produto.Valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })}; ${(produto.Quantidade * (produto.ValorPromocional > 0 ? produto.ValorPromocional : produto.Valor)).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            })} `;
        });

        const url = await salvarCSV(row.IdUsuario, 'Pedido.csv', dados);
        window.open(url, '_blank');
    }

    const handleSalvar = async () => {
        try {
            const obj = {
                ...row,
                IdStatusPedido: status,
                MotivoCancelamento: motivoCancelamento
            };

            const res = await PUT_DATA(`PedidoVesti/UpdatePedidoVesti`, obj);

            if (res) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Status do pedido alterado',
                });

                setOpenModalStatus(false);

                setStatus(1);
                setMotivoCancelamento('');

                onBuscarPedido();
            }
        }
        catch (err) {
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Eroo',
                message: `Ocorreu um erro\n${err.message}`,
            });
        }
    }

    const handleSalvarLinkPagamento = async () => {
        try {
            const obj = {
                ...row,
                urlPagamentoPixVesti: linkPagamento
            };

            const res = await PUT_DATA(`PedidoVesti/UpdatePedidoVesti`, obj);

            if (res) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Sucesso',
                    message: 'Link de pagamento vinculado ao pedido',
                });

                setOpenModalStatus(false);

                setLinkPagamento('');

                onBuscarPedido();
            }
        }
        catch (err) {
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Eroo',
                message: `Ocorreu um erro\n${err.message}`,
            });
        }
    }

    return (
        <Fragment key={row.IdPedidoVesti}>
            {
                openModalStatus && <Modal
                    key="modal"
                    open={openModalStatus}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    keepMounted
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            containerModal,
                            {
                                width: 600,
                                height: (status === 6 || status === 8) ? 380 : 220,
                                overflowY: null,
                                borderRadius: 2,
                                backgroundColor: '#ffff',
                            },
                        ]}
                    >
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Alterar Stauts do pedido</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenModalStatus(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 15,
                                    top: 35,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box style={{ width: '100%', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

                            <FormControl style={{ width: 300, marginRight: 1 }}>
                                <InputLabel id="alterar-status-selector">Status</InputLabel>
                                <Select
                                    labelId="alterar-status-selector"
                                    id="alterar-status-selector-id"
                                    value={status}
                                    label="Status"
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setStatus(Number(e.target.value))
                                    }}
                                    MenuProps={{
                                        disablePortal: true,
                                        PaperProps: {
                                            style: {
                                                transform: 'scale(1)', // Ajusta o tamanho se necessário
                                            },
                                        },
                                    }}
                                >
                                    {
                                        stautsPedido.map(item => {
                                            return <MenuItem key={`status-${item.id}`} value={item.id}>{item.nome}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>

                            {
                                (status === 6 || status === 8) &&
                                <>
                                    <Typography style={{ marginTop: 15 }}>Motivo cancelamento/reprovação</Typography>
                                    <TextField multiline rows={3} value={motivoCancelamento} onChange={(e) => setMotivoCancelamento(e.target.value)} style={{ marginTop: 10 }} />
                                </>
                            }

                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ width: 220, marginTop: 20 }} variant="contained" onClick={() => handleSalvar()}>Salvar</Button>
                            </Box>
                        </Box>

                    </Box>

                </Modal>
            }

            {
                openModalLinkPagamento && <Modal
                    key="modal"
                    open={openModalLinkPagamento}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    keepMounted
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            containerModal,
                            {
                                width: 600,
                                height: 350,
                                overflowY: null,
                                borderRadius: 2,
                                backgroundColor: '#ffff',
                            },
                        ]}
                    >
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Typography style={{ fontWeight: 'bold' }}>Incluir link para pagamento do pedido</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenModalLinkPagamento(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 15,
                                    top: 35,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box style={{ width: '100%', marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            <Typography style={{ marginTop: 15 }}>Colar link abaixo</Typography>
                            <TextField multiline rows={4} value={linkPagamento} onChange={(e) => setLinkPagamento(e.target.value)} style={{ marginTop: 10 }} />

                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ width: 220, marginTop: 20 }} variant="contained" onClick={() => handleSalvarLinkPagamento()}>Salvar</Button>
                            </Box>
                        </Box>

                    </Box>

                </Modal>
            }

            <TableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell key={`acoes-${row.IdPedidoVesti}`} align='left'>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <span title="Vincular link de pagamento">
                            <AttachMoneyIcon style={{ fontSize: 28, cursor: 'pointer', marginRight: 5 }} onClick={() => {
                                setLinkPagamento(row.UrlPagamentoPixVesti);
                                setOpenModalLinkPagamento(true);
                            }} />
                        </span>
                        <span title="Alterar status">
                            <PublishedWithChangesIcon style={{ fontSize: 28, cursor: 'pointer', marginRight: 10 }} onClick={() => {
                                setStatus(row.IdStatusPedido);
                                setOpenModalStatus(true);
                            }} />
                        </span>
                        <Button
                            size="small"
                            variant="contained"
                            style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                            onClick={() => fetchExcel()}
                        >
                            <FileDownloadIcon />
                            CSV
                        </Button>
                    </Box>
                </TableCell>

                <TableCell key={`status-${row.IdPedidoVesti}`} align='left'>
                    <Typography style={{ padding: 2, paddingLeft: 8, paddingRight: 8, color: 'black', background: getCorByStatusPedido(row._PedidoStatus.Descricao), borderRadius: 10, border: '0.5px solid #e4e4e4', textAlign: 'center', fontWeight: 'bold' }}>{row._PedidoStatus.Descricao}</Typography>
                </TableCell>
                <TableCell key={`data-${row.IdPedidoVesti}`} align='left'>
                    {moment(row.DataCadastro).format('DD/MM/YYYY HH:mm:ss')}
                </TableCell>
                <TableCell key={`valor-total-${row.IdPedidoVesti}`} align='left'>
                    {row.ValorTotal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </TableCell>

                <TableCell key={`nome-comprador-${row.IdPedidoVesti}`} align='left'>
                    {row._Usuario.Nome}
                </TableCell>
                <TableCell key={`cpf-comprador-${row.IdPedidoVesti}`} align='left'>
                    {row._Usuario.Cpf}
                </TableCell>
                <TableCell key={`celular-comprador-${row.IdPedidoVesti}`} align='left'>
                    {row._Usuario.Celular}
                </TableCell>
                <TableCell key={`nome-fornecedor-${row.IdPedidoVesti}`} align='left'>
                    {row._FornecedorVesti.Nome}
                </TableCell>

                <TableCell key={`tipo-pagamento-${row.IdPedidoVesti}`} align='left'>
                    {row._TipoPagamento.Descricao}
                </TableCell>
                <TableCell key={`urlPagamento_pedido-${row.IdPedidoVesti}`} align='left'>
                    {row.UrlPagamentoPixVesti ? <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>{truncate(row.UrlPagamentoPixVesti, 12)}</Typography>
                        <span title="Copiar link de pagamento do pedido">
                            <QrCodeIcon style={{ marginLeft: 10, fontSize: 25, cursor: 'pointer' }} onClick={() => {
                                navigator.clipboard.writeText(row.UrlPagamentoPixVesti);
                                dispatch({
                                    type: CONTROLEMENSAGEM_SUCESSO,
                                    tipoComponente: 'alert',
                                    titulo: 'Sucesso',
                                    message: 'Link de pagamento do pedido cópiado com sucesso',
                                });
                            }} />
                        </span>
                    </Box> : "-"}
                </TableCell>
                <TableCell key={`motivoCancelamento_pedido-${row.IdPedidoVesti}`} align='left'>
                    {row.MotivoCancelamento ? row.MotivoCancelamento : "-"}
                </TableCell>
            </TableRow>

            {
                open && loading && <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`itens-pedido-${row.IdPedidoVesti}`}
                    rowspan={1}
                >
                    <TableCell colSpan={columnsPedidos.length} key={`pedidos-${row.IdPedidoVesti}`}>
                        <Stack
                            sx={{ height: "150px" }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            }

            {
                open && !loading && produtos.length === 0 && <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`itens-pedido-${row.IdPedidoVesti}`}
                    rowspan={1}
                >
                    <TableCell colSpan={columnsPedidos.length} key={`pedidos-${row.IdPedidoVesti}`}>
                        <Stack
                            sx={{ width: "100%", minHeight: "300px", p: 2 }}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                            <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                Nenhum produto encontrado.
                            </Typography>
                        </Stack>
                    </TableCell>
                </TableRow>
            }

            {
                open && !loading && produtos.length > 0 &&
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`itens-pedido-${row.IdPedidoVesti}`}
                    rowspan={1}
                >
                    <TableCell colSpan={columnsPedidos.length} key={`pedidos-${row.IdPedidoVesti}`} align='left'>
                        <TableContainer style={{ width: '100%' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columnsProduto.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {produtos.map((roww) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={`produto-${roww.IdPedidoProdutosVesti}`}
                                            >
                                                <TableCell key={`id-${roww.IdPedidoProdutosVesti}`} align='left'>
                                                    {roww.IdPedidoProdutosVesti}
                                                </TableCell>
                                                <TableCell key={`id-${roww.IdPedidoProdutosVesti}`} align='left'>
                                                    {roww.IdProduto}
                                                </TableCell>
                                                <TableCell key={`nome-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.Nome}
                                                </TableCell>
                                                <TableCell key={`sku-${roww.IdPedidoProdutosVesti}`} align='left'>
                                                    {roww.Sku}
                                                </TableCell>
                                                <TableCell key={`categoria-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.Categoria}
                                                </TableCell>
                                                <TableCell key={`cor-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.Cor}
                                                </TableCell>
                                                <TableCell key={`tamanho-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.Tamanho}
                                                </TableCell>
                                                <TableCell key={`quantidade-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.Quantidade}
                                                </TableCell>
                                                <TableCell key={`valor-promocional-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.ValorPromocional.toLocaleString("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    })}
                                                </TableCell>
                                                <TableCell key={`valor-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {roww.Valor.toLocaleString("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    })}
                                                </TableCell>
                                                <TableCell key={`valor-${roww.IdPedidoProdutosVesti}`} align='left' style={{ minWidth: 140 }}>
                                                    {(roww.Quantidade * (roww.ValorPromocional > 0 ? roww.ValorPromocional : roww.Valor)).toLocaleString("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    );
};


const LeapVestiPedidos = () => {
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    const [pedidos, setPedidos] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            await fecthPedidos();
        })();

    }, []);


    const fecthPedidos = async () => {
        try {
            setLoading(true);
            const query = [
                {
                    "sAndOr": "and",
                    "sCampo": "PV.FlgAtivo",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": "=",
                    "sValorCondicao": 1,
                    "sTipoCampo": "bool"
                }
            ];

            if (status > 0) {
                query.push({
                    "sAndOr": "and",
                    "sCampo": "IdStatusPedido",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": "=",
                    "sValorCondicao": status,
                    "sTipoCampo": "int"
                })
            }

            if (dataInicio !== null) {
                query.push({
                    "sAndOr": "and",
                    "sCampo": "DataCadastro",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": ">=",
                    "sValorCondicao": moment(dataInicio).format('YYYY-MM-DD'),
                    "sTipoCampo": "datetime"
                })
            }

            if (dataFim !== null) {
                query.push({
                    "sAndOr": "and",
                    "sCampo": "DataCadastro",
                    "sOrder": "DataCadastro DESC",
                    "sCondicao": "<=",
                    "sValorCondicao": moment(dataFim).add(1, 'days').format('YYYY-MM-DD'),
                    "sTipoCampo": "datetime"
                })
            }

            const pedidos = await POST_DATA(`PedidoVesti/GetAllPedidosVestiByFiltroQuery`, query);

            if (search.length > 0) {
                const pedidoByFornecedor = pedidos.filter(a => a._FornecedorVesti.Nome.trim().toUpperCase().includes(search.trim().toLocaleUpperCase()));

                setPedidos(pedidoByFornecedor);
                setRows(pedidoByFornecedor);
            }
            else {
                setPedidos(pedidos);
                setRows(pedidos);
            }

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            SalvarLogSentry(err);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return <Container>
        <Box style={{ width: "100%" }}>
            <Typography style={{ fontWeight: 'bold' }}>Atacado / Pedidos</Typography>
        </Box>
        <Stack
            direction="row"
            sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-end' }}
            justifyContent="space-between"
        >
            <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel style={{ fontWeight: 'bold' }} id="dataInicio">Data de início</InputLabel>
                    <DatePicker
                        id='dataInicio'
                        format="dd/MM/yyyy"
                        value={dataInicio}
                        onChange={(newValue) => setDataInicio(newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ width: 180, marginRight: 1 }}
                    />

                </Box>

                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel style={{ fontWeight: 'bold' }} id="dataFim">Data de fim</InputLabel>
                    <DatePicker
                        id='dataFim'
                        format="dd/MM/yyyy"
                        value={dataFim}
                        onChange={(newValue) => setDataFim(newValue)}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ width: 180, marginRight: 1 }}
                    />
                </Box>

                <FormControl style={{ width: 150, marginRight: 1 }}>
                    <InputLabel id="status-selector">Status</InputLabel>
                    <Select
                        labelId="status-selector"
                        id="status-selector-id"
                        value={status}
                        label="Status"
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            setStatus(Number(e.target.value))
                        }}
                        MenuProps={{
                            disablePortal: true,
                            PaperProps: {
                                style: {
                                    transform: 'scale(1)', // Ajusta o tamanho se necessário
                                },
                            },
                        }}
                    >
                        <MenuItem value={0}>Todos</MenuItem>
                        {
                            stautsPedido.map(item => {
                                return <MenuItem key={`status-${item.id}`} value={item.id}>{item.nome}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <Box>
                    <Button style={{ marginLeft: 10 }} variant="contained" onClick={async () => await fecthPedidos()}>Buscar</Button>
                </Box>

            </LocalizationProvider>

        </Stack>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading ? (
                <Stack
                    sx={{ height: "150px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                </Stack>
            ) : (
                <>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnsPedidos.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <Row key={i} row={row} onBuscarPedido={async () => await fecthPedidos()} />
                                ))}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={
                            search
                                ? `${rows.length}/${pedidos.length} resultados encontrados para pesquisa "${search}" - pedidosFornecedor por página: `
                                : "pedidos por página"
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
    </Container>;
}

export default LeapVestiPedidos;
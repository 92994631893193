import { Fragment, useEffect, useState } from "react";
import { Container } from "./styles";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { ImagemSemResultado, salvarCSV } from "../../../services/utils/functions";
import { POPOVER_OPEN } from "../../../store/PopoverReducer/types";
import ModalEmail from "../../../components/ModalEmail";
import { Avatar, Box, Button, CircularProgress, FormControl, MenuItem, Paper, Select, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import PeriodPicker from "../../../components/PeriodPicker";
import * as moment from "moment";

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const columns = [
    { id: "orgigem", label: "Origem", minWidth: 150, maxWidth: 170 },
    { id: "nome", label: "Nome", minWidth: 150, maxWidth: 170 },
    { id: "email", label: "E-mail", minWidth: 150, maxWidth: 170 },
    { id: "dataEvento", label: "Data evento", minWidth: 150, maxWidth: 170 },
    { id: "flagNotificado", label: "Notificado", minWidth: 150, maxWidth: 170 },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 200,
        align: "center",
    },
];

const NewsLetter = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const orderList = useSelector((state) => state.ordenacaoDashboard);

    const [loading, setLoading] = useState(false);
    const [newsLetter, setNewsLetter] = useState([]);
    const [anchorEl, setaAchorEl] = useState(null);

    const [origem, setOrigem] = useState(0);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    useEffect(() => {
        (async () => {
            if (userLoggedIn === null) return;
            await fetchNewsletter();
        })();
    }, [userLoggedIn, origem, orderList.period]);

    useEffect(() => {
        const tmpSolicitacao = newsLetter?.map(item => {
            return <Row item={item} />
        });

        setRows(tmpSolicitacao || []);
    }, [newsLetter]);

    const fetchNewsletter = async () => {
        try {
            setLoading(true);

            const result = await POST_DATA(`NewsLetter/GetListNewsLetterFilter?idUsuarioEmpresa=${userLoggedIn.IdUsuarioEmpresa}&idOrigem=${origem}&dtInicio=${orderList.period.startDate}&dtFim=${orderList.period.endDate}`);
            setNewsLetter(result);

            setLoading(false);
        }
        catch (err) {
            SalvarLogSentry(err);
            setLoading(false);
        }
    }

    const fetchExcel = async () => {
        if (newsLetter === null || newsLetter.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Origem; Nome; Email; Data evento;`;
        newsLetter?.forEach(item => {
            dados += `\n${item.IdOrigemNewsletter === 1 ? 'Novidades' : 'Checkout'}; ${item.Nome}; ${item.Email}; ${moment(item.DataInclusao).format('DD/MM/YYYY')}`;
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'ListaNewsletter.csv', dados);
        window.open(url, '_blank');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleNotificar = async (item) => {
        const aviseMe = { ...item, FlagNotificado: !item.FlagNotificado };
        const result = await PUT_DATA(`Newsletter/CadastroNewsletter`, aviseMe);

        if (result) {
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: `Sucesso ao executar a ação.`,
            });

            await fetchNewsletter();
        }
    }

    const Row = ({ item }) => {
        return <Fragment key={item.IdAviseMeProduto}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 100 }}>{item.IdOrigemNewsletter === 1 ? "Novidades" : "Checkout"}</TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>{item.Nome}</TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>{item.Email}</TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    {moment(item?.DataInclusao).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                    <Switch
                        checked={item.FlagNotificado}
                        onChange={async () => {
                            await handleNotificar(item);
                        }}
                    />
                </TableCell>
                <TableCell align="right" style={{ minWidth: 100 }}>
                    <Button
                        size="small"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmailComAnexo',
                                    idItem: 'poperModalEmailComAnexo',
                                    open: true,
                                    data: {
                                        nomePessoa: item.Nome,
                                        emailCliente: item.Email
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <LocalOfferIcon style={{ fontSize: 16 }} />
                    </Button>
                    <Button
                        size="small"
                        title="Enviar mala direta"
                        variant="contained"
                        style={{ height: '100%', paddingLeft: 10, paddingRight: 10, fontSize: 14, marginLeft: 10 }}
                        onClick={(e) => {
                            setaAchorEl(e.currentTarget);

                            setTimeout(() => {
                                dispatch({
                                    type: POPOVER_OPEN,
                                    idPopover: 'poperModalEmail',
                                    idItem: 'poperModalEmail',
                                    open: true,
                                    data: {
                                        nomePessoa: item.Nome,
                                        emailCliente: item.Email
                                    }
                                });
                            }, 200);
                        }}
                    >
                        <EmailIcon style={{ fontSize: 16 }} />
                    </Button>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    return <Container>
        <ModalEmail idPopover='poperModalEmail' anchorEl={anchorEl} />
        <ModalEmail idPopover='poperModalEmailComAnexo' anchorEl={anchorEl} possuiAnexo={true} />

        <Box sx={{ width: "100%" }}>
            <Box style={{ width: "100%" }}>
                <Typography style={{ fontWeight: 'bold' }}>Relatórios / Leads</Typography>
            </Box>
            <Box style={{ width: "100%", marginTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
                <Box>
                    <Typography style={{ fontSize: 12, marginBottom: 7 }}>Origem:</Typography>
                    <FormControl sx={{ width: 200, marginRight: 1 }}>
                        <Select
                            labelId="origem"
                            id={`origem`}
                            value={origem}
                            style={{ height: 43 }}
                            onChange={(e) => {
                                setOrigem(Number(e.target.value));
                            }}
                            MenuProps={{
                                disablePortal: true,
                                PaperProps: {
                                    style: {
                                        transform: 'scale(1)', // Ajusta o tamanho se necessário
                                    },
                                },
                            }}
                        >
                            <MenuItem style={{ fontSize: 14 }} value={0}>Ambos</MenuItem>
                            <MenuItem style={{ fontSize: 14 }} value={1}>Novidades</MenuItem>
                            <MenuItem style={{ fontSize: 14 }} value={2}>Checkout</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <PeriodPicker />
            </Box>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading ? (
                <Stack
                    sx={{ height: "150px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando avaliações</Typography>
                </Stack>
            ) : (
                <>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(row => {
                                        return row;
                                    })}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={newsLetter.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={"Solicitações"}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
        <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                size="small"
                variant="contained"
                style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                onClick={() => fetchExcel()}
            >
                <FileDownloadIcon />
                Excel
            </Button>
        </Box>
    </Container>
}

export default NewsLetter;
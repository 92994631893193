import { Box, Typography } from '@mui/material';
import { Container } from './styles';
import OpcoesFormasPagamento from './opcoesFormasPagamento';
import { useSelector } from 'react-redux';
import { handleTrack } from '../../../services/API';
import { useEffect } from 'react';

const FormasPagamento = () => {
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    useEffect(() => {
        if(userLoggedIn === null) return;

        (async () => {
            await handleTrack(userLoggedIn.IdUsuario, 'FormasPagamento', `Carregando tela de Formas de Pagamento`);
        })();
    }, [userLoggedIn]);

    return (
        <Container>
            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Configuração / Formas de pagamento</Typography>
                    <Typography style={{ fontSize: 14, color: 'gray' }}>Observação: Você só pode ter uma integração finaceira por vez</Typography>
                </Box>
            </Box>
            <OpcoesFormasPagamento />
        </Container>
    )
}

export default FormasPagamento;

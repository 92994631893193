import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';

import imgErro from '../../assets/image/imgPageError.jpeg';
import imgErro2 from '../../assets/image/error2.webp';
import GoWhatsApp from '../../components/GoWhatsApp';
import { Box, styled } from '@mui/material';
import { Button } from "@mui/material";

const Container = styled(Box)`
    background: white;
    width: 100%;
    height: 100vh;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const PageError = () => {
    const location = useLocation();
    const [passouMousePorCima, setPassouMousePorCima] = useState(false);

    const [dados, setDados] = useState({
        message: '1777 - Oi, houve um erro na operação que você solicitou. Tente novamente ou fale com o suporte abaixo.',
        showButton: true
    });

    useEffect(() => {
        setDados({ ...dados, ...location.state });
    }, [location]);

    return (
        <Container>
            {
                dados?.error404 &&
                <img style={{ width: 450 }} src={imgErro2} alt='Error 404' />
            }
            {
                !dados?.error404 &&
                <img style={{ width: 450 }} src={imgErro} alt='Page não encontrada' />
            }


            <h3 style={{ fontWeight: 0, marginTop: 20, fontSize: 22, maxWidth: 800, fontWeight: 550, textAlign: 'center', marginBottom: 35  }}>{dados?.message}</h3>
            <span
                onMouseMove={() => setPassouMousePorCima(true)}
                onMouseLeave={() => setPassouMousePorCima(false)}
            >
                <GoWhatsApp whatsNumber='11976548907' fixoLocal={true} passouMousePorCima={passouMousePorCima} />
            </span>

            <Button style={{ background: 'black', color: 'white', marginLeft: -13 }} variant='contained' onClick={() => window.location.href= "/" }>Recarregar</Button>

        </Container>
    )
}

export default PageError;

import { Alert, Box, Button, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Modal, Typography } from "@mui/material"
import { Container, ModalContainer } from "./styles"

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { atualizarOnboardStore } from "../../../services/utils/functions";
import { GET_DATA_DESIGN, handleTrack, POST_AUTH, POST_DATA, PUT_DATA, SalvarLogEventoSentry, SalvarLogSentry } from "../../../services/API";
import useDialogConfirmationAsync from "../../../components/DialogConfirmation/DialogConfirmationAsync";
import LoadingAllPage from "../../../components/LoadingAllPage";

import EditIcon from '@mui/icons-material/Edit';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import * as moment from "moment";
import { isMobile } from "../../../services/DeviceServices";

const OnBoardMobile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onBoard = useSelector((state) => state.onBoard);
    const userLogeedIn = useSelector((state) => state.usuarioLogado);
    const [getConfirmation, Confirmation] = useDialogConfirmationAsync();
    const [dadosOnBoard, setDadosOnBoard] = useState(null);
    const [openModalFinalizado, setOpenFinalizado] = useState(false);
    const [possuiLayoutConfigurado, setPossuiLayoutConfigurado] = useState(false);

    const [mostrarObservacao, setMostrarObservacao] = useState(-1);

    const [verificandoLoadingInicial, setVerificandoLoadingInicial] = useState(false);

    const [dominio, setDominio] = useState({
        IdDominioLEAP: 0,
        IdUsuarioEmpresa: 0,
        IdProvedor: 0,
        IpServidor: '',
        NomeSiteLeap: '',
        NomeSiteDominioGeral: '',
        DataCriacao: '',
        DataAtualizacao: null,
        FlagAtivo: true
    });

    useEffect(() => {
        try {
            if (userLogeedIn === null) return;

            setDadosOnBoard(onBoard);

            (async () => {
                setVerificandoLoadingInicial(true);

                await handleTrack(userLogeedIn.IdUsuario, 'OnboardMobile', `Acessou a tela de Onboard`);

                //Onboard inicial
                const onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${userLogeedIn.IdUsuario}`);
                //const onboardDesignSiteResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=2&idUsuario=${userLogeedIn.IdUsuario}`);

                if (onboardLojistaResult.totalPerguntaRespondida < onboardLojistaResult.totalPergunta) {
                    navigate('/onboard-lojista');
                }
                // else if (onboardDesignSiteResult.totalPerguntaRespondida < onboardDesignSiteResult.totalPergunta) {
                //     navigate('/on-board-design-site');
                // }

                setVerificandoLoadingInicial(false);

                const obj = {
                    userName: process.env.REACT_APP_LOGIN_DESIGN,
                    password: process.env.REACT_APP_SENHA_DESIGN
                };

                const login = await POST_AUTH(obj);
                const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLogeedIn.IdUsuario}`, login.token);
                setPossuiLayoutConfigurado(construtorSite.data.id > 0);

                if (onBoard?.FlagCompletado) {
                    const onboardResult = await POST_DATA(`OnboardingStore/GetAllOnboardingStoreByValorExato?strValorExato=${userLogeedIn.IdUsuarioEmpresa}&ColunaParaValorExato=OS.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=OS.IdOnboardingStore&Log=`);
                    if (!onboardResult[0].FlagCompletado) {
                        atualizarOnboardStore(onBoard, null);
                    }

                    setOpenFinalizado(true);
                }

                const result = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${userLogeedIn?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
                if (result !== null && result.length > 0) {
                    setDominio(result[0]);
                }
            })();
        }
        catch (err) {
            SalvarLogSentry(err);
            setVerificandoLoadingInicial(false);
        }

    }, [userLogeedIn]);

    const handleClick = async (botao) => {
        await handleTrack(userLogeedIn.IdUsuario, 'OnboardMobile', `Clicou no botão: ${botao.OnboardingStoreItem.sDescricaoBotao}`);

        if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus && botao.OnboardingStoreItem.sLink !== '/configurar-ecommerce') {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                titulo: 'Aviso',
                message: 'Favor configurar o primeiro item (Personalizar o design) para poder prosseguir para os demais itens',
                tipoComponente: 'alert'
            });

            return;
        }

        navigate(botao.OnboardingStoreItem.sLink);
    }

    const getBotao = (index, item, dadosOnBoard) => {
        if (index === 0) {
            return <Button style={{ fontSize: 8, marginLeft: 10, marginTop: 5 }} onClick={() => {
                SalvarLogEventoSentry('OnboardMobile => Clicou configurar loja');
                handleClick(item);
            }} variant='contained'>Configurar</Button>
        }
        else if (index === 2) {
            return <Button style={{ fontSize: 8, marginLeft: 10, marginTop: 5 }} onClick={() => {
                handleClick(item);
            }} variant='contained'>Configurar</Button>
        }
        else {
            return <Button style={{ fontSize: 8, marginLeft: 10, marginTop: 5 }} onClick={() => {
                if (item.OnboardingStoreItem.IdOnboardingStoreItem === 3) {
                    SalvarLogEventoSentry('OnboardMobile => Clicou desing site');
                }

                handleClick(item);
            }} variant='contained'>Configurar</Button>
        }
    }

    try {
        return verificandoLoadingInicial ? <LoadingAllPage text="Buscando dados..." /> : (
            <Container>
                {
                    openModalFinalizado &&
                    <Modal
                        open={openModalFinalizado}
                        onClose={() => setOpenFinalizado(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <ModalContainer>
                            <CheckCircleRoundedIcon style={{ color: '#f1416d', width: 250, height: 250 }} />
                            <Typography style={{ fontSize: 20, fontWeight: 'bold', marginTop: 50, marginBottom: 10, textAlign: 'center' }}>Obrigado por finalizar as configurações básicas da loja</Typography>
                            <Button style={{ marginBottom: 10 }} onClick={() => {
                                setOpenFinalizado();
                            }} variant="text">Fechar</Button>
                            <Button style={{ marginBottom: 10 }} onClick={() => {
                                setOpenFinalizado();
                                navigate('/');
                            }} variant="contained">Ir para o dashboard</Button>
                        </ModalContainer>
                    </Modal>
                }

                <Confirmation title="Aviso" mensagem="Você deseja falar com o suporte ?"
                    btn1={{ title: "Fechar", onClick: null }}
                    btn2={{
                        title: "Abrir chat", onClick: () => {
                            const link = `https://wa.me/11976548907?text=${encodeURIComponent(`Olá, a loja ${userLogeedIn.NomeFantasia} (${userLogeedIn.IdUsuario}) precisa de ajuda`)}`;
                            window.open(link, '_blank');
                        }
                    }}
                />

                <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Typography style={{ fontSize: 15, fontFamily: 'Arial', color: '#181818', fontWeight: 'bold' }}>Bem vindo ao painel de controle</Typography>
                    {/* <span style={{ marginLeft: 20, background: '#6cbf6c', color: 'white', borderRadius: '50%', height: '50px', width: '50px', fontWeight: 'bold', fontSize: 13, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="text"
                        onClick={() => getConfirmation('Teste')}
                    >
                        S.O.S
                    </span> */}
                </Box>

                <Card style={{ padding: 10, borderRadius: 10, border: '1px solid #e2e2e2f' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={5} md={2.7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography color={'primary'} style={{ fontSize: 12, fontFamily: 'Arial', fontWeight: 'bold' }}>Olá, {userLogeedIn?.NomeFantasia}</Typography>
                        </Grid>
                        <Grid item xs={0.2}>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid item xs={5} md={2.7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography style={{ fontSize: isMobile() ? 12 : 15, fontFamily: 'Arial', color: '#181818', fontWeight: 'bold' }}>Plano {userLogeedIn?.PlanoEmpresa?.Descricao}</Typography>
                            <Button variant="text" style={{ fontSize: 10, fontFamily: 'Arial', fontWeight: 400 }} onClick={() => navigate('/assinatura')}>Ver planos</Button>
                        </Grid>

                        <Grid item xs={5} md={2.7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 15, fontFamily: 'Arial', color: '#181818', fontWeight: 'bold' }}>Domínio</Typography>
                            <Button onClick={() => navigate('/config-dominio-leap')} variant="text" style={{ fontSize: 10, fontFamily: 'Arial', fontWeight: 400 }}>{dominio !== null && dominio.NomeSiteDominioGeral !== '' ? dominio.NomeSiteDominioGeral : 'Configurar'}</Button>
                        </Grid>
                        <Grid item xs={0.2}>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid item xs={5} md={2.7} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <EditIcon />
                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 15, fontFamily: 'Arial', color: '#181818', fontWeight: 'bold' }}>Site</Typography>
                                <Button onClick={() => navigate('/configurar-ecommerce')} variant="text" style={{ fontSize: 10, fontFamily: 'Arial', fontWeight: 400 }}>Editar</Button>
                            </Box>
                        </Grid>
                    </Grid>

                </Card>

                <Card style={{ marginTop: 20, borderRadius: 10, border: '1px solid #e2e2e2f' }}>
                    <CardContent style={{ paddingRight: 0, paddingLeft: 10, paddingBottom: 0, paddingTop: 10 }}>
                        <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>Passos da configuração</Typography>
                        <Typography style={{ fontSize: 10, fontFamily: 'Arial', fontWeight: 'bold', color: '#707070', marginTop: 5 }}>Siga o passo a passo para configurar sua loja</Typography>

                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 25, marginBottom: 15, dipslay: 'flex', justifyContent: 'space-between' }}>
                            <Box style={{ width: '100%', paddingRight: 10 }}>
                                <LinearProgress
                                    sx={{ width: '100%', maxWidth: 600, height: 15, borderRadius: 10 }}
                                    variant="buffer"
                                    valueBuffer={100}
                                    value={((dadosOnBoard?.ListOnboardingStorexOnboardingStoreItem?.filter(a => a.FlagStatus)?.length * 100) / dadosOnBoard?.ListOnboardingStorexOnboardingStoreItem?.length)}
                                    color="primary"
                                />
                            </Box>
                            <Typography style={{ width: 280, marginTop: 10, fontSize: 10, fontFamily: 'Arial', marginLeft: 10, color: '#181818', fontWeight: 'bold' }}>Você concluiu {dadosOnBoard?.ListOnboardingStorexOnboardingStoreItem?.filter(a => a.FlagStatus)?.length} de {dadosOnBoard?.ListOnboardingStorexOnboardingStoreItem?.length} passos</Typography>
                        </Box>

                        {
                            dadosOnBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.OnboardingStoreItem.IdOnboardingStoreItem === 3 && a.FlagStatus).length > 0 &&
                            <Box style={{ paddingRight: 10 }}>
                                <Alert severity="warning">
                                    <Typography style={{ fontWeight: 'bold' }}>Qual é a próxima etapa?</Typography>
                                    <Typography style={{ fontSize: 11, marginTop: 5 }}>Acesse sua conta Leap no desktop para uma experiência completa.</Typography>
                                </Alert>
                            </Box>
                        }

                        <Grid container spacing={2} style={{ marginTop: 1 }}>
                            <Grid item xs={12}>
                                {
                                    dadosOnBoard?.ListOnboardingStorexOnboardingStoreItem?.filter(a => a.OnboardingStoreItem.IdOnboardingStoreItem === 1 || a.OnboardingStoreItem.IdOnboardingStoreItem === 3)?.sort((a, b) => a.OnboardingStoreItem.iOrdem - b.OnboardingStoreItem.iOrdem).map((item, index) => {
                                        return (
                                            <Timeline key={index} sx={{
                                                [`& .${timelineItemClasses.root}:before`]: {
                                                    flex: 0,
                                                    padding: 0,
                                                },
                                            }}>
                                                <TimelineItem style={{ marginTop: index !== 0 ? -25 : 0 }}>
                                                    <TimelineSeparator style={{ height: 75 }}>
                                                        {
                                                            !item.FlagStatus &&
                                                            <RadioButtonUncheckedIcon style={{ width: 40, height: 40 }} />
                                                        }
                                                        {
                                                            item.FlagStatus &&
                                                            <CheckCircleOutlinedIcon style={{ width: 40, height: 40, color: '#e048a3' }} />
                                                        }
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ marginTop: 0, cursor: 'pointer' }} onMouseEnter={() => setMostrarObservacao(index)} onMouseLeave={() => setMostrarObservacao(-1)}>
                                                        {
                                                            (item.OnboardingStoreItem.iOrdem === 1 || item.OnboardingStoreItem.iOrdem === 2) &&
                                                            <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                <Typography variant="h6" component="span" style={{ fontFamily: 'Arial', fontSize: 14, fontWeight: 'bold', color: item.FlagStatus ? '#707070' : 'r#181818' }}>
                                                                    <span style={{ textDecoration: item.FlagStatus ? 'line-through' : null }}>*{item?.OnboardingStoreItem?.sNome}</span> <span style={{ fontSize: 12 }}>(Item obrigatório)</span>
                                                                </Typography>
                                                                {
                                                                    mostrarObservacao === index &&
                                                                    <Box style={{ marginTop: 5 }}>
                                                                        <Typography style={{ fontFamily: 'Arial', fontSize: 12, fontWeight: 450, color: '#707070' }}>{item.OnboardingStoreItem.sDescricao}</Typography>
                                                                    </Box>
                                                                }
                                                                {
                                                                    getBotao(index, item, onBoard)
                                                                }
                                                            </Box>
                                                        }
                                                        {
                                                            (item.OnboardingStoreItem.iOrdem !== 1 && item.OnboardingStoreItem.iOrdem !== 2) &&
                                                            <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                <Typography variant="h6" component="span" style={{ fontFamily: 'Arial', fontSize: 14, fontWeight: 'bold', color: item.FlagStatus ? '#707070' : 'r#181818', textDecoration: item.FlagStatus ? 'line-through' : null }}>
                                                                    {item?.OnboardingStoreItem?.sNome}
                                                                </Typography>
                                                                {
                                                                    mostrarObservacao === index &&
                                                                    <Box style={{ marginTop: 5 }}>
                                                                        <Typography style={{ fontFamily: 'Arial', fontSize: 12, fontWeight: 450, color: '#707070' }}>{item.OnboardingStoreItem.sDescricao}</Typography>
                                                                    </Box>
                                                                }
                                                                {
                                                                    getBotao(index, item, onBoard)
                                                                }
                                                            </Box>
                                                        }

                                                    </TimelineContent>
                                                </TimelineItem>
                                            </Timeline>
                                        )
                                    })
                                }

                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        )
    }
    catch (err) {
        SalvarLogSentry(err);

        const obj = {
            Descricao: 'Erro ao abrir página de Configuração de Emails',
            Erro: err.message,
            Controller: 'CRM CSM',
            DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
        };
        PUT_DATA(`/LogErro/CadastroLogErro`, obj);

        navigate('/not-found', { replace: true });
    }
}

export default OnBoardMobile;
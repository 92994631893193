import * as moment from 'moment';
import { handleTrack, PUT_DATA, SalvarLogSentry } from '../../../services/API';
import { Container } from './styles';
import { Alert, AlertTitle, Box, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import OpcoesEnvioEmailsAccordion from './opcoesEnvioEmailsAccordion';
import TemplateList from './templateList';
import UploadTesteira from './uploadTesteira';
import { useSelector } from 'react-redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Emails = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);

    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    useEffect(() => {
        if(userLoggedIn === null) return;

        (async () => {
            await handleTrack(userLoggedIn.IdUsuario, 'ConfigurarEmail', `Carregando tela de configuração de e-mail`);
        })();
    }, [userLoggedIn]);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    try {
        const componente = (
            <Container>
                <Box sx={{ width: "100%" }}>
                    <Box style={{ width: "100%" }}>
                        <Typography style={{ fontWeight: 'bold' }}>Configuração / Remessa de e-mails</Typography>
                    </Box>
                </Box>

                <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                >
                    <Tab label="Configuração" {...a11yProps(0)} />
                    <Tab label="Template" {...a11yProps(1)} />
                    <Tab label="Testeira" {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <OpcoesEnvioEmailsAccordion />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <TemplateList />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <UploadTesteira isEditing={false} />
                </TabPanel>

            </Container>
        );

        return componente;
    }
    catch (err) {
        SalvarLogSentry(err, 'Erro ao executar tela de e-mails');

        navigate('/page-error', { state: { message: '1761 - Oi, houve um erro na operação que você solicitou. Tente novamente ou fale com o suporte abaixo.' } });
    }
}

export default Emails;

import { Box, styled } from '@mui/material';

export const SectionLogo = styled(Box, { shouldForwardProp: (prop) => prop !== 'drawerOpen' })`
    width: 100%;
    height: 4.375rem;
    border-bottom: 1px dashed #ffff;
    display: flex;
    justify-content: center;
    align-items: center;

    .expand-btn {
        color: #dddd;
        background-color: #fff;
        position: relative;
        transition: 0.5s;
        left: ${props => props.drawerOpen ? "1rem" : "0.5rem"};
        display: flex;
        align-items: center;
        padding: 0.3rem;
        cursor: pointer;
    }

    .header-container img {
        transition: 0.5s;
        transform: ${props => props.drawerOpen ? "scale(1)" : "scale(0.5)"};
        ${props => !props.drawerOpen && "width: 85px;"}
        ${props => !props.drawerOpen && "height: 85px;"}
    }
`;

export const LogoImagem = styled(Box, { shouldForwardProp: (prop) => prop !== 'drawerOpen' })`
    width: ${props => props.drawerOpen ? "120px" : "50px"}; 
    margin-top: -40px;
    margin-bottom: -30px;
    margin-left: 30px;
`;

import { Box, DialogContent, Typography, styled } from '@mui/material';

export const Container = styled(Box)`
`;

export const Section = styled(Box)`
    width: 100%; 
    margin-bottom: 10px;
    align-items: flex-end;
    background: white;
    padding: 15px;
    border-radius: 10px;
    height: auto;
`;

export const AlertError = styled(Typography)`
    padding-top: 10px;
    color: #e15126;
    font-weight: bold;
`;

export const ImageContainer = styled(DialogContent)`
    position: relative;
`;

export const Marcador = styled(Box, { shouldForwardProp: (prop) => prop !== 'posY' && prop !== 'posX' && prop !== 'temporary' && prop !== 'color' })`
    position: absolute;
    top: ${({ posY }) => `${posY}px`};
    left: ${({ posX }) => `${posX}px`};
    width: 35px;
    height: 35px;
    background-color: ${({ color }) => `${color}`};
    border-radius: 50px;
    border: 2px solid white;
    box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    opacity: ${({ temporary }) => (temporary ? "0.5" : "1")};
    &:hover {
    transform: scale(1.3);
    }
`;


export const Title = styled(Typography)`
    color: #757575;
    font-size: 16px !important;
    font-weight: bold;
`;

export const Descricao = styled(Typography)`
    color: rgb(247, 147, 30);
    font-size: 24px !important;
    font-weight: 700 !important;
    padding-top: 10px;
`;
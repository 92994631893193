import { ThemeProvider, createTheme } from '@mui/material/styles';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const Theme = ({ children, customization }) => {
    const color = colors;

    const themeOption = {
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: '#FFFFFF',
        menuSelectedBack: '#F1416C',
        divider: color.grey200,
        customization
    };

    const mode = customization.mode === "dark" ? "dark" : "light";

    const theme = createTheme({
        direction: 'ltr',
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: {
            fontFamily: "arial",
        },
        palette: {
            mode: mode,
            ...(mode === "light"
                ? {
                    // palette values for light mode
                    primary: {
                        main: "#F1416C",
                    },
                    secondary: {
                        main: "#F1416C",
                    },
                    error: {
                        main: "#e02424",
                    },
                    info: {
                        main: "#ACDDF9",
                    },
                    warning: {
                        main: "#FFC731",
                    },
                    background: {
                        default: "#F5F8FA",
                        paper: "#FFFFFF",
                    },
                    contrastThreshold: 2,
                    tonalOffset: 0.04,
                }
                : {
                    primary: {
                        main: "#F3923D",
                    },
                    secondary: {
                        main: "#F1416C",
                    },
                    error: {
                        main: "#e02424",
                    },
                    info: {
                        main: "#ACDDF9",
                    },
                    warning: {
                        main: "#FFC731",
                    },
                    background: {
                        default: "#242424",
                        paper: "#2D2D2D",
                    },
                    contrastThreshold: 3,
                }),
        },
        components: {
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        transform: 'scale(.9)', // Evita distorção
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#FFFFFF',
                        color: 'red',

                        '&.Mui-selected': {
                            backgroundColor: '#FFFFFF',
                            color: '#F1416C'
                        },
                    },
                },
            },
        }
    });
    theme.components = componentStyleOverrides(themeOption);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};


import { useNavigate } from "react-router";

const GlobalErrorBoundary = ({children}) => {

    window.onerror = function (message, source, lineno, colno, error) {
        console.log(message, source, lineno, colno, error);

        if(process.env.REACT_APP_LOCAL !== 'true'){
            window.location.href = '/page-error';
        }
    };

    return children;
}

export default GlobalErrorBoundary;